import React from "react";
import { Approval } from "../../types/postmatch.types";
import styles from "./timesheets-table-render-rejected-date.module.css";

export function TimesheetsTableRenderRejectedDate() {
  return function (rejectionData: Approval) {
    return (
      <div className={styles.text}>
        <div>{rejectionData?.fullName}</div>
        <div>{rejectionData?.updatedAt}</div>
      </div>
    );
  };
}
