import React, { ReactNode } from "react";
import { Modal } from "antd";
import styles from "./modal-design-system-v2.module.css";
import { CloseSvg } from "../svgs/CloseSvg";

export function ModalDesignSystemV2({
  isOpen,
  onClose,
  children,
  width = 575,
}: {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  width?: number;
}) {
  return (
    <Modal
      open={isOpen}
      footer={null}
      width={width}
      className={styles.timesheetsModal}
      onCancel={onClose}
      closable
    >
      {children}
      <div className={styles.closeWrapper} onClick={onClose}>
        <CloseSvg />
      </div>
    </Modal>
  );
}
