import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import styles from "./breadcrumb.module.css";
import { PostmatchBreadcrumbProps } from "./breadcrumb.types";

export function PostMatchBreadcrumb({ items }: PostmatchBreadcrumbProps) {
  return (
    <div className={styles.container}>
      {items.map((item, index) => {
        return (
          <Fragment key={item.text}>
            {item.url ? (
              <Link className={styles["breadcrumb-item"]} to={`${item.url}`}>
                {item.text}
              </Link>
            ) : (
              <div className={styles["breadcrumb-item"]}>{item.text}</div>
            )}

            {index !== items.length - 1 && items.length > 1 ? (
              <div className={styles["breadcrumb-item"]}>/</div>
            ) : null}
          </Fragment>
        );
      })}
    </div>
  );
}
