import { createColumnHelper } from "@tanstack/react-table";
import {
  filterDateRange,
  filterNumericalRange,
  filterText,
  sortText,
} from "../dashboard/dashboard.utils";
import { ColumnType } from "../dashboard/dashboard.types";
import { FormattedDeveloper } from "../../types/formatted-developer.types";

export const columnHelper = createColumnHelper<FormattedDeveloper>();

const textWithoutCheckboxesConfig = {
  sortingFn: sortText,
  sortDescFirst: false,
  sortUndefined: -1,
  filterFn: filterText,
};

const textWithCheckboxesConfig = {
  sortingFn: sortText,
  sortDescFirst: false,
  sortUndefined: -1,
  filterFn: filterText,
};

const numberConfig = {
  sortDescFirst: false,
  sortUndefined: -1,
  filterFn: filterNumericalRange,
};

const dateConfig = {
  sortDescFirst: false,
  sortUndefined: -1,
  filterFn: filterDateRange,
};

const disabledConfig = {
  enableColumnFilter: false,
  enableSorting: false,
};

function getSpecificColumnConfig(columnType: ColumnType) {
  switch (columnType) {
    case ColumnType.TextWithoutCheckboxes:
      return textWithoutCheckboxesConfig;
    case ColumnType.TextWithCheckboxes:
      return textWithCheckboxesConfig;
    case ColumnType.NumberType:
      return numberConfig;
    case ColumnType.DateType:
      return dateConfig;
    default:
      return disabledConfig;
  }
}

export function getColumnConfig(columnType: ColumnType) {
  return {
    meta: {
      columnType,
    },
    ...getSpecificColumnConfig(columnType),
  };
}
