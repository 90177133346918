import { notification } from "antd";
import styles from "./toast.module.css";

export const notificationConfig = {
  className: styles.notification,
};

export const Toast = {
  success: (message: string, config = notificationConfig) => {
    notification.success({ message, ...config });
  },

  info: (message: string, config = notificationConfig) => {
    notification.info({ message, ...config });
  },

  error: (message: string, config = notificationConfig) => {
    notification.error({ message, ...config });
  },

  warn: (message: string, config = notificationConfig) => {
    notification.warn({ message, ...config });
  },
};
