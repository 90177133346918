import React from "react";

export const ThumbsDownSvg = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.7564 12.9167C2.36002 12.9167 2.00986 12.7647 1.70592 12.4608C1.40197 12.1568 1.25 11.8067 1.25 11.4103V10.0642C1.25 9.97671 1.26122 9.88299 1.28365 9.78302C1.30608 9.68306 1.33118 9.58981 1.35896 9.50327L3.74715 3.86773C3.86656 3.60127 4.06676 3.37616 4.34773 3.19239C4.62872 3.00863 4.9219 2.91675 5.22727 2.91675H13.9903V12.9167L9.14258 17.7228C8.97699 17.8884 8.78522 17.9872 8.56727 18.0192C8.34933 18.0513 8.14047 18.0139 7.94069 17.9071C7.74091 17.8002 7.59455 17.6485 7.50161 17.452C7.40865 17.2554 7.38888 17.0519 7.44231 16.8414L8.33973 12.9167H2.7564ZM12.7404 12.3847V4.16673H5.22433C5.16557 4.16673 5.10547 4.18276 5.04404 4.21481C4.9826 4.24687 4.93585 4.30029 4.90379 4.37508L2.49996 10.0001V11.4103C2.49996 11.4851 2.524 11.5465 2.57208 11.5946C2.62017 11.6427 2.6816 11.6667 2.7564 11.6667H9.91983L8.87496 16.234L12.7404 12.3847ZM16.4102 2.91675C16.8245 2.91675 17.1791 3.06426 17.4741 3.35927C17.7691 3.65427 17.9166 4.0089 17.9166 4.42317V11.4103C17.9166 11.8246 17.7691 12.1792 17.4741 12.4742C17.1791 12.7692 16.8245 12.9167 16.4102 12.9167H13.9903V11.6667H16.4102C16.485 11.6667 16.5464 11.6427 16.5945 11.5946C16.6426 11.5465 16.6666 11.4851 16.6666 11.4103V4.42317C16.6666 4.34837 16.6426 4.28694 16.5945 4.23885C16.5464 4.19077 16.485 4.16673 16.4102 4.16673H13.9903V2.91675H16.4102Z"
      fill="#212121"
    />
  </svg>
);
