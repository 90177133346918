import React from "react";

export const WaitingForApprovalSvg = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0003 13.9582C10.1775 13.9582 10.3259 13.8983 10.4455 13.7785C10.5652 13.6587 10.625 13.5103 10.625 13.3332V9.79154C10.625 9.61448 10.5651 9.46604 10.4452 9.34625C10.3254 9.22646 10.1769 9.16657 9.99975 9.16657C9.82258 9.16657 9.67417 9.22646 9.55452 9.34625C9.43487 9.46604 9.37504 9.61448 9.37504 9.79154V13.3332C9.37504 13.5103 9.43496 13.6587 9.55479 13.7785C9.67464 13.8983 9.82314 13.9582 10.0003 13.9582ZM10 7.74029C10.1907 7.74029 10.3506 7.67579 10.4796 7.54679C10.6086 7.41779 10.6731 7.25794 10.6731 7.06723C10.6731 6.87654 10.6086 6.71668 10.4796 6.58767C10.3506 6.45867 10.1907 6.39417 10 6.39417C9.80931 6.39417 9.64946 6.45867 9.52046 6.58767C9.39146 6.71668 9.32696 6.87654 9.32696 7.06723C9.32696 7.25794 9.39146 7.41779 9.52046 7.54679C9.64946 7.67579 9.80931 7.74029 10 7.74029ZM10.0014 17.9165C8.90647 17.9165 7.87728 17.7088 6.91383 17.2932C5.95037 16.8777 5.1123 16.3137 4.39962 15.6013C3.68693 14.889 3.12271 14.0513 2.70698 13.0882C2.29124 12.1252 2.08337 11.0962 2.08337 10.0013C2.08337 8.90635 2.29115 7.87716 2.70671 6.91371C3.12226 5.95025 3.68622 5.11218 4.39858 4.3995C5.11096 3.68681 5.94866 3.12259 6.91169 2.70686C7.8747 2.29112 8.90368 2.08325 9.99862 2.08325C11.0936 2.08325 12.1228 2.29103 13.0862 2.70659C14.0497 3.12214 14.8877 3.6861 15.6004 4.39846C16.3131 5.11084 16.8773 5.94854 17.2931 6.91157C17.7088 7.87458 17.9167 8.90356 17.9167 9.9985C17.9167 11.0934 17.7089 12.1226 17.2933 13.0861C16.8778 14.0495 16.3138 14.8876 15.6015 15.6003C14.8891 16.313 14.0514 16.8772 13.0884 17.2929C12.1253 17.7087 11.0964 17.9165 10.0014 17.9165ZM10 16.6666C11.8611 16.6666 13.4375 16.0207 14.7292 14.7291C16.0209 13.4374 16.6667 11.861 16.6667 9.9999C16.6667 8.13879 16.0209 6.5624 14.7292 5.27073C13.4375 3.97907 11.8611 3.33323 10 3.33323C8.13891 3.33323 6.56252 3.97907 5.27085 5.27073C3.97919 6.5624 3.33335 8.13879 3.33335 9.9999C3.33335 11.861 3.97919 13.4374 5.27085 14.7291C6.56252 16.0207 8.13891 16.6666 10 16.6666Z"
      fill="#005499"
    />
  </svg>
);
