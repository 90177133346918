import moment from "moment";
import {
  APPROVAL_STATUS,
  Approval,
  Comment,
  TimeCard,
  TimesheetsTableDataSource,
} from "../../types/postmatch.types";
import { convertStatusToDisplayableText } from "../timesheets-status/timesheets-status";
import { Environment, getEnvironment } from "../environment/environment";

function formatUpdatedAt(updatedAt: string) {
  const updatedAtDate = moment.utc(updatedAt).startOf("day");
  return updatedAtDate.isValid() ? updatedAtDate.format("MMMM DD, YYYY") : "";
}

export function verifyApprovalStatus(
  timeCards: TimeCard[],
  status: APPROVAL_STATUS,
) {
  return timeCards.filter((timeCard) => {
    const timeCardStatus = timeCard?.status;
    const approvals = timeCard?.approvals;
    const lastApproval = approvals?.[approvals.length - 1];
    const lastApprovalStatus = lastApproval?.approvalStatus;
    return status === timeCardStatus && status === lastApprovalStatus;
  });
}

export function formatWeek(
  rangeStartDate: string | Date | undefined,
  rangeEndDate: string | Date | undefined,
) {
  const startDate = moment.utc(rangeStartDate).startOf("day");
  const endDate = moment(rangeEndDate).startOf("day");
  if (!startDate.isValid() || !endDate.isValid()) {
    return "";
  }
  const startYear = startDate.format("YYYY");
  const startMonth = startDate.format("MMMM");
  const startDay = startDate.format("DD");
  const endYear = endDate.format("YYYY");
  const endMonth = endDate.format("MMMM");
  const endDay = endDate.format("DD");
  if (startYear === endYear && startMonth === endMonth && startDay === endDay) {
    return `${startMonth} ${startDay}`;
  }
  if (startYear === endYear && startMonth === endMonth) {
    return `${startMonth} ${startDay} - ${endDay}`;
  }
  if (startYear === endYear) {
    return `${startMonth} ${startDay} - ${endMonth} ${endDay}`;
  }
  return `${startYear} ${startMonth} ${startDay} - ${endYear} ${endMonth} ${endDay}`;
}

export function formatTotalHours(totalMinutes: string | number | undefined) {
  const normalizedTotalMinutes = parseInt(totalMinutes as string, 10);
  if (Number.isNaN(normalizedTotalMinutes)) {
    return "";
  }
  return `${Math.floor(normalizedTotalMinutes / 60)}h ${
    normalizedTotalMinutes % 60
  }m`;
}

export function formatRejectionData(approvals: Approval[] | undefined) {
  if (!approvals?.length) {
    return { fullName: "", updatedAt: "" };
  }
  const rejectionData = approvals[approvals.length - 1];
  const updatedAt = rejectionData?.updatedAt;
  return {
    fullName: rejectionData?.fullName || "",
    updatedAt: formatUpdatedAt(updatedAt),
  };
}

export function formatComments(comments: Comment[] | undefined) {
  return (comments || []).map((comment, index) => ({
    ...comment,
    id: index,
    updatedAt: formatUpdatedAt(comment?.updatedAt),
  }));
}

export function parseTimesheets(
  timesheets: TimeCard[],
): TimesheetsTableDataSource[] {
  return timesheets.map((timesheet) => {
    const {
      avatarUrl,
      contractor,
      developerId,
      rangeEndDate,
      rangeStartDate,
      status,
      timeCardId,
      totalMinutes,
      developerIdHash,
      approvalId,
      jobId,
      comments,
      approvals,
    } = timesheet || {};

    const environment = getEnvironment();
    const jibble =
      environment === Environment.Production
        ? "https://web.jibble.io/timesheets/overview"
        : "https://app.test.jibble.io/timesheets/overview";

    const contractorLink = developerId ? `/developer/${developerId}` : "";

    return {
      contractor,
      avatarUrl: avatarUrl || "",
      week: formatWeek(rangeStartDate, rangeEndDate),
      totalHours: formatTotalHours(totalMinutes),
      approvalStatus: status,
      status: convertStatusToDisplayableText(status as APPROVAL_STATUS),
      timeCardId,
      developerId,
      developerIdHash,
      approvalId,
      jobId,
      comments: formatComments(comments),
      rejectionData: formatRejectionData(approvals),
      jibble,
      contractorLink,
    };
  });
}
