export const TABLE_ACTION = {
  SORT: "sort",
  FILTER: "filter",
};

export const TABLE_ACTIVITY_TYPES = {
  KEY: "POSTMATCH",
  POSTMATCH_TABLE_ORDERED: "POSTMATCH_TABLE_ORDERED",
  POSTMATCH_TABLE_FILTERED: "POSTMATCH_TABLE_FILTERED",
};
