import React from "react";

export function TooltipSvg() {
  return (
    <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.00015 8.37498C6.10645 8.37498 6.1955 8.33904 6.26729 8.26716C6.33908 8.1953 6.37498 8.10624 6.37498 7.99999V5.87498C6.37498 5.76873 6.33903 5.67968 6.26713 5.6078C6.19522 5.53593 6.10612 5.49999 5.99983 5.49999C5.89353 5.49999 5.80448 5.53593 5.73269 5.6078C5.6609 5.67968 5.625 5.76873 5.625 5.87498V7.99999C5.625 8.10624 5.66095 8.1953 5.73285 8.26716C5.80476 8.33904 5.89386 8.37498 6.00015 8.37498ZM5.99999 4.64423C6.11441 4.64423 6.21033 4.60553 6.28773 4.52813C6.36513 4.45073 6.40383 4.35481 6.40383 4.24039C6.40383 4.12597 6.36513 4.03006 6.28773 3.95265C6.21033 3.87525 6.11441 3.83655 5.99999 3.83655C5.88556 3.83655 5.78965 3.87525 5.71225 3.95265C5.63485 4.03006 5.59615 4.12597 5.59615 4.24039C5.59615 4.35481 5.63485 4.45073 5.71225 4.52813C5.78965 4.60553 5.88556 4.64423 5.99999 4.64423ZM6.00082 10.75C5.34386 10.75 4.72634 10.6253 4.14828 10.376C3.5702 10.1266 3.06736 9.78827 2.63975 9.36085C2.21213 8.93343 1.8736 8.43081 1.62416 7.85299C1.37472 7.27518 1.25 6.65779 1.25 6.00083C1.25 5.34386 1.37467 4.72634 1.624 4.14828C1.87333 3.5702 2.21171 3.06736 2.63913 2.63975C3.06655 2.21213 3.56917 1.8736 4.14699 1.62416C4.7248 1.37472 5.34218 1.25 5.99915 1.25C6.65612 1.25 7.27363 1.37467 7.8517 1.624C8.42978 1.87333 8.93262 2.21171 9.36023 2.63913C9.78784 3.06655 10.1264 3.56917 10.3758 4.14699C10.6253 4.7248 10.75 5.34218 10.75 5.99915C10.75 6.65612 10.6253 7.27363 10.376 7.8517C10.1266 8.42978 9.78827 8.93262 9.36085 9.36023C8.93343 9.78784 8.43081 10.1264 7.85299 10.3758C7.27518 10.6253 6.65779 10.75 6.00082 10.75ZM5.99999 9.99999C7.11665 9.99999 8.06249 9.61249 8.83749 8.83749C9.61249 8.06249 9.99999 7.11665 9.99999 5.99999C9.99999 4.88332 9.61249 3.93749 8.83749 3.16249C8.06249 2.38749 7.11665 1.99999 5.99999 1.99999C4.88332 1.99999 3.93749 2.38749 3.16249 3.16249C2.38749 3.93749 1.99999 4.88332 1.99999 5.99999C1.99999 7.11665 2.38749 8.06249 3.16249 8.83749C3.93749 9.61249 4.88332 9.99999 5.99999 9.99999Z"
        fill="#1C1B1F"
      />
    </svg>
  );
}
