export interface TotalEngagements {
  starts: number;
  stops: number;
}

export interface Location {
  country: string;
  city: string;
}

export interface Email {
  personal: string;
  turing: string;
}

export interface Job {
  id: number;
  title: string;
}

export interface User {
  email: string;
  name: string;
}

export interface BillingRate {
  rate: number;
  type: number | null;
  rateText?: string;
}

export interface BillingRateMargin {
  margin: number;
  marginText: string;
}
export enum RateChangeType {
  customer = "customer",
  developer = "developer",
}
export interface OpportunityRateHistoryResponse {
  opportunityId: string;
  rateChangeType: RateChangeType;
  newRate: number;
  oldRate: number;
  approvedDate: string;
  effectiveDate: string;
}

export interface Engagement {
  startDate: string;
  endDate: string;
  job: Job;
  customerContact?: User;
  customerHiringManager?: User;
  duration?: number | null;
  formattedDuration?: string;
  rateHistory: Array<OpportunityRateHistoryResponse>;
}

export interface Status {
  internal: string;
  external: string;
}

export interface NumericalIds {
  ids: number[];
}

export interface StringIds {
  ids: string[];
}

export interface DeveloperRecord {
  name: string;
  developerId?: number;
  developerIdHash?: string;
  img: string;
  jobId: number | null;
}

export interface Developer extends DeveloperRecord {
  id: number;
  normalizedId: string;
  developerId: number;
  avatarUrl: string;
  location: Location | undefined;
  email: Email | undefined;
  billedHours: number | string;
  ptoHours: number | string;
  customerBillingRate: BillingRate | undefined;
  developerBillingRate: BillingRate | undefined;
  billingRateMargin: BillingRateMargin | undefined;
  billingRateType: BillingRateType | null;
  status: Status | undefined;
  devSuccessSpecialist?: User | undefined;
  salesRepresentative: User | undefined;
  engagement: Engagement | undefined;
  companyName?: string;
  companyId?: number | string | undefined;
  companyType?: CompanyTypeEnum;
  accountExecutive?: User;
  opportunityLink?: string;
  opportunityId?: number;
  opportunityBusinessLine: SfOppBusinessLineEnum;
}

export interface ParsedDeveloper extends Developer {
  billingStatus: string;
}

export interface RowRecord extends Developer {
  [key: string]: any;
}

export interface CompanyItem {
  name: string;
  type?: CompanyTypeEnum;
  logoUrl: string;
  developers: Developer[];
  totalActiveDevelopers: number;
  totalMargin: BillingRateMargin | undefined;
  averageMarginPerDev: BillingRateMargin | undefined;
  billedHours: number;
  moneySpent: number;
  totalEngagements: TotalEngagements | undefined;
  id?: number;
  accountExecutive?: User;
  salesRepresentative?: User;
}

export interface GetEngagementsRequest {
  id?:
    | { $case: "companyId"; companyId: number }
    | { $case: "selfServeUserId"; selfServeUserId: string }
    | { $case: "hiringManagerId"; hiringManagerId: string }
    | undefined;
  startDate: string;
  endDate: string;
  pageSize: number;
  pageNumber: number;
}

export interface GetEngagementsResponse {
  company: CompanyItem | undefined;
}

export interface GetEngagementsResponseData {
  data: GetEngagementsResponse;
}

export interface GetBulkEngagementsRequest {
  ids?:
    | { $case: "companyIds"; companyIds: NumericalIds }
    | {
        $case: "selfServeUserIds";
        selfServeUserIds: StringIds;
      }
    | {
        $case: "hiringManagerIds";
        hiringManagerIds: StringIds;
      }
    | undefined;
  startDate: string;
  endDate: string;
  pageSize: number;
  pageNumber: number;
}

export enum SortOrder {
  SORT_ORDER_UNSPECIFIED = 0,
  SORT_ORDER_ASC = 1,
  SORT_ORDER_DESC = 2,
  UNRECOGNIZED = -1,
}

export interface GetBulkEngagementsResponse {
  companies: CompanyItem[];
  total: number;
  error: string;
}

export interface GetBulkEngagementsResponseData {
  data: GetBulkEngagementsResponse;
}

export enum SearchByCompanyField {
  SEARCH_BY_COMPANY_FIELD_UNSPECIFIED = 0,
  /** SEARCH_BY_COMPANY_FIELD_COMPANY - search by company name */
  SEARCH_BY_COMPANY_FIELD_COMPANY = 1,
  UNRECOGNIZED = -1,
}

export interface GetCompaniesRequest {
  /** this value should match any field name in "ms2_company" table */
  sortBy: string;
  /** ASC or DESC */
  sortOrder: SortOrder;
  /** this value should match any field name in "ms2_company" table */
  searchBy: SearchByCompanyField;
  /** value to be searched in the field specified in "search_by" */
  searchValue: string;
  pageNumber: number;
  pageSize: number;
}

export interface GetCompaniesResponseCompany {
  id: number;
  name: string;
  salesforceAccountId: string;
}

export interface GetCompaniesResponse {
  companies: GetCompaniesResponseCompany[];
  total: number;
}

export interface CompanyName {
  label: string;
  value: number;
}

export interface SummaryDetail {
  totalActiveDevelopers: number;
  totalMargin: BillingRateMargin | undefined;
  averageMarginPerDev: BillingRateMargin | undefined;
  billedHours: number | string;
  moneySpent: string;
  moneySpentTitle?: string;
  totalEngagementsStarts: number;
  totalEngagementsStops: number;
  totalEngagements?: TotalEngagements;
  salesRepresentative?: User;
}

export type SummaryDetails = Record<string, SummaryDetail>;

export interface Company {
  value: number;
  label: string;
  id: number;
  salesforceAccountId: string;
}

export enum PaginationOption {
  Default = "default",
  Alternative = "alternative",
}

export enum ProductSurface {
  SelfServe = "self-serve",
  Matching = "matching",
}

export enum EngagementsPermission {
  None = "none",
  OwnEngagements = "own-engagements",
  CompanyEngagements = "company-engagements",
}

export const ActivePicker = {
  Week: "week",
  Month: "month",
  Custom: "custom",
};

export interface DateRange {
  start: string;
  end: string;
}

export interface PickerDateRange {
  start: Date | null;
  end: Date | null;
}

export interface Feedback {
  developerName?: string;
  developerPageLink?: string;
  companyName?: string;
  companyPageLink?: string;
  jobTitle?: string;
  jobPageLink?: string;
  salesforceLink?: string;
  message?: string;
  user?: string;
}

export enum APPROVAL_STATUS {
  QUEUED = "QUEUED",
  PENDING_APPROVAL = "PENDING_APPROVAL",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  AUTO_APPROVED = "AUTO_APPROVED",
}

export interface TimeCardMetadata {
  fullName: string;
  updatedAt: string;
}

export interface Comment extends TimeCardMetadata {
  comment: string;
}

export interface Approval extends TimeCardMetadata {
  approvalStatus: APPROVAL_STATUS;
}

export interface DeveloperProfileData {
  name: string | null;
  developerId: number | null;
  developerIdHash: string | null;
  jobId: number | null;
}

export interface TimesheetsQuery {
  pageNumber?: number | undefined;
  pageSize?: number | undefined;
  sortBy?: string | undefined;
  sortDir?: string | undefined;
  filterBy?: string | undefined;
  filterValue?: string | undefined;
}

export interface BaseTimeCard {
  avatarUrl: string;
  contractor: string;
  developerId: number;
  timeCardId: number;
  developerIdHash: string;
  approvalId: number;
  jobId: number;
}

export interface TimeCard extends BaseTimeCard {
  frequency: string;
  rangeEndDate: string;
  rangeStartDate: string;
  status: APPROVAL_STATUS;
  totalMinutes: string;
  approvals?: Approval[];
  comments?: Comment[];
}

export interface TimesheetsTableDataSource extends BaseTimeCard {
  week: string;
  totalHours: string;
  status: string;
  approvalStatus: APPROVAL_STATUS;
  rejectionData: Omit<Approval, "approvalStatus">;
  comments: Comment[];
}

export interface TimeCardsResponse {
  timesheets: TimeCard[];
  total: number;
}

export type TimesheetsMap = Record<string, TimesheetsTableDataSource>;

export enum SfOppBusinessLineEnum {
  SERVICES = "SERVICES",
  STAFF_AUG = "STAFF_AUG",
}

export const SfOppBusinessLineEnumToLabelMap = {
  [SfOppBusinessLineEnum.SERVICES]: "Services",
  [SfOppBusinessLineEnum.STAFF_AUG]: "Staff Aug",
};

export enum CompanyTypeEnum {
  ENTERPRISE = "Enterprise",
  FSS = "FSS",
}

export const CompanyTypeEnumToLabelMap = {
  [CompanyTypeEnum.ENTERPRISE]: "ENT",
  [CompanyTypeEnum.FSS]: "FSS",
};

export enum BillingRateType {
  MONTHLY = "monthly",
  HOURLY = "hourly",
}

export const BillingRateTypeToLabelMap = {
  [BillingRateType.MONTHLY]: "Monthly",
  [BillingRateType.HOURLY]: "Hourly",
};

export enum FIELDS_TO_INCLUDE {
  COMMENTS = "comments",
  APPROVALS = "approvals",
}
