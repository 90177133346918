import { TimesheetsTableDataSource } from "../../types/postmatch.types";

export function buildTimesheetsMap(timesheets: TimesheetsTableDataSource[]) {
  return timesheets.reduce(
    (acc: Record<string, TimesheetsTableDataSource>, curr) => {
      acc[curr?.timeCardId] = curr;
      return acc;
    },
    {},
  );
}
