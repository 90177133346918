import React from "react";
import classNames from "classnames";
import styles from "./button-design-system-v2.module.css";
import {
  ButtonDesignSystemV2Color,
  ButtonDesignSystemV2Props,
} from "./button-design-system-v2.types";

function getButtonColor(color: ButtonDesignSystemV2Color) {
  switch (color) {
    case ButtonDesignSystemV2Color.Primary:
      return styles.primary;
    case ButtonDesignSystemV2Color.Secondary:
      return styles.secondary;
    case ButtonDesignSystemV2Color.Tertiary:
      return styles.tertiary;
    default:
      return styles.primary;
  }
}

export function ButtonDesignSystemV2({
  text,
  Icon,
  isLoading,
  onClick,
  color = ButtonDesignSystemV2Color.Primary,
  buttonClassName,
  spinnerClassName,
  textClassName,
  disabled,
  isLinkNewTab = false,
  href = "",
}: ButtonDesignSystemV2Props) {
  const colorClassName = getButtonColor(color);

  if (isLinkNewTab) {
    return (
      <a
        className={classNames(styles.root, colorClassName, buttonClassName)}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className={styles.text}>{text}</span>
      </a>
    );
  }

  return (
    <button
      type="button"
      className={classNames(
        styles.root,
        colorClassName,
        { [styles.rootLoading]: isLoading || disabled },
        buttonClassName,
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {isLoading && (
        <div className={classNames(styles.spinner, spinnerClassName)} />
      )}{" "}
      {Icon && !isLoading && (
        <div className={styles.iconWrapper}>
          <Icon />
        </div>
      )}{" "}
      <span className={classNames(styles.text, textClassName)}>{text}</span>
    </button>
  );
}
