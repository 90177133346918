import React from "react";

export function CloseSvg() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99999 10.8782L5.77241 15.1057C5.65703 15.2211 5.512 15.2802 5.33732 15.2828C5.16266 15.2855 5.01496 15.2265 4.89424 15.1057C4.7735 14.985 4.71313 14.8387 4.71313 14.6667C4.71313 14.4947 4.7735 14.3483 4.89424 14.2276L9.1218 9.99999L4.89424 5.77241C4.77885 5.65703 4.71982 5.512 4.71716 5.33732C4.71447 5.16266 4.7735 5.01496 4.89424 4.89424C5.01496 4.7735 5.16132 4.71313 5.33332 4.71313C5.50532 4.71313 5.65168 4.7735 5.77241 4.89424L9.99999 9.1218L14.2276 4.89424C14.3429 4.77885 14.488 4.71982 14.6627 4.71716C14.8373 4.71447 14.985 4.7735 15.1057 4.89424C15.2265 5.01496 15.2868 5.16132 15.2868 5.33332C15.2868 5.50532 15.2265 5.65168 15.1057 5.77241L10.8782 9.99999L15.1057 14.2276C15.2211 14.3429 15.2802 14.488 15.2828 14.6627C15.2855 14.8373 15.2265 14.985 15.1057 15.1057C14.985 15.2265 14.8387 15.2868 14.6667 15.2868C14.4947 15.2868 14.3483 15.2265 14.2276 15.1057L9.99999 10.8782Z"
        fill="#444444"
      />
    </svg>
  );
}
