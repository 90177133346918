import React, { useState } from "react";
import { Button, Popover, Tooltip } from "antd";
import moment from "moment";
import classNames from "classnames";
import styles from "./postmatch-picker.module.css";
import { ActivePicker } from "../../types/postmatch.types";
import { PostmatchPickerMonth } from "./components/postmatch-picker-month/postmatch-picker-month";
import { PostmatchPickerWeek } from "./components/postmatch-picker-week/postmatch-picker-week";
import { PostmatchPickerCustom } from "./components/postmatch-picker-custom/postmatch-picker-custom";
import "react-datepicker/dist/react-datepicker.css";
import "./postmatch-picker.css";
import { PostmatchPickerProps } from "./postmatch-picker.types";
import {
  getActivePickerFromQuery,
  getMonthFromISO,
  getYearFromISO,
} from "../../utils/dates/dates";
import { getQueryDatesInISO } from "../../utils/query/query";
import { TooltipSvg } from "../svgs/TooltipSvg";
import { CalendarSvg } from "../svgs/CalendarSvg";
import { DownSvg } from "../svgs/DownSvg";
import CalendarDotTopLeftSvg from "../svgs/CalendarDotTopLeftSvg";

export function PostmatchPicker({
  activePicker,
  setActivePicker = () => {},
  dateRange,
  onSetDate,
  isPopoverOpen,
  setIsPopoverOpen,
  popoverTitle,
  disabled,
  isQueryLoaded,
  query,
  label = "Active contractors",
  isTooltipEnabled = true,
  areSelectorsEnabled = true,
  customClassNames = {},
  isAlternativeIcon = false,
}: PostmatchPickerProps) {
  const [popoverDate, setPopoverDate] = useState({
    start: dateRange.start,
    end: dateRange.end,
  });
  const [activeMonthYear, setActiveMonthYear] = useState({ month: 0, year: 0 });
  const [visibleCustomDate, setVisibleCustomDate] = useState({
    start: dateRange.start,
    end: dateRange.end,
  });
  const [dateBoxHighlight, setDateBoxHighlight] = useState({
    start: true,
    end: false,
  });

  const onOpenChange = (newIsOpen: boolean) => {
    if (newIsOpen) {
      const { startDateFromQueryISO, endDateFromQueryISO } =
        getQueryDatesInISO(query);
      setActivePicker(getActivePickerFromQuery(query));
      setPopoverDate({
        start: startDateFromQueryISO,
        end: endDateFromQueryISO,
      });
      setActiveMonthYear({
        month: getMonthFromISO(startDateFromQueryISO),
        year: getYearFromISO(startDateFromQueryISO),
      });
    }
    setIsPopoverOpen(newIsOpen);
  };

  const content = (
    <div
      className={classNames(styles.calendarContainer, {
        [styles.calendarContainerCustom]: activePicker === ActivePicker.Custom,
        [styles.calendarContainerHiddenSelectors]: !areSelectorsEnabled,
      })}
    >
      {areSelectorsEnabled && (
        <div className={styles.calendarSelectors}>
          <div
            className={classNames(styles.calendarSelector, {
              [styles.calendarSelectorActive]:
                activePicker === ActivePicker.Week,
            })}
            onClick={() => setActivePicker(ActivePicker.Week)}
          >
            Week
          </div>
          <div
            className={classNames(styles.calendarSelector, {
              [styles.calendarSelectorActive]:
                activePicker === ActivePicker.Month,
            })}
            onClick={() => setActivePicker(ActivePicker.Month)}
          >
            Month
          </div>
          <div
            className={classNames(styles.calendarSelector, {
              [styles.calendarSelectorActive]:
                activePicker === ActivePicker.Custom,
            })}
            onClick={() => setActivePicker(ActivePicker.Custom)}
          >
            Custom
          </div>
        </div>
      )}
      <div
        className={classNames(
          styles.calendarPicker,
          // Apply specific styles for each mode.
          // Note react-datepicker doesn't use css modules.
          {
            picker__week: activePicker === ActivePicker.Week,
          },
          { picker__month: activePicker === ActivePicker.Month },
        )}
      >
        {activePicker === ActivePicker.Week && isPopoverOpen ? (
          <PostmatchPickerWeek
            setPopoverDate={setPopoverDate}
            isQueryLoaded={isQueryLoaded}
            query={query}
            activeMonthYear={activeMonthYear}
            setActiveMonthYear={setActiveMonthYear}
          />
        ) : activePicker === ActivePicker.Month && isPopoverOpen ? (
          <PostmatchPickerMonth
            setPopoverDate={setPopoverDate}
            isQueryLoaded={isQueryLoaded}
            query={query}
            activeMonthYear={activeMonthYear}
            setActiveMonthYear={setActiveMonthYear}
          />
        ) : activePicker === ActivePicker.Custom && isPopoverOpen ? (
          <PostmatchPickerCustom
            setPopoverDate={setPopoverDate}
            isQueryLoaded={isQueryLoaded}
            query={query}
            activeMonthYear={activeMonthYear}
            setActiveMonthYear={setActiveMonthYear}
            setVisibleCustomDate={setVisibleCustomDate}
            setDateBoxHighlight={setDateBoxHighlight}
          />
        ) : null}
        <div className={styles.calendarPickerButtonWrapper}>
          {activePicker === ActivePicker.Custom && (
            <div className={styles.dateBoxes}>
              <span
                className={classNames(styles.dateBox, {
                  [styles.dateBoxHighlight]: dateBoxHighlight.start,
                })}
              >
                {moment.utc(visibleCustomDate.start).format("MM/DD/YYYY")}
              </span>
              <span className={styles.separator}>-</span>
              <span
                className={classNames(
                  styles.dateBox,
                  { [styles.dateBoxHighlight]: dateBoxHighlight.end },
                  {
                    [styles.dateBoxEmpty]: !visibleCustomDate.end,
                  },
                )}
              >
                {visibleCustomDate.end ? (
                  moment.utc(visibleCustomDate.end).format("MM/DD/YYYY")
                ) : (
                  <span>-</span>
                )}
              </span>
            </div>
          )}

          <Button
            className={classNames(styles.calendarPickerButton, {
              [styles.calendarPickerButtonDisabled]:
                activePicker === ActivePicker.Custom && !visibleCustomDate.end,
            })}
            onClick={() => onSetDate(popoverDate.start, popoverDate.end)}
          >
            Apply
          </Button>
        </div>
      </div>
    </div>
  );

  return (
    <div
      className={classNames(styles.root, { [styles.rootDisabled]: disabled })}
    >
      <div
        className={classNames(styles.popoverLabel, {
          [styles.popoverCustomLabel]: customClassNames.pickerLabel,
        })}
      >
        <span className={styles.popoverTitle}>{label}</span>
        {isTooltipEnabled && (
          <Tooltip
            title="This date selector lets you see invoicing contractors in specific timeframes"
            overlayInnerStyle={{ backgroundColor: "black" }}
          >
            <span className={styles.popoverTooltip}>
              <TooltipSvg />
            </span>
          </Tooltip>
        )}
        <br />
      </div>
      <div className={styles.popoverWrapper}>
        <Popover
          content={content}
          trigger="click"
          getPopupContainer={(trigger) => trigger}
          placement="bottomLeft"
          open={isPopoverOpen}
          onOpenChange={onOpenChange}
          className={classNames(styles.popover, {
            [styles.popoverDisabled]: disabled,
            [styles.popoverCustomBackground]: customClassNames.pickerLabel,
            [styles.popoverFocus]: isPopoverOpen,
          })}
        >
          <Button>
            <div className={styles.popoverButton}>
              {isAlternativeIcon ? <CalendarDotTopLeftSvg /> : <CalendarSvg />}
              <div
                className={classNames(styles.popoverButtonText, {
                  [styles.popoverButtonTextDisabled]: disabled,
                })}
              >
                {popoverTitle}
              </div>
              <div
                className={classNames(styles.popoverArrow, {
                  [styles.popoverArrowDisabled]: disabled,
                })}
              >
                <DownSvg />
              </div>
            </div>
          </Button>
        </Popover>
      </div>
    </div>
  );
}
