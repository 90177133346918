import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { DeveloperRecord } from "../../types/postmatch.types";
import { logClickActivity } from "../../utils/activity-logs/activity-logs";
import styles from "../postmatch-table-link-new-tab/postmatch-table-link-new-tab.module.css";
import { PostmatchTableDeveloperActivity } from "../postmatch-table-render/postmatch-table-developer-activity";
import { PostmatchTableLinkNewTab } from "../postmatch-table-link-new-tab/postmatch-table-link-new-tab";

export function DashboardRenderDeveloperLinkEntity({
  value = {},
  activityKey,
  activityAction,
  logActivity,
  isTruncationEnabled = false,
}: any) {
  const prefix = process.env.REACT_APP_POSTMATCH_URL_PREFIX;
  const isRouterEnabled = process.env.REACT_APP_USE_ROUTER;
  const { link } = value;
  const developerRecord: DeveloperRecord = {
    name: value.text,
    img: value.imageUrl,
    developerId: value.developerId,
    developerIdHash: value.developerIdHash,
    jobId: value.jobId,
  };

  const onClickDeveloper = () => {
    logClickActivity(activityKey, activityAction, link, logActivity);
  };

  if (link && prefix) {
    return (
      <a
        href={`${prefix}${link}`}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.link}
      >
        <PostmatchTableDeveloperActivity
          developerRecord={developerRecord}
          onClickDeveloper={onClickDeveloper}
          isTruncationEnabled={isTruncationEnabled}
        />
      </a>
    );
  }

  if (link && isRouterEnabled) {
    return (
      <Router>
        <PostmatchTableLinkNewTab link={link}>
          <PostmatchTableDeveloperActivity
            developerRecord={developerRecord}
            onClickDeveloper={onClickDeveloper}
            isTruncationEnabled={isTruncationEnabled}
          />
        </PostmatchTableLinkNewTab>
      </Router>
    );
  }

  if (link) {
    return (
      <PostmatchTableLinkNewTab link={link}>
        <PostmatchTableDeveloperActivity
          developerRecord={developerRecord}
          onClickDeveloper={onClickDeveloper}
          isTruncationEnabled={isTruncationEnabled}
        />
      </PostmatchTableLinkNewTab>
    );
  }

  return (
    <PostmatchTableDeveloperActivity
      developerRecord={developerRecord}
      onClickDeveloper={onClickDeveloper}
      isTruncationEnabled={isTruncationEnabled}
    />
  );
}
