import React from "react";
import { PostmatchTableProps } from "../postmatch-table/postmatch-table.types";
import { DashboardRenderLinkEntity } from "../dashboard-render-link-entity/dashboard-render-link-entity";

export function PostmatchTableRenderLink(
  activityKey: string,
  activityAction: string,
  logActivity: PostmatchTableProps["logActivity"],
) {
  return function (value: { link: string; text: string }) {
    return (
      <DashboardRenderLinkEntity
        value={value}
        activityKey={activityKey}
        activityAction={activityAction}
        logActivity={logActivity}
      />
    );
  };
}
