import { ColumnOrderState, SortDirection } from "@tanstack/react-table";
import moment from "moment";
import { isEntityValid } from "../postmatch-table-columns/postmatch-table-columns.utils";
import {
  ColumnOrder,
  ColumnVisibility,
  Filter,
  SaveEngagementDashboardPreferences,
  Sort,
} from "./dashboard.types";
import { LogActivity } from "../postmatch-table/postmatch-table.types";
import { logFiltering } from "../../utils/activity-logs/dashboard-activity-logs";

const datePattern = /\d{2}\/\d{2}\/\d{4}/;

export function reorderColumn(
  draggedColumnId: string,
  targetColumnId: string,
  columnOrder: string[],
): ColumnOrderState {
  columnOrder.splice(
    columnOrder.indexOf(targetColumnId),
    0,
    columnOrder.splice(columnOrder.indexOf(draggedColumnId), 1)[0] as string,
  );
  return [...columnOrder];
}

export function getNextSortingState(
  currentSortingState: false | SortDirection,
) {
  switch (currentSortingState) {
    case false:
      return "asc";
    case "asc":
      return "desc";
    case "desc":
      return false;
    default:
      return false;
  }
}

export function sortTextFunc(a: string, b: string) {
  const aLowerCase = (
    typeof a === "string" ? a.toLowerCase() : undefined
  ) as string;
  const bLowerCase = (
    typeof b === "string" ? b.toLowerCase() : undefined
  ) as string;
  if (!isEntityValid(aLowerCase)) {
    return -1;
  }
  if (!isEntityValid(bLowerCase)) {
    return 1;
  }
  if (aLowerCase < bLowerCase) {
    return -1;
  }
  if (aLowerCase > bLowerCase) {
    return 1;
  }
  return 0;
}

export function sortText(rowA: any, rowB: any, columnId: any) {
  const a = rowA?.original?.[columnId];
  const b = rowB?.original?.[columnId];
  return sortTextFunc(a, b);
}

export function filterNumericalRow(
  rowValue: any,
  normalizedMin: any,
  normalizedMax: any,
) {
  if (Number.isNaN(normalizedMin) && Number.isNaN(normalizedMax)) {
    return true;
  }
  if (Number.isNaN(normalizedMax)) {
    return rowValue >= normalizedMin;
  }
  if (Number.isNaN(normalizedMin)) {
    return rowValue <= normalizedMax;
  }
  return rowValue >= normalizedMin && rowValue <= normalizedMax;
}

export function filterNumericalRange(row: any, columnId: any, value: any) {
  const min = value?.[0];
  const max = value?.[1];
  const normalizedMin = parseFloat(min);
  const normalizedMax = parseFloat(max);
  const rowValue = row?.original?.[columnId];
  return filterNumericalRow(rowValue, normalizedMin, normalizedMax);
}

export function filterDateRange(row: any, columnId: any, value: any) {
  const min = value?.[0];
  const max = value?.[1];
  const minDate = datePattern.test(min) ? min : "";
  const maxDate = datePattern.test(max) ? max : "";
  const normalizedMin = parseInt(
    moment.utc(minDate, "MM/DD/YYYY").startOf("day").format("X"),
    10,
  );
  const normalizedMax = parseInt(
    moment.utc(maxDate, "MM/DD/YYYY").endOf("day").format("X"),
    10,
  );
  const rowValue = row?.original?.[columnId];
  return filterNumericalRow(rowValue, normalizedMin, normalizedMax);
}

export function isNumericalInputValid(minMax: string[]) {
  return minMax?.every((item) => {
    if (!item) {
      return true;
    }
    return !Number.isNaN(parseFloat(item));
  });
}

export function isDateInputValid(minMax: string[]) {
  return minMax?.every((item) => {
    if (!item) {
      return true;
    }
    return datePattern.test(item);
  });
}

export function filterText(row: any, columnId: any, value: any) {
  if (!value?.length || value?.every((item: any) => !item)) {
    return true;
  }
  const rowValueLowerCase = row?.original?.[columnId]?.toLowerCase();
  return value?.some((item: any) =>
    rowValueLowerCase?.includes(item?.toLowerCase()),
  );
}

export async function saveFilterPreferences(
  columnOrder: ColumnOrder,
  columnVisibility: ColumnVisibility,
  sort: Sort[],
  filter: Filter[],
  saveEngagementDashboardPreferences: SaveEngagementDashboardPreferences,
  logActivity: LogActivity,
  columnId: string,
  activeFilter: string[],
) {
  saveEngagementDashboardPreferences({
    columnOrder,
    columnVisibility,
    sort,
    filter,
  });
  await logFiltering(logActivity, columnId, activeFilter);
}

export function getColumnOrderToPreload(
  savedColumnOrder: any,
  defaultColumns: any,
) {
  const defaultColumnKeys = Object.keys(defaultColumns);
  const existingColumns: any = [];
  savedColumnOrder.forEach((savedColumn: any) => {
    if (defaultColumnKeys.includes(savedColumn)) {
      existingColumns.push(savedColumn);
    }
  });
  const newColumns: any = [];
  defaultColumnKeys.forEach((defaultColumn) => {
    if (!savedColumnOrder.includes(defaultColumn)) {
      newColumns.push(defaultColumn);
    }
  });
  return [...existingColumns, ...newColumns];
}

export function getColumnVisibilityToPreload(
  savedColumnVisibility: any,
  defaultColumns: any,
) {
  const savedColumnVisibilityKeys = Object.keys(savedColumnVisibility);
  const defaultColumnKeys = Object.keys(defaultColumns);
  const existingColumns: any = {};
  Object.entries(savedColumnVisibility).forEach(([savedKey, savedValue]) => {
    if (defaultColumnKeys.includes(savedKey)) {
      existingColumns[savedKey] = savedValue;
    }
  });
  const newColumns: any = {};
  defaultColumnKeys.forEach((defaultColumn) => {
    if (!savedColumnVisibilityKeys.includes(defaultColumn)) {
      newColumns[defaultColumn] = true;
    }
  });
  return { ...existingColumns, ...newColumns };
}
