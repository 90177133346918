import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { logClickActivity } from "../../utils/activity-logs/activity-logs";
import { getPlaceholderValue } from "../../utils/get-placeholder-value/get-placeholder-value";
import { PostmatchTableFieldActivity } from "../postmatch-table-render/postmatch-table-field-activity";
import { PostmatchTableLinkNewTab } from "../postmatch-table-link-new-tab/postmatch-table-link-new-tab";
import styles from "../postmatch-table-link-new-tab/postmatch-table-link-new-tab.module.css";

export function DashboardRenderLinkEntity({
  value = {},
  activityKey,
  activityAction,
  logActivity,
  isTruncationEnabled = false,
}: any) {
  const prefix = process.env.REACT_APP_POSTMATCH_URL_PREFIX;
  const isRouterEnabled = process.env.REACT_APP_USE_ROUTER;
  const { link, text } = value;

  const onClickField = () => {
    logClickActivity(activityKey, activityAction, link, logActivity);
  };

  if (link && text && prefix) {
    return (
      <a
        href={`${prefix}${link}`}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.link}
      >
        <PostmatchTableFieldActivity
          onClickField={onClickField}
          text={text}
          isTruncationEnabled={isTruncationEnabled}
        />
      </a>
    );
  }

  if (link && text && !prefix && isRouterEnabled) {
    return (
      <Router>
        <PostmatchTableLinkNewTab link={link}>
          <PostmatchTableFieldActivity
            onClickField={onClickField}
            text={text}
            isTruncationEnabled={isTruncationEnabled}
          />
        </PostmatchTableLinkNewTab>
      </Router>
    );
  }

  if (link && text && !prefix && !isRouterEnabled) {
    return (
      <PostmatchTableLinkNewTab link={link}>
        <PostmatchTableFieldActivity
          onClickField={onClickField}
          text={text}
          isTruncationEnabled={isTruncationEnabled}
        />
      </PostmatchTableLinkNewTab>
    );
  }

  return <div>{getPlaceholderValue(text)}</div>;
}
