export const PLACEHOLDER = "N/A";

export function isValueValid(value: any) {
  // Not using ?? due to browser compatibility.
  return !!value || value === 0;
}

export function isValueValidAndNotPlaceholder(value: any) {
  return (
    isValueValid(value) &&
    value !== PLACEHOLDER &&
    value !== PLACEHOLDER.toLowerCase()
  );
}

export function getPlaceholderValueSortNumber(value: any) {
  return isValueValidAndNotPlaceholder(value) ? value : undefined;
}

export function getPlaceholderValueSortText(value: any) {
  return isValueValidAndNotPlaceholder(value) && typeof value === "string"
    ? value.toLowerCase()
    : PLACEHOLDER;
}

export function getPlaceholderValue(value: any) {
  return isValueValid(value) ? value : PLACEHOLDER;
}

export function getPlaceholderDash(value: any) {
  return isValueValid(value) ? value : "-";
}
