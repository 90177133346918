import { TABLE_ACTIVITY_TYPES } from "./table-activity.types";

function formatSortingState(sortingState: any) {
  switch (sortingState) {
    case "desc":
      return "descend";
    case "asc":
      return "ascend";
    default:
      return "cancel";
  }
}

export async function logSorting(
  logActivity: any,
  columnName: string,
  sortingState: any,
) {
  const loggingPayload = {
    columnName,
    order: formatSortingState(sortingState),
  };
  await logActivity(
    TABLE_ACTIVITY_TYPES.KEY,
    TABLE_ACTIVITY_TYPES.POSTMATCH_TABLE_ORDERED,
    null,
    loggingPayload,
  );
}

export async function logFiltering(
  logActivity: any,
  columnName: any,
  filteredValues: any,
) {
  const loggingPayload = {
    columnName,
    filteredValues,
  };
  await logActivity(
    TABLE_ACTIVITY_TYPES.KEY,
    TABLE_ACTIVITY_TYPES.POSTMATCH_TABLE_FILTERED,
    null,
    loggingPayload,
  );
}
