import React, { useState } from "react";
import { Column, Table, flexRender, Header } from "@tanstack/react-table";
import { useDrag, useDrop } from "react-dnd";
import classNames from "classnames";
import { FormattedDeveloper } from "../../types/formatted-developer.types";
import { logSorting } from "../../utils/activity-logs/dashboard-activity-logs";
import { DashboardHeaderItemFilter } from "../dashboard-header-item-filter/dashboard-header-item-filter";
import {
  getNextSortingState,
  reorderColumn,
} from "../dashboard/dashboard.utils";
import { SortSvg } from "../svgs/Sort";
import styles from "./dashboard-header-item.module.css";
import {
  ColumnOrder,
  ColumnVisibility,
  EngagementDashboardPreferences,
  Filter,
  SaveEngagementDashboardPreferences,
  Sort,
} from "../dashboard/dashboard.types";
import { LogActivity } from "../postmatch-table/postmatch-table.types";

export function DashboardHeaderItem({
  header,
  table,
  saveEngagementDashboardPreferences,
  isFetchingPreferences,
  engagementDashboardPreferences,
  resetCount,
  logActivity,
}: {
  header: Header<any, unknown>;
  table: Table<FormattedDeveloper>;
  saveEngagementDashboardPreferences: SaveEngagementDashboardPreferences;
  isFetchingPreferences: boolean;
  engagementDashboardPreferences: EngagementDashboardPreferences;
  resetCount: number;
  logActivity: LogActivity;
}) {
  const { getState, setColumnOrder } = table;
  const dashboardState = getState();
  const columnOrder = dashboardState.columnOrder as ColumnOrder;
  const columnVisibility = dashboardState.columnVisibility as ColumnVisibility;
  const columnFilters = dashboardState.columnFilters as Filter[];
  const sorting = dashboardState.sorting as Sort[];

  const { column }: any = header;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const isDragEnabled = !isPopoverOpen;

  const [, dropRef] = useDrop({
    accept: "column",
    drop: () => {
      saveEngagementDashboardPreferences({
        columnOrder,
        columnVisibility,
        sort: sorting,
        filter: columnFilters,
      });
    },
    hover: (draggedColumn: Column<any>, monitor) => {
      if (
        monitor.canDrop() &&
        monitor.isOver({ shallow: true }) &&
        draggedColumn.id !== column.id
      ) {
        const newColumnOrder = reorderColumn(
          draggedColumn.id,
          column.id,
          columnOrder,
        );
        setColumnOrder(newColumnOrder);
      }
    },
  });

  const [, dragRef, previewRef] = useDrag({
    collect: (monitor) => ({
      isDragging: Boolean(monitor.getItem()),
    }),
    item: () => column,
    type: "column",
    canDrag: isDragEnabled,
  });

  const sortFn = header.column.getToggleSortingHandler();

  const currentSortingState = column.getIsSorted();

  const onSort = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (typeof sortFn === "function") {
      const outdatedSortingState = column.getIsSorted();
      const correctSortingState = getNextSortingState(outdatedSortingState);
      const sortingPayload = !correctSortingState
        ? []
        : [
            {
              id: column.id,
              desc: correctSortingState === "desc",
            },
          ];
      sortFn(e);
      saveEngagementDashboardPreferences({
        columnOrder,
        columnVisibility,
        sort: sortingPayload,
        filter: columnFilters,
      });
      await logSorting(logActivity, column.id, correctSortingState);
    }
  };

  return (
    <th ref={dropRef} colSpan={header.colSpan}>
      <div ref={previewRef}>
        <div
          ref={dragRef}
          className={classNames(styles.headerItem, {
            [styles.grab]: isDragEnabled,
          })}
        >
          <div className={styles.filtersContainer}>
            <div>
              {flexRender(header.column.columnDef.header, header.getContext())}
            </div>

            {header.column.getCanSort() && (
              <div onClick={(e) => onSort(e)} className={styles.sort}>
                <SortSvg sortedState={currentSortingState} />
              </div>
            )}

            {header.column.getCanFilter() && (
              <DashboardHeaderItemFilter
                column={header.column}
                tableState={{
                  columnOrder,
                  columnVisibility,
                  columnFilters,
                  sorting,
                }}
                saveEngagementDashboardPreferences={
                  saveEngagementDashboardPreferences
                }
                isFetchingPreferences={isFetchingPreferences}
                engagementDashboardPreferences={engagementDashboardPreferences}
                resetCount={resetCount}
                logActivity={logActivity}
                isPopoverOpen={isPopoverOpen}
                setIsPopoverOpen={setIsPopoverOpen}
              />
            )}
          </div>
        </div>
      </div>
    </th>
  );
}
