import React from "react";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Tag, TagColors } from "../tags/tags";
import {
  ITimeEntry,
  TimeCardStatus,
  getTimeCardStatusText,
} from "../time-card-summary-types";
import { formatDuration } from "../time-card.util";
import styles from "./time-card-body.module.css";

export const TimeCardBodyComponent = ({
  timeCardStatus,
  developerName,
  developerImage,
  totalMinutes,
  timeEntries = [],
}: {
  timeCardStatus: string;
  developerName: string;
  developerImage: string;
  totalMinutes: number;
  timeEntries: ITimeEntry[];
}) => {
  const formattedTotalHours = formatDuration(totalMinutes ? totalMinutes : 0);
  let color = TagColors.DEFAULT;
  let icon;
  switch (timeCardStatus) {
    case TimeCardStatus.APPROVED:
      color = TagColors.SUCCESS;
      icon = CheckCircleOutlined;
      break;
    case TimeCardStatus.PENDING_APPROVAL:
      color = TagColors.INFO;
      icon = InfoCircleOutlined;
      break;
    case TimeCardStatus.REJECTED:
      color = TagColors.DANGER;
      icon = CloseCircleOutlined;
      break;
  }

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.meta}>
          {developerImage ? (
            <img
              src={developerImage}
              alt="Developer Image"
              className={styles["developer-img"]}
            />
          ) : (
            <div className={styles.placeholder}>
              <UserOutlined />
            </div>
          )}
          <span className={styles.name}>{developerName}</span>
          <Tag
            text={getTimeCardStatusText(timeCardStatus)}
            icon={icon}
            color={color}
          />
        </div>
        <div className={styles["time-card-body-box"]}>
          {timeEntries.map((timeEntry: ITimeEntry) => {
            const loggedTime = formatDuration(
              timeEntry.durationMinutes ? timeEntry.durationMinutes : 0,
            );

            return (
              <div
                className={styles["time-card-day-box"]}
                key={`time-entry-key-${timeEntry.from}&${timeEntry.to}`}
              >
                <div className={styles["time-card-day-box-date"]}>
                  {timeEntry.dayTitle}
                  <br />
                  {timeEntry.dayDate}
                </div>
                <div className={styles["time-card-day-box-time"]}>
                  <strong>{loggedTime}</strong>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.footer}>Total: {formattedTotalHours}</div>
    </div>
  );
};
