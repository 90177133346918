export interface ITimeEntry {
  id: number;
  timeCardId: string;
  from: string;
  to: string;
  dayTitle: string;
  // Sun / Mon
  dayDate: string;
  // Aug 4 / Oct 5
  durationMinutes: number;
}

export interface IApproval {
  id: number;
  approvalStatus: string;
  approvalDate: string;
  createdAt: string;
  approvalTitle: string;
  approvalBody: string;
  approvalChainStructure: IApprovalChainStructure;
  approverName: string;
  approvalType: string;
  approvalComment: {
    heading: string;
    body: string;
    commentorName: string;
    commentDate: string;
  } | null;
}

export interface IApprovalChainStructure {
  id: number;
  approverEmail: string;
  approverType: string;
}

export interface IActivityLog {
  id: number;
  activityTitle: string;
  // Timecard approved by X \n ${comment} / Timecard rejected by X \n ${comment} / X commented \n ${comment}
  createdAt: string;
}

export interface ITimeCardSummary {
  id: string;
  developerId: number;
  developerImage?: string;
  developerName: string;
  projectId: number;
  jobId: number;
  periodStart: string;
  periodEnd: string;
  totalMinutes: number;
  intervalString: string;
  timeEntries: ITimeEntry[];
  approvals: IApproval[];
  activityLogs: IActivityLog[];
}

export interface TimeCardSummaryProps {
  data: ITimeCardSummary;
  isLoading: boolean;
  onTimeCardApprove?: (comment: string) => void;
  onTimeCardComment?: (comment: string) => void;
  onTimeCardReject?: (comment: string) => void;
  approval?: IApproval;
  approveActionProgress?: boolean;
  rejectActionProgress?: boolean;
  commentActionProgress?: boolean;
  resetCommentTrigger?: boolean;
  setCommenTrigger?: (value: boolean) => void;
  disableApprovalWorkflow?: boolean;
}

export const TimeCardStatus = {
  QUEUED: "QUEUED",
  PENDING_APPROVAL: "PENDING_APPROVAL",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  AUTO_APPROVED: "AUTO_APPROVED",
};

export const getTimeCardStatusText = (status: string) => {
  if (status === TimeCardStatus.QUEUED) return "Queued";
  if (status === TimeCardStatus.PENDING_APPROVAL) return "Waiting for approval";
  if (status === TimeCardStatus.APPROVED) return "Approved";
  if (status === TimeCardStatus.REJECTED) return "Rejected";
  if (status === TimeCardStatus.AUTO_APPROVED) return "Approved";
  return "Approved";
};

export interface TimeCardActionsProps {
  isLoading: boolean;
  onTimeCardApprove?: (comment: string) => void;
  onTimeCardReject?: (comment: string) => void;
  onTimeCardComment?: (comment: string) => void;
  timeCardStatus: string;
  approveActionProgress?: boolean;
  rejectActionProgress?: boolean;
  commentActionProgress?: boolean;
  resetCommentTrigger?: boolean;
  setCommenTrigger?: (value: boolean) => void;
}
