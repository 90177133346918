import React from "react";
import { Button } from "antd";
import { DownloadSvg } from "../svgs/DownloadSvg";
import styles from "./export-button.module.css";
import classnames from "classnames";
import { ExportButtonProps } from "./export-button.types";

export function ExportButton({
  onClick,
  isLoading,
  isDisabled,
}: ExportButtonProps) {
  return (
    <div
      className={classnames(styles.root, {
        [styles.disableHover]: !isLoading && !isDisabled,
      })}
    >
      <Button
        onClick={onClick}
        loading={isLoading}
        disabled={isDisabled}
        type="primary"
        icon={
          <span
            style={{
              marginTop: 4,
              marginRight: 8,
            }}
          >
            <DownloadSvg />
          </span>
        }
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "12px 20px 12px 16px",
          width: 252,
          height: 48,
          borderRadius: 6,
        }}
      >
        Export engagements
      </Button>
    </div>
  );
}
