import React from "react";
import { PostmatchSummaryBlocks } from "../postmatch-summary-blocks/postmatch-summary-blocks";
import styles from "./postmatch-summary.module.css";
import { PostmatchSummaryProps } from "./postmatch-summary.types";

export function PostmatchSummary({
  postmatchCompany,
  title,
  isLoading,
  enableHoursBilled,
  children,
  alignLeft = false,
  enableMargins = false,
}: PostmatchSummaryProps) {
  const styleLeft = !enableHoursBilled || alignLeft;
  return (
    <div className={styles.summary}>
      <h2>{title}</h2>
      {children ? children : null}
      <PostmatchSummaryBlocks
        postmatchCompany={postmatchCompany}
        isLoading={isLoading}
        styleLeft={styleLeft}
        enableHoursBilled={enableHoursBilled}
        enableMargins={enableMargins}
      />
    </div>
  );
}
