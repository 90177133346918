import React from "react";

export const ApprovedSvg = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.81733 11.7884L6.88142 9.85248C6.76604 9.73709 6.62101 9.67807 6.44633 9.6754C6.27165 9.67272 6.12395 9.73175 6.00323 9.85248C5.88251 9.9732 5.82215 10.1196 5.82215 10.2916C5.82215 10.4636 5.88251 10.6099 6.00323 10.7306L8.29008 13.0175C8.44072 13.1681 8.61647 13.2435 8.81733 13.2435C9.01818 13.2435 9.19392 13.1681 9.34456 13.0175L13.9808 8.3813C14.0962 8.26592 14.1552 8.12089 14.1579 7.94621C14.1605 7.77153 14.1015 7.62383 13.9808 7.50311C13.86 7.38239 13.7137 7.32202 13.5417 7.32202C13.3697 7.32202 13.2233 7.38239 13.1026 7.50311L8.81733 11.7884ZM10.0014 17.9165C8.90647 17.9165 7.87728 17.7088 6.91383 17.2932C5.95037 16.8777 5.1123 16.3137 4.39962 15.6013C3.68693 14.889 3.12271 14.0513 2.70698 13.0882C2.29124 12.1252 2.08337 11.0962 2.08337 10.0013C2.08337 8.90635 2.29115 7.87716 2.70671 6.91371C3.12226 5.95025 3.68622 5.11218 4.39858 4.3995C5.11096 3.68681 5.94866 3.12259 6.91169 2.70686C7.8747 2.29112 8.90368 2.08325 9.99862 2.08325C11.0936 2.08325 12.1228 2.29103 13.0862 2.70659C14.0497 3.12214 14.8877 3.6861 15.6004 4.39846C16.3131 5.11084 16.8773 5.94854 17.2931 6.91157C17.7088 7.87458 17.9167 8.90356 17.9167 9.9985C17.9167 11.0934 17.7089 12.1226 17.2933 13.0861C16.8778 14.0495 16.3138 14.8876 15.6015 15.6003C14.8891 16.313 14.0514 16.8772 13.0884 17.2929C12.1253 17.7087 11.0964 17.9165 10.0014 17.9165ZM10 16.6666C11.8611 16.6666 13.4375 16.0207 14.7292 14.7291C16.0209 13.4374 16.6667 11.861 16.6667 9.9999C16.6667 8.13879 16.0209 6.5624 14.7292 5.27073C13.4375 3.97907 11.8611 3.33323 10 3.33323C8.13891 3.33323 6.56252 3.97907 5.27085 5.27073C3.97919 6.5624 3.33335 8.13879 3.33335 9.9999C3.33335 11.861 3.97919 13.4374 5.27085 14.7291C6.56252 16.0207 8.13891 16.6666 10 16.6666Z"
      fill="#1F7A37"
    />
  </svg>
);
