import React, { Fragment } from "react";
import { IActivityLog, IApproval } from "../time-card-summary-types";
import styles from "./time-card-notifications.module.css";
import moment from "moment";
const formatApprovalFlowDate = (date: string) => {
  const dateFormat = "MMM DD, YYYY, hh:mm A";
  return moment.utc(date).format(dateFormat);
};

export const TimeCardNotificationsComponent = ({
  approvals = [],
  activityLogs = [],
  disableApprovalWorkflow,
}: {
  approvals: IApproval[];
  activityLogs: IActivityLog[];
  disableApprovalWorkflow?: boolean;
}) => {
  return (
    <Fragment>
      {!disableApprovalWorkflow ? (
        <div className={[styles.container, "approvals"].join(" ")}>
          <div className={styles.header}>
            <h2 className={styles.heading}>Approvals</h2>
          </div>
          <div className={styles.body}>
            {approvals.map((approval: IApproval) => {
              return (
                <Fragment>
                  <div className={styles["approval-item-container"]}>
                    <h4 className={styles["approval-item-title"]}>
                      {approval.approvalTitle}
                    </h4>
                    <p
                      className={[
                        styles["approval-item-text"],
                        `${
                          approval.approvalComment
                            ? styles["approval-item-comment"]
                            : ""
                        }`,
                      ].join()}
                    >
                      {approval.approvalBody}
                    </p>
                  </div>
                  {approval.approvalComment ? (
                    <Fragment>
                      <div className={styles["approval-item-container"]}>
                        <h4 className={styles["approval-item-title"]}>
                          {approval.approvalComment.heading}
                        </h4>
                        <p
                          className={[
                            styles["approval-item-text"],
                            `${styles["approval-item-comment"]}`,
                          ].join()}
                        >
                          {approval.approvalComment.body}
                        </p>
                        <p className={styles["approval-item-commentor"]}>
                          {approval.approvalComment.commentorName}
                        </p>
                        <p className={styles["approval-item-commentDate"]}>
                          {approval.approvalComment.commentDate}
                        </p>
                      </div>
                    </Fragment>
                  ) : null}
                </Fragment>
              );
            })}
          </div>
        </div>
      ) : null}

      <div className={[styles["activity-container"], "activity-log"].join(" ")}>
        <div className={styles["activity-header"]}>
          <h2 className={styles["activity-heading"]}>Activity log</h2>
        </div>
        <div className={styles["activity-activities"]}>
          {activityLogs.map((activityLog: IActivityLog) => {
            const activityDate = formatApprovalFlowDate(activityLog.createdAt);

            return (
              <div className={styles["log-item-container"]}>
                <h4 className={styles["log-item-title"]}>
                  {activityLog.activityTitle}
                </h4>
                <p className={styles["log-item-activity-date"]}>
                  {activityDate}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </Fragment>
  );
};
