import axios, { AxiosRequestConfig } from "axios";
import { parseISO, format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

export const exportPostmatchEngagements = async (
  endpoint: string,
  token: string | null,
  startDate: string,
  endDate: string,
  salesforceAccountId: string | null,
  companyName: string,
) => {
  const authorization = `Bearer ${token}`;
  const body = {
    startDate,
    endDate,
    ...(salesforceAccountId && { salesforceAccountIds: [salesforceAccountId] }),
  };
  const config: AxiosRequestConfig = {
    responseType: "blob",
    headers: {
      Authorization: authorization,
      Accept: "text/csv",
    },
    timeout: 30000,
  };
  const dateFormat = "dd-MMM-yyyy";
  const formattedStartDate = format(
    utcToZonedTime(parseISO(startDate), "UTC"),
    dateFormat,
  );
  const formattedEndDate = format(
    utcToZonedTime(parseISO(endDate), "UTC"),
    dateFormat,
  );
  const response = await axios.post(endpoint, body, config);
  const url = URL.createObjectURL(response.data);
  const file = companyName
    ? `${companyName.replace(
        /\s/g,
        "-",
      )}-${formattedStartDate}-to-${formattedEndDate}`
    : `Engagements-${formattedStartDate}-to-${formattedEndDate}`;
  const filename = `${file}.csv`;
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
  return filename;
};
