import get from "lodash/get";
import {
  PLACEHOLDER,
  isValueValid,
} from "../../utils/get-placeholder-value/get-placeholder-value";

const getSortValues = (a: any, b: any, field: any) => {
  return [get(a, field), get(b, field)];
};

const sortComparision = (a: any, b: any) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

export function isEntityValid(entity: any) {
  return (
    isValueValid(entity) &&
    entity !== PLACEHOLDER &&
    entity !== PLACEHOLDER.toLowerCase()
  );
}

export function sortValuesDescOrder(a: any, b: any) {
  if (!isEntityValid(a)) {
    return 1;
  }
  if (!isEntityValid(b)) {
    return -1;
  }
  return sortComparision(b, a);
}

export function sortValuesAscOrder(a: any, b: any) {
  if (!isEntityValid(a)) {
    return -1;
  }
  if (!isEntityValid(b)) {
    return 1;
  }
  return sortComparision(a, b);
}

export function sortValuesAscOrderAppendInvalids(a: any, b: any) {
  if (!isEntityValid(a)) {
    return 1;
  }
  if (!isEntityValid(b)) {
    return -1;
  }
  return sortComparision(a, b);
}

export const sortValuesForTable = (a: any, b: any, field: any) => {
  const [first, second] = getSortValues(a, b, field);
  return sortValuesAscOrder(first, second);
};
