import React from "react";
import { UserOutlined } from "@ant-design/icons";
import classNames from "classnames";
import styles from "./timesheets-table-contractor.module.css";

export function TimesheetsTableContractor({
  contractor,
  img,
  isDeveloperClickable,
  onClick = () => {},
}: {
  contractor: string;
  img: string;
  isDeveloperClickable: boolean;
  onClick?: () => void;
}) {
  return (
    <div
      className={classNames(styles.developerRecord, {
        [styles.developerRecordButton]: isDeveloperClickable,
      })}
      onClick={onClick}
    >
      {img ? (
        <img src={img} />
      ) : (
        <div className={styles.placeholder}>
          <UserOutlined />
        </div>
      )}
      <div
        className={classNames(styles.name, {
          [styles.nameButton]: isDeveloperClickable,
        })}
      >
        {contractor}
      </div>
    </div>
  );
}
