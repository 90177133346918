import React from "react";
import classNames from "classnames";
import styles from "./tags.module.css";

export enum TagColors {
  INFO = "info",
  DANGER = "danger",
  SUCCESS = "success",
  DEFAULT = "default",
}

export interface TagProps {
  text: string;
  icon?: React.FunctionComponent;
  color: TagColors;
}

export function Tag({ text, color, icon: Icon }: TagProps) {
  let tagClassName;
  switch (color) {
    case TagColors.DANGER:
      tagClassName = styles.danger;
      break;
    case TagColors.INFO:
      tagClassName = styles.info;
      break;
    case TagColors.SUCCESS:
      tagClassName = styles.success;
      break;
    default:
      tagClassName = styles.default;
      break;
  }
  return (
    <span className={classNames(styles.base, tagClassName)}>
      {Icon ? <Icon /> : null}
      {text}
    </span>
  );
}
