import React from "react";
import { PostmatchTableProps } from "../postmatch-table/postmatch-table.types";
import { DashboardRenderLinkEntity } from "../dashboard-render-link-entity/dashboard-render-link-entity";

export function DashboardRenderLink(
  activityKey: string,
  activityAction: string,
  logActivity: PostmatchTableProps["logActivity"],
  field: string,
) {
  return function (info: any) {
    const value = info?.row?.original?.[field] || {};
    return (
      <DashboardRenderLinkEntity
        value={value}
        activityKey={activityKey}
        activityAction={activityAction}
        logActivity={logActivity}
        isTruncationEnabled
      />
    );
  };
}
