export function flatArray<T>(matrix: T[][]) {
  // Not using flat directly to avoid browser compatibility issues
  return matrix.reduce((acc, curr) => {
    acc.push(...curr);
    return acc;
  }, []);
}

export function areArraysEqual<T>(array1: T[], array2: T[]) {
  return (
    array1.length === array2.length &&
    array1.every((item, index) => item === array2[index])
  );
}

export function filterObject(object: Record<string, any>) {
  return Object.entries(object).reduce(
    (acc: Record<string, any>, [key, value]) => {
      if (value) {
        acc[key] = value;
      }
      return acc;
    },
    {},
  );
}

export function filterObjectOfArrays<T>(object: Record<string, T>) {
  return Object.entries(object).reduce(
    (acc: Record<string, T>, [key, value]) => {
      if (Array.isArray(value)) {
        acc[key] = value;
      }
      return acc;
    },
    {},
  );
}

export function isArrayNotEmpty<T>(array: T[]) {
  return Array.isArray(array) && array.length;
}
