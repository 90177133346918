import React from "react";
import { ButtonDesignSystemV2 } from "../button-design-system-v2/button-design-system-v2";
import styles from "./timesheets-table-render-jibble.module.css";

export function TimesheetsTableRenderJibble() {
  return function (jibble: string) {
    return (
      <ButtonDesignSystemV2
        isLinkNewTab
        href={jibble}
        text="Update in Jibble"
        buttonClassName={styles.button}
      />
    );
  };
}
