import React from "react";
import classnames from "classnames";
import { PostmatchTableColumnsHeader } from "../postmatch-table-columns-header/postmatch-table-columns-header";
import { PostmatchTableRender } from "../postmatch-table-render/postmatch-table-render";
import { PostmatchTableRenderLink } from "../postmatch-table-render/postmatch-table-render-link";
import { PostmatchTableRenderSalesforce } from "../postmatch-table-render/postmatch-table-render-salesforce";
import { sortValuesForTable } from "./postmatch-table-columns.utils";
import styles from "./postmatch-table-columns.module.css";
import { PostmatchTableRenderDeveloper } from "../postmatch-table-render/postmatch-table-render-developer";
import { PostmatchTableRenderLinkDeveloper } from "../postmatch-table-render/postmatch-table-render-link-developer";
import { PostmatchTableProps } from "../postmatch-table/postmatch-table.types";
import {
  TableDataKeyToNameMap,
  TableDataKeyEnum,
  TableSort,
} from "./postmatch-table-columns.constants";
import { PostmatchTableRenderDuration } from "../postmatch-table-render/postmatch-table-render-duration";
import { ContactsWidth } from "../postmatch-table-columns-header/postmatch-table-columns-header.types";
import { PostmatchTableRenderFeedback } from "../postmatch-table-render/postmatch-table-render-feedback";

export function getPostmatchTableColumns(
  filters: any,
  postmatchActivityType: any,
  logActivity: PostmatchTableProps["logActivity"],
  onClickDeveloperOpenDDP: any,
  isSomePreOnboarding: boolean,
  isOutOfSpace: boolean,
  contactsWidth: ContactsWidth,
  isSelfServe: boolean,
  onClickFeedbackTable: PostmatchTableProps["onClickFeedbackTable"],
  disableDDP?: boolean,
) {
  const { KEY, ACTIONS } = postmatchActivityType || {};
  return [
    {
      title: (
        <PostmatchTableColumnsHeader
          text={TableDataKeyToNameMap[TableDataKeyEnum.companyNameWithLink]}
          className={styles.headerSort}
        />
      ),
      dataIndex: TableDataKeyEnum.companyNameWithLink,
      className: styles.column,
      sorter: (a: any, b: any) =>
        sortValuesForTable(a, b, TableSort[TableDataKeyEnum.companyName]),
      render: PostmatchTableRenderLink(
        KEY,
        ACTIONS?.CLICKED_POSTMATCH_COMPANY_LINK,
        logActivity,
      ),
      width: "150px",
    },

    {
      title: (
        <PostmatchTableColumnsHeader
          text={TableDataKeyToNameMap[TableDataKeyEnum.developerRecordWithLink]}
          className={styles.headerSort}
        />
      ),
      dataIndex: TableDataKeyEnum.developerRecordWithLink,
      className: styles.column,
      sorter: (a: any, b: any) =>
        sortValuesForTable(a, b, TableSort[TableDataKeyEnum.developerName]),
      render: PostmatchTableRenderLinkDeveloper(
        KEY,
        ACTIONS?.CLICKED_POSTMATCH_DEVELOPER_LINK,
        logActivity,
      ),
      width: "150px",
    },

    {
      title: (
        <PostmatchTableColumnsHeader
          text={TableDataKeyToNameMap[TableDataKeyEnum.jobTitleWithLink]}
          className={styles.headerSort}
        />
      ),
      dataIndex: TableDataKeyEnum.jobTitleWithLink,
      className: styles.column,
      sorter: (a: any, b: any) =>
        sortValuesForTable(a, b, TableSort[TableDataKeyEnum.jobTitle]),
      render: PostmatchTableRenderLink(
        KEY,
        ACTIONS?.CLICKED_POSTMATCH_JOB_LINK,
        logActivity,
      ),
      width: "160px",
    },

    {
      title: (
        <PostmatchTableColumnsHeader
          text={TableDataKeyToNameMap[TableDataKeyEnum.opportunityLink]}
          className={styles.header}
        />
      ),
      dataIndex: TableDataKeyEnum.opportunityLink,
      className: styles.column,
      render: PostmatchTableRenderSalesforce(),
      width: "130px",
    },

    {
      title: (
        <PostmatchTableColumnsHeader
          text={TableDataKeyToNameMap[TableDataKeyEnum.developerRate]}
          className={styles.headerSort}
        />
      ),
      dataIndex: TableDataKeyEnum.developerRate,
      className: styles.column,
      sorter: (a: any, b: any) =>
        sortValuesForTable(a, b, TableSort[TableDataKeyEnum.developerRate]),
      render: PostmatchTableRender(),
      width: "150px",
    },

    {
      title: (
        <PostmatchTableColumnsHeader
          text={
            TableDataKeyToNameMap[
              isSelfServe
                ? TableDataKeyEnum.customerRate
                : TableDataKeyEnum.customerRateAlternative
            ]
          }
          className={styles.headerSort}
        />
      ),
      dataIndex: TableDataKeyEnum.customerRate,
      className: styles.column,
      sorter: (a: any, b: any) =>
        sortValuesForTable(a, b, TableSort[TableDataKeyEnum.customerRate]),
      render: PostmatchTableRender(),
      width: isSelfServe ? "120px" : "150px",
    },

    {
      title: (
        <PostmatchTableColumnsHeader
          text={TableDataKeyToNameMap[TableDataKeyEnum.rateMargin]}
          className={styles.headerSort}
        />
      ),
      dataIndex: TableDataKeyEnum.rateMargin,
      className: styles.column,
      sorter: (a: any, b: any) =>
        sortValuesForTable(a, b, TableSort[TableDataKeyEnum.rateMargin]),
      render: PostmatchTableRender(),
      width: "120px",
    },

    {
      title: (
        <PostmatchTableColumnsHeader
          text={TableDataKeyToNameMap[TableDataKeyEnum.billedHours]}
          className={styles.headerSort}
        />
      ),
      dataIndex: TableDataKeyEnum.billedHours,
      className: styles.column,
      sorter: (a: any, b: any) =>
        sortValuesForTable(a, b, TableSort[TableDataKeyEnum.billedHours]),
      render: PostmatchTableRender(),
      width: isSelfServe ? "120px" : "140px",
    },

    {
      title: (
        <PostmatchTableColumnsHeader
          text={TableDataKeyToNameMap[TableDataKeyEnum.startDate]}
          className={styles.headerSort}
        />
      ),
      dataIndex: TableDataKeyEnum.startDate,
      className: styles.column,
      sorter: (a: any, b: any) =>
        sortValuesForTable(a, b, TableSort[TableDataKeyEnum.startDate]),
      render: PostmatchTableRender(),
      width: isSelfServe ? "100px" : "120px",
    },

    {
      title: (
        <PostmatchTableColumnsHeader
          text={TableDataKeyToNameMap[TableDataKeyEnum.endDate]}
          className={styles.headerSort}
        />
      ),
      dataIndex: TableDataKeyEnum.endDate,
      className: styles.column,
      sorter: (a: any, b: any) =>
        sortValuesForTable(a, b, TableSort[TableDataKeyEnum.endDate]),
      render: PostmatchTableRender(),
      width: isSelfServe ? "100px" : "120px",
    },

    {
      title: (
        <PostmatchTableColumnsHeader
          text={TableDataKeyToNameMap[TableDataKeyEnum.duration]}
          className={styles.headerSort}
        />
      ),
      dataIndex: TableDataKeyEnum.duration,
      className: styles.column,
      sorter: (a: any, b: any) =>
        sortValuesForTable(a, b, TableSort[TableDataKeyEnum.duration]),
      render: PostmatchTableRenderDuration(),
      width: isOutOfSpace ? "88px" : "100px",
    },

    {
      title: (
        <PostmatchTableColumnsHeader
          text={TableDataKeyToNameMap[TableDataKeyEnum.internalStatus]}
          className={styles.headerSort}
        />
      ),
      dataIndex: TableDataKeyEnum.internalStatus,
      className: styles.column,
      sorter: (a: any, b: any) =>
        sortValuesForTable(a, b, TableSort[TableDataKeyEnum.internalStatus]),
      filters: filters?.[TableDataKeyEnum.internalStatus],
      onFilter: (selectedValue: string, currentRecord: any) =>
        selectedValue === currentRecord?.[TableDataKeyEnum.internalStatus],
      render: PostmatchTableRender(),
      width: "160px",
    },

    {
      title: (
        <PostmatchTableColumnsHeader
          text={
            TableDataKeyToNameMap[
              isSelfServe
                ? TableDataKeyEnum.externalStatus
                : TableDataKeyEnum.externalStatusAlternative
            ]
          }
          className={styles.headerSort}
        />
      ),
      dataIndex: TableDataKeyEnum.externalStatus,
      className: styles.column,
      sorter: (a: any, b: any) =>
        sortValuesForTable(a, b, TableSort[TableDataKeyEnum.externalStatus]),
      filters: isSelfServe
        ? undefined
        : filters?.[TableDataKeyEnum.externalStatus],
      onFilter: isSelfServe
        ? undefined
        : (selectedValue: string, currentRecord: any) =>
            selectedValue === currentRecord?.[TableDataKeyEnum.externalStatus],
      render: PostmatchTableRender(),
      width: isSelfServe ? (isSomePreOnboarding ? "128px" : "106px") : "160px",
    },

    {
      title: (
        <PostmatchTableColumnsHeader
          text={TableDataKeyToNameMap[TableDataKeyEnum.locationText]}
          className={classnames(styles.header, {
            [styles.headerSort]: !isSelfServe,
          })}
        />
      ),
      dataIndex: TableDataKeyEnum.locationText,
      className: styles.column,
      sorter: isSelfServe
        ? undefined
        : (a: any, b: any) =>
            sortValuesForTable(a, b, TableSort[TableDataKeyEnum.locationText]),
      filters: filters?.[TableDataKeyEnum.locationText],
      onFilter: (selectedValue: string, currentRecord: any) =>
        selectedValue === currentRecord?.[TableDataKeyEnum.locationText],
      render: PostmatchTableRender(),
      width: isSelfServe ? "90px" : "130px",
    },

    {
      title: (
        <PostmatchTableColumnsHeader
          text={TableDataKeyToNameMap[TableDataKeyEnum.salesRepEmail]}
          className={styles.headerSort}
        />
      ),
      dataIndex: TableDataKeyEnum.salesRepEmail,
      className: styles.column,
      sorter: (a: any, b: any) =>
        sortValuesForTable(a, b, TableSort[TableDataKeyEnum.salesRepEmail]),
      filters: filters?.[TableDataKeyEnum.salesRepEmail],
      onFilter: (selectedValue: string, currentRecord: any) =>
        selectedValue === currentRecord?.[TableDataKeyEnum.salesRepEmail],
      render: PostmatchTableRender(),
      width: "220px",
    },

    {
      title: (
        <PostmatchTableColumnsHeader
          text={TableDataKeyToNameMap[TableDataKeyEnum.personalEmail]}
          className={styles.headerSort}
        />
      ),
      dataIndex: TableDataKeyEnum.personalEmail,
      className: styles.column,
      sorter: (a: any, b: any) =>
        sortValuesForTable(a, b, TableSort[TableDataKeyEnum.personalEmail]),
      render: PostmatchTableRender(),
      width: "220px",
    },

    {
      title: (
        <PostmatchTableColumnsHeader
          text={TableDataKeyToNameMap[TableDataKeyEnum.turingEmail]}
          className={styles.headerSort}
        />
      ),
      dataIndex: TableDataKeyEnum.turingEmail,
      className: styles.column,
      sorter: (a: any, b: any) =>
        sortValuesForTable(a, b, TableSort[TableDataKeyEnum.turingEmail]),
      render: PostmatchTableRender(),
      width: "220px",
    },

    {
      title: (
        <PostmatchTableColumnsHeader
          text={TableDataKeyToNameMap[TableDataKeyEnum.developerRecord]}
          className={styles.headerSort}
        />
      ),
      dataIndex: TableDataKeyEnum.developerRecord,
      className: styles.column,
      sorter: (a: any, b: any) =>
        sortValuesForTable(a, b, TableSort[TableDataKeyEnum.developerName]),
      render: PostmatchTableRenderDeveloper(
        onClickDeveloperOpenDDP,
        disableDDP,
      ),
      width: "140px",
    },

    {
      title: (
        <PostmatchTableColumnsHeader
          text={TableDataKeyToNameMap[TableDataKeyEnum.customerContactEmail]}
          className={classnames(styles.header, {
            [styles.headerSort]: !isSelfServe,
          })}
        />
      ),
      dataIndex: TableDataKeyEnum.customerContactEmail,
      className: styles.columnBreakWord,
      sorter: isSelfServe
        ? undefined
        : (a: any, b: any) =>
            sortValuesForTable(
              a,
              b,
              TableSort[TableDataKeyEnum.customerContactEmail],
            ),
      filters: filters?.[TableDataKeyEnum.customerContactEmail],
      onFilter: (selectedValue: string, currentRecord: any) =>
        selectedValue ===
        currentRecord?.[TableDataKeyEnum.customerContactEmail],
      render: PostmatchTableRender(),
      width: contactsWidth.customerContact,
    },

    {
      title: (
        <PostmatchTableColumnsHeader
          text={TableDataKeyToNameMap[TableDataKeyEnum.accountExecutiveEmail]}
          className={classnames(styles.header, {
            [styles.headerSort]: !isSelfServe,
          })}
        />
      ),
      dataIndex: TableDataKeyEnum.accountExecutiveEmail,
      className: styles.columnBreakWord,
      sorter: isSelfServe
        ? undefined
        : (a: any, b: any) =>
            sortValuesForTable(
              a,
              b,
              TableSort[TableDataKeyEnum.accountExecutiveEmail],
            ),
      filters: filters?.[TableDataKeyEnum.accountExecutiveEmail],
      onFilter: (selectedValue: string, currentRecord: any) =>
        selectedValue ===
        currentRecord?.[TableDataKeyEnum.accountExecutiveEmail],
      render: PostmatchTableRender(),
      width: contactsWidth.turingContact,
    },

    {
      title: (
        <PostmatchTableColumnsHeader
          text={TableDataKeyToNameMap[TableDataKeyEnum.feedback]}
          className={classnames(styles.header)}
        />
      ),
      dataIndex: TableDataKeyEnum.feedback,
      className: styles.column,
      render: PostmatchTableRenderFeedback(onClickFeedbackTable),
      width: "150px",
    },

    {
      title: (
        <PostmatchTableColumnsHeader
          text={
            TableDataKeyToNameMap[TableDataKeyEnum.opportunityBusinessLineLabel]
          }
          className={classnames(styles.header, {
            [styles.headerSort]: !isSelfServe,
          })}
        />
      ),
      dataIndex: TableDataKeyEnum.opportunityBusinessLineLabel,
      className: styles.column,
      sorter: isSelfServe
        ? undefined
        : (a: any, b: any) =>
            sortValuesForTable(
              a,
              b,
              TableSort[TableDataKeyEnum.opportunityBusinessLine],
            ),
      filters: filters?.[TableDataKeyEnum.opportunityBusinessLineLabel],
      onFilter: (selectedValue: string, currentRecord: any) =>
        selectedValue ===
        currentRecord?.[TableDataKeyEnum.opportunityBusinessLineLabel],
      render: PostmatchTableRender(),
      width: isSelfServe ? "100px" : "140px",
    },

    {
      title: (
        <PostmatchTableColumnsHeader
          text={TableDataKeyToNameMap[TableDataKeyEnum.companyTypeLabel]}
          className={classnames(styles.header, {
            [styles.headerSort]: !isSelfServe,
          })}
        />
      ),
      dataIndex: TableDataKeyEnum.companyTypeLabel,
      className: styles.column,
      sorter: isSelfServe
        ? undefined
        : (a: any, b: any) =>
            sortValuesForTable(a, b, TableSort[TableDataKeyEnum.companyType]),
      filters: filters?.[TableDataKeyEnum.companyTypeLabel],
      onFilter: (selectedValue: string, currentRecord: any) =>
        selectedValue === currentRecord?.[TableDataKeyEnum.companyTypeLabel],
      render: PostmatchTableRender(),
      width: isSelfServe ? "110px" : "150px",
    },

    {
      title: (
        <PostmatchTableColumnsHeader
          text={TableDataKeyToNameMap[TableDataKeyEnum.billingRateTypeLabel]}
          className={classnames(styles.header, {
            [styles.headerSort]: !isSelfServe,
          })}
        />
      ),
      dataIndex: TableDataKeyEnum.billingRateTypeLabel,
      className: styles.column,
      sorter: isSelfServe
        ? undefined
        : (a: any, b: any) =>
            sortValuesForTable(
              a,
              b,
              TableSort[TableDataKeyEnum.billingRateType],
            ),
      filters: filters?.[TableDataKeyEnum.billingRateTypeLabel],
      onFilter: (selectedValue: string, currentRecord: any) =>
        selectedValue ===
        currentRecord?.[TableDataKeyEnum.billingRateTypeLabel],
      render: PostmatchTableRender(),
      width: isSelfServe ? "115px" : "155px",
    },

    {
      title: (
        <PostmatchTableColumnsHeader
          text={
            TableDataKeyToNameMap[TableDataKeyEnum.devSuccessSpecialistEmail]
          }
          className={classnames(styles.header, {
            [styles.headerSort]: !isSelfServe,
          })}
        />
      ),
      dataIndex: TableDataKeyEnum.devSuccessSpecialistEmail,
      className: styles.column,
      sorter: isSelfServe
        ? undefined
        : (a: any, b: any) =>
            sortValuesForTable(
              a,
              b,
              TableSort[TableDataKeyEnum.devSuccessSpecialistEmail],
            ),
      filters: filters?.[TableDataKeyEnum.devSuccessSpecialistEmail],
      onFilter: (selectedValue: string, currentRecord: any) =>
        selectedValue ===
        currentRecord?.[TableDataKeyEnum.devSuccessSpecialistEmail],
      render: PostmatchTableRender(),
      width: contactsWidth.turingContact,
    },
  ];
}
