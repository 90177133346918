export const DEVELOPERS_TITLE = "Developers";

export const DEVELOPER_TITLE = "Developer";

export const CUSTOMER_CONTACT_LIMIT_LENGTH = 24;

export const TURING_CONTACT_LIMIT_LENGTH = 24;

export const DEFAULT_PAGE_SIZE = 10;

export const ALTERNATIVE_PAGE_SIZE = 15;
