import React from "react";

export function CheckboxSvg() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 6C2 3.79086 3.79086 2 6 2H18C20.2091 2 22 3.79086 22 6V18C22 20.2091 20.2091 22 18 22H6C3.79086 22 2 20.2091 2 18V6Z"
        fill="#212121"
      />
      <path
        d="M10.1717 16.8334C10.044 16.8334 9.92082 16.8108 9.80207 16.7657C9.68333 16.7205 9.57608 16.65 9.48032 16.5543L6.27013 13.3441C6.08402 13.158 5.99408 12.9244 6.0003 12.6434C6.00652 12.3623 6.10269 12.1287 6.28879 11.9426C6.47489 11.7565 6.70887 11.6635 6.99072 11.6635C7.27258 11.6635 7.50656 11.7565 7.69265 11.9426L10.1717 14.4217L16.3089 8.28451C16.495 8.09841 16.7285 8.0036 17.0096 8.00009C17.2906 7.99657 17.5277 8.09138 17.7209 8.28451C17.907 8.47062 18 8.70595 18 8.99051C18 9.27506 17.907 9.51039 17.7209 9.69649L10.8631 16.5543C10.7673 16.65 10.6601 16.7205 10.5413 16.7657C10.4226 16.8108 10.2994 16.8334 10.1717 16.8334Z"
        fill="white"
      />
    </svg>
  );
}
