import React from "react";
import { getPlaceholderValue } from "../../utils/get-placeholder-value/get-placeholder-value";
import { ReactComponent as TabLink } from "../postmatch-table-render/newtablink.svg";

export function DashboardRenderSalesforceEntity({ link }: { link: string }) {
  if (!link) {
    return <div>{getPlaceholderValue("")}</div>;
  }

  return (
    <a target="_blank" href={link} rel="noreferrer">
      <TabLink />
    </a>
  );
}
