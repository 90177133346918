import { TimesheetsQuery } from "../../types/postmatch.types";

export function getTimesheetsEndpoint(
  startDate: string,
  endDate: string,
  timesheetsQuery: TimesheetsQuery,
  include?: string[],
) {
  const { pageNumber, pageSize, sortBy, sortDir, filterBy, filterValue } =
    timesheetsQuery;
  const includeQuery = Array.isArray(include)
    ? include.map((includeItem) => `&include[]=${includeItem}`).join("")
    : "";
  return `postmatch/v1/time-cards?startDate=${startDate}&endDate=${endDate}${
    pageNumber && pageSize
      ? `&pageNumber=${pageNumber}&pageSize=${pageSize}`
      : ""
  }${sortBy && sortDir ? `&sortBy=${sortBy}&sortDir=${sortDir}` : ""}${
    filterBy && filterValue
      ? `&filterBy=${filterBy}&filterValue=${filterValue}`
      : ""
  }${includeQuery}`;
}
