import React from "react";
import { ButtonDesignSystemV2 } from "../button-design-system-v2/button-design-system-v2";
import { ButtonDesignSystemV2Color } from "../button-design-system-v2/button-design-system-v2.types";
import {
  APPROVAL_STATUS,
  TimesheetsTableDataSource,
} from "../../types/postmatch.types";
import { ThumbsDownSvg } from "../svgs/ThumbsDownSvg";
import { ThumbsUpSvg } from "../svgs/ThumbsUpSvg";
import styles from "./timesheets-table-render-approval-rejection.module.css";
import { OnOpenTimesheetsModal } from "../timesheets-table/timesheets-table.types";

export const TimesheetsTableRenderApprovalRejection = (
  onOpenTimesheetsModal: OnOpenTimesheetsModal,
) =>
  function (_: undefined, record: TimesheetsTableDataSource) {
    const approvalStatus = record?.approvalStatus;
    const timeCardId = record?.timeCardId;

    const onClickApproval = () => {
      onOpenTimesheetsModal(true, timeCardId);
    };

    const onClickRejection = () => {
      onOpenTimesheetsModal(false, timeCardId);
    };

    return approvalStatus !== APPROVAL_STATUS.PENDING_APPROVAL &&
      timeCardId ? null : (
      <div className={styles.root}>
        <ButtonDesignSystemV2
          text="Reject"
          Icon={ThumbsDownSvg}
          onClick={onClickRejection}
          buttonClassName={styles.button}
          color={ButtonDesignSystemV2Color.Secondary}
        />
        <ButtonDesignSystemV2
          text="Approve"
          Icon={ThumbsUpSvg}
          onClick={onClickApproval}
          buttonClassName={styles.button}
        />
      </div>
    );
  };
