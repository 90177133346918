import React from "react";
import { Feedback } from "../../types/postmatch.types";
import { PostmatchTableProps } from "../postmatch-table/postmatch-table.types";
import { DashboardRenderFeedbackEntity } from "../dashboard-render-feedback-entity/dashboard-render-feedback-entity";

export function PostmatchTableRenderFeedback(
  onClickFeedbackTable: PostmatchTableProps["onClickFeedbackTable"],
) {
  return function (feedback: Feedback) {
    return (
      <DashboardRenderFeedbackEntity
        feedback={feedback}
        onClickFeedbackTable={onClickFeedbackTable}
      />
    );
  };
}
