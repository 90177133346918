import {
  endOfMonth,
  startOfMonth,
  parseISO,
  setMonth,
  setYear,
} from "date-fns";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { utcToZonedTime } from "date-fns-tz";
import { minDate } from "../../utils/datepicker-dates";
import { PostmatchPickerMonthProps } from "./postmatch-picker-month.types";
import {
  ActivePicker,
  PickerDateRange,
} from "../../../../types/postmatch.types";
import {
  getQueryDatesInISO,
  isPickerFromQueryValid,
} from "../../../../utils/query/query";
import { getMonthFromISO, getYearFromISO } from "../../../../utils/dates/dates";
import { ActiveMonthYear } from "../../postmatch-picker.types";

function getInitialPickerDate(
  query: URLSearchParams,
  isQueryLoaded: boolean,
  activeMonthYear: ActiveMonthYear,
) {
  const { startDateFromQueryISO, endDateFromQueryISO } =
    getQueryDatesInISO(query);
  if (
    getMonthFromISO(startDateFromQueryISO) !== activeMonthYear.month ||
    getYearFromISO(startDateFromQueryISO) !== activeMonthYear.year
  ) {
    const initialStartDate = startOfMonth(
      setYear(
        setMonth(new Date(), activeMonthYear.month),
        activeMonthYear.year,
      ),
    );
    const initialEndDate = endOfMonth(
      setYear(
        setMonth(new Date(), activeMonthYear.month),
        activeMonthYear.year,
      ),
    );
    return { initialStartDate, initialEndDate };
  }
  if (
    isQueryLoaded &&
    query.get("pickerMode") === ActivePicker.Month &&
    isPickerFromQueryValid(
      ActivePicker.Month,
      startDateFromQueryISO,
      endDateFromQueryISO,
    )
  ) {
    return {
      initialStartDate: utcToZonedTime(parseISO(startDateFromQueryISO), "UTC"),
      initialEndDate: utcToZonedTime(parseISO(endDateFromQueryISO), "UTC"),
    };
  }
  const initialStartDate = startOfMonth(
    utcToZonedTime(parseISO(startDateFromQueryISO), "UTC"),
  );
  const initialEndDate = endOfMonth(
    utcToZonedTime(parseISO(endDateFromQueryISO), "UTC"),
  );
  return { initialStartDate, initialEndDate };
}

export function PostmatchPickerMonth({
  setPopoverDate,
  isQueryLoaded,
  query,
  activeMonthYear,
  setActiveMonthYear,
}: PostmatchPickerMonthProps) {
  const { initialStartDate, initialEndDate } = getInitialPickerDate(
    query,
    isQueryLoaded,
    activeMonthYear,
  );

  const [pickerDateRange, setPickerDateRange] = useState<PickerDateRange>({
    start: initialStartDate,
    end: initialEndDate,
  });

  useEffect(() => {
    const startDateUTC = moment(initialStartDate).utc(true).toISOString();
    const endDateUTC = moment(initialEndDate).utc(true).toISOString();
    setPopoverDate({ start: startDateUTC, end: endDateUTC });
    setPickerDateRange({ start: initialStartDate, end: initialEndDate });
  }, [isQueryLoaded]);

  const onMonthChange = (date: Date) => {
    const startDate = startOfMonth(date);
    const endDate = endOfMonth(date);
    setPickerDateRange({ start: startDate, end: endDate });
    const startDateUTC = moment(startDate).utc(true).toISOString();
    const endDateUTC = moment(endDate).utc(true).toISOString();
    setPopoverDate({ start: startDateUTC, end: endDateUTC });
    setActiveMonthYear({ month: date.getMonth(), year: date.getFullYear() });
  };

  return (
    <DatePicker
      selected={pickerDateRange.start}
      onChange={() => {}}
      startDate={pickerDateRange.start}
      endDate={pickerDateRange.end}
      selectsRange
      inline
      onMonthChange={onMonthChange}
      minDate={minDate}
    />
  );
}
