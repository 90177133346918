export function getTimesheetModalFields(isApproval: boolean) {
  return isApproval
    ? {
        title: "APPROVE TIMESHEET",
        subtitle: "Would you like to add any details?",
        placeholder: "Eg. I approved extra hours this week",
      }
    : {
        title: "REJECT TIMESHEET",
        subtitle: "Tell us why you're rejecting this timesheet",
        placeholder: "Eg. Their are extra hours on the timesheet",
      };
}
