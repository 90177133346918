import { APPROVAL_STATUS } from "../../types/postmatch.types";
import { capitalizeFirstLetter } from "../text/text";

const WAITING_FOR_APPROVAL = "Waiting for approval";

export function convertDisplayableStatusToEnum(status: string) {
  if (typeof status !== "string") {
    return "";
  }
  if (status === WAITING_FOR_APPROVAL) {
    return APPROVAL_STATUS.PENDING_APPROVAL;
  }
  return status.replace(/\s/g, "_").toUpperCase();
}

export function convertStatusToDisplayableText(status: APPROVAL_STATUS) {
  if (!Object.values(APPROVAL_STATUS).includes(status)) {
    return "";
  }
  if (status === APPROVAL_STATUS.PENDING_APPROVAL) {
    return WAITING_FOR_APPROVAL;
  }
  return capitalizeFirstLetter(status.toLowerCase().replace(/_/g, " "));
}
