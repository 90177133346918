import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  ColumnOrderState,
  flexRender,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  SortingState,
  getSortedRowModel,
} from "@tanstack/react-table";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import classNames from "classnames";
import { notUndefined, useVirtualizer } from "@tanstack/react-virtual";
import { isArrayNotEmpty } from "../../utils/helpers/helpers";
import {
  TableDataKeyEnum,
  TableDataKeyToNameMap,
} from "../postmatch-table-columns/postmatch-table-columns.constants";
import styles from "./dashboard.module.css";
import { ButtonDesignSystemV2 } from "../button-design-system-v2/button-design-system-v2";
import { ModalDesignSystemV2 } from "../modal-design-system-v2/modal-design-system-v2";
import { ButtonDesignSystemV2Color } from "../button-design-system-v2/button-design-system-v2.types";
import { Spinner } from "../spinner/spinner";
import { Checkbox } from "../checkbox/checkbox";
import { ColumnOrder, DashboardProps, Filter, Sort } from "./dashboard.types";
import {
  getColumnOrderToPreload,
  getColumnVisibilityToPreload,
} from "./dashboard.utils";
import { DashboardHeaderItem } from "../dashboard-header-item/dashboard-header-item";
import {
  getDashboardAccessorColumns,
  getDashboardDefaultColumns,
} from "../dashboard-columns/dashboard-columns";
import { CELL_HEIGHT, CELL_WIDTH } from "./dashboard.constants";

export function Dashboard({
  developers,
  postmatchActivityType,
  logActivity,
  onClickFeedbackTable,
  saveEngagementDashboardPreferences,
  engagementDashboardPreferences,
  isFetchingPreferences,
  isLoading,
  columnsToRemove = new Set(),
  openRateHistoryModal,
  gbFlags = {},
}: DashboardProps) {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [resetCount, setResetCount] = useState(0);
  const [isCheckedSelectAll, setIsCheckedSelectAll] = useState(false);

  const [columnFilters, setColumnFilters] = useState<Filter[]>([]);
  const [sorting, setSorting] = useState<Sort[]>([]);

  const dashboardColumns: any = useMemo(
    () =>
      getDashboardAccessorColumns(
        postmatchActivityType,
        logActivity,
        onClickFeedbackTable,
        openRateHistoryModal,
      ),
    [],
  );
  const [columns] = useState(() => [...dashboardColumns]);

  const defaultSelectedColumns = useMemo(
    () =>
      columns.reduce((acc, { id }) => {
        acc[id] = true;
        return acc;
      }, {}),
    [],
  );
  const [selectedColumns, setSelectedColumns] = useState(
    defaultSelectedColumns,
  );
  const [columnVisibility, setColumnVisibility] = useState(
    defaultSelectedColumns,
  );

  const accessorColumnOrder: any = useMemo(
    () => getDashboardAccessorColumns().map(({ id }) => id),
    [],
  );
  const [columnOrder, setColumnOrder] =
    useState<ColumnOrder>(accessorColumnOrder);

  const isCheckedSelectAllManually = useMemo(
    () => Object.values(selectedColumns).every((value) => value),
    [selectedColumns],
  );

  const isCheckedPartialForSelectAll = useMemo(
    () => Object.values(selectedColumns).some((value) => value),
    [selectedColumns],
  );

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const table = useReactTable({
    data: developers,
    columns,
    state: {
      columnVisibility,
      columnOrder,
      columnFilters,
      sorting,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder as React.Dispatch<
      React.SetStateAction<ColumnOrderState>
    >,
    onColumnFiltersChange: setColumnFilters as React.Dispatch<
      React.SetStateAction<ColumnFiltersState>
    >,
    onSortingChange: setSorting as React.Dispatch<
      React.SetStateAction<SortingState>
    >,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  const numDevelopers = table.getFilteredRowModel().rows.length;

  const { rows } = table.getRowModel();

  const virtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: () => CELL_HEIGHT,
    overscan: 20,
  });

  const items = virtualizer.getVirtualItems();
  const [before, after] =
    items.length > 0
      ? [
          notUndefined(items[0]).start - virtualizer.options.scrollMargin,
          virtualizer.getTotalSize() -
            notUndefined(items[items.length - 1]).end,
        ]
      : [0, 0];

  useEffect(() => {
    if (!isFetchingPreferences) {
      const {
        columnOrder: savedColumnOrder,
        columnVisibility: savedColumnVisibility,
        sort: savedSort,
        filter: savedFilter,
      } = engagementDashboardPreferences;
      if (isArrayNotEmpty(savedColumnOrder)) {
        setColumnOrder(
          getColumnOrderToPreload(savedColumnOrder, defaultSelectedColumns),
        );
      }
      if (Object.keys(savedColumnVisibility || {}).length) {
        const columnVisibilityToPreload = getColumnVisibilityToPreload(
          savedColumnVisibility,
          defaultSelectedColumns,
        );
        setColumnVisibility(columnVisibilityToPreload);
        setSelectedColumns(columnVisibilityToPreload);
      }
      if (isArrayNotEmpty(savedSort)) {
        setSorting(savedSort);
      }
      if (isArrayNotEmpty(savedFilter)) {
        setColumnFilters(savedFilter);
      }
    }
  }, [isFetchingPreferences]);

  const onOpenEditModal = () => {
    setIsEditModalOpen(true);
    setSelectedColumns(columnVisibility);
  };

  const onCloseEditModal = () => {
    setIsEditModalOpen(false);
  };

  const onChangeSelectColumn = (columnId: string) => {
    const newSelectedColumns = { ...selectedColumns };
    newSelectedColumns[columnId] = !selectedColumns[columnId];
    setSelectedColumns(newSelectedColumns);
    setIsCheckedSelectAll(
      Object.values(newSelectedColumns).every((item) => item),
    );
  };

  const onChangeSelectAllColumns = () => {
    const newIsCheckedSelectAll = isCheckedSelectAllManually
      ? false
      : !isCheckedSelectAll;
    const newSelectedColumns = Object.keys(selectedColumns).reduce(
      (acc: any, key) => {
        acc[key] = newIsCheckedSelectAll;
        return acc;
      },
      {},
    );
    setSelectedColumns(newSelectedColumns);
    setIsCheckedSelectAll(newIsCheckedSelectAll);
  };

  const onSaveSelectedColumns = () => {
    const newSorting = sorting.filter(
      (sortItem) => selectedColumns[sortItem?.id],
    );
    const newFilter = columnFilters.filter(
      (filterItem) => selectedColumns[filterItem?.id],
    );
    setColumnVisibility(selectedColumns);
    setIsEditModalOpen(false);
    setSorting(newSorting);
    setColumnFilters(newFilter);
    saveEngagementDashboardPreferences({
      columnOrder,
      columnVisibility: selectedColumns,
      sort: newSorting,
      filter: newFilter,
    });
  };

  const onResetPreferences = () => {
    const defaultColumnOrder = getDashboardDefaultColumns().map(({ id }) => id);
    setColumnOrder(defaultColumnOrder);
    setColumnVisibility(defaultSelectedColumns);
    setSorting([]);
    setColumnFilters([]);
    saveEngagementDashboardPreferences({
      columnOrder: defaultColumnOrder,
      columnVisibility: defaultSelectedColumns,
      sort: [],
      filter: [],
    });
    setIsEditModalOpen(false);
    setResetCount(resetCount + 1);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={styles.dashboard}>
        <div
          className={classNames(styles.editContainer, {
            [styles.editContainerLoading]: isLoading,
          })}
        >
          <div className={styles.contractors}>
            Contractors {numDevelopers ? `(${numDevelopers})` : null}
          </div>
          <div
            onClick={onOpenEditModal}
            className={classNames(styles.editText, {
              [styles.editTextLoading]: isLoading,
            })}
          >
            Edit dashboard
          </div>
        </div>

        {isLoading && (
          <div className={styles.spinner}>
            <Spinner />
          </div>
        )}

        <div
          ref={tableContainerRef}
          className={classNames(styles.container, {
            [styles.containerLoading]: isLoading,
          })}
        >
          <div style={{ height: `${virtualizer.getTotalSize()}px` }}>
            <table>
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return !columnsToRemove.has(header.column.id) ? (
                        <DashboardHeaderItem
                          key={header.id}
                          header={header}
                          table={table}
                          saveEngagementDashboardPreferences={
                            saveEngagementDashboardPreferences
                          }
                          isFetchingPreferences={isFetchingPreferences}
                          engagementDashboardPreferences={
                            engagementDashboardPreferences
                          }
                          resetCount={resetCount}
                          logActivity={logActivity}
                        />
                      ) : null;
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {before > 0 && (
                  <tr>
                    <td style={{ height: before }} />
                  </tr>
                )}
                {items.map((virtualRow) => {
                  const row = rows[virtualRow.index];
                  let counter = -1;
                  return (
                    <tr
                      key={row.id}
                      style={{
                        height: `${virtualRow.size}px`,
                      }}
                    >
                      {row.getVisibleCells().map((cell) => {
                        const isColumnEnabled = !columnsToRemove.has(
                          cell.column.id,
                        );
                        if (isColumnEnabled) {
                          counter += 1;
                        }
                        const style = gbFlags.disableDndAnimation
                          ? {}
                          : ({
                              position: "absolute",
                              left: CELL_WIDTH * counter,
                            } as React.CSSProperties);
                        return isColumnEnabled ? (
                          <td key={cell.id} style={style}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </td>
                        ) : null;
                      })}
                    </tr>
                  );
                })}
                {after > 0 && (
                  <tr>
                    <td style={{ height: after }} />
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <ModalDesignSystemV2
          isOpen={isEditModalOpen}
          onClose={onCloseEditModal}
        >
          <div className={styles.title}>EDIT DASHBOARD</div>
          <div className={styles.subtitle}>
            Choose which content you&apos;d like to view
          </div>
          <div className={styles.pivotContainer}>
            <div className={styles.pivotItem}>
              <Checkbox
                text="(Select all)"
                isChecked={isCheckedSelectAllManually}
                onClick={onChangeSelectAllColumns}
                isCheckedPartialForSelectAll={isCheckedPartialForSelectAll}
              />
            </div>
            {table.getAllLeafColumns().map((column) => {
              const columnId = column.id;
              const columnTitle =
                TableDataKeyToNameMap[
                  TableDataKeyEnum[columnId as keyof typeof TableDataKeyEnum]
                ];
              return !columnsToRemove.has(columnId) ? (
                <div key={columnId} className={styles.pivotItem}>
                  <Checkbox
                    text={columnTitle}
                    isChecked={selectedColumns[columnId]}
                    onClick={() => onChangeSelectColumn(columnId)}
                  />
                </div>
              ) : null;
            })}
          </div>
          <div className={styles.buttonsContainer}>
            <ButtonDesignSystemV2
              text="Reset dashboard"
              onClick={onResetPreferences}
              color={ButtonDesignSystemV2Color.Tertiary}
            />
            <ButtonDesignSystemV2 text="Save" onClick={onSaveSelectedColumns} />
          </div>
        </ModalDesignSystemV2>
      </div>
    </DndProvider>
  );
}
