import React from "react";
import { PostmatchTableProps } from "../postmatch-table/postmatch-table.types";
import { DashboardRenderDeveloperLinkEntity } from "../dashboard-render-developer-link-entity/dashboard-render-developer-link-entity";

export function DashboardRenderDeveloperLink(
  activityKey: string,
  activityAction: string,
  logActivity: PostmatchTableProps["logActivity"],
) {
  return function (info: any) {
    const value = info?.row?.original?.developerRecordWithLink || {};
    return (
      <DashboardRenderDeveloperLinkEntity
        value={value}
        activityKey={activityKey}
        activityAction={activityAction}
        logActivity={logActivity}
        isTruncationEnabled
      />
    );
  };
}
