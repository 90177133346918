import React, { useEffect, useMemo, useState } from "react";
import { Table, TablePaginationConfig } from "antd";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import classNames from "classnames";
import styles from "./timesheets-table.module.css";
import { DownSvg } from "../svgs/DownSvg";
import { getTimesheetsTableColumns } from "../timesheets-table-columns/timesheets-table-columns";
import { TimesheetsTableProps } from "./timesheets-table.types";
import {
  getLocale,
  rearrangeColumns,
} from "../postmatch-table/postmatch-table.utils";
import { TimesheetsTableDataSource } from "../../utils";
import {
  computePaginationLimits,
  getPaginationInfo,
  getTableFilters,
} from "./timesheets-table.utils";
import { filterObjectOfArrays, flatArray } from "../../utils/helpers/helpers";
import { DEFAULT_TIMESHEETS_PAGE_SIZE } from "./timesheets-table.constants";

export function TimesheetsTable({
  onClickDeveloperOpenDDP = () => {},
  isLoading,
  timesheets,
  onOpenTimesheetsModal = () => {},
  onGetTimesheets,
  updateTimesheetQuery,
  updatePagination,
  pagination,
  total,
  disableDDP = false,
  timesheetsQuery,
  visibleColumns,
  onOpenRejectionsModal = () => {},
  customClassNames = {},
  disableApprovalWorkflow,
}: TimesheetsTableProps) {
  const [paginationInfo, setPaginationInfo] = useState(() =>
    getPaginationInfo(1, DEFAULT_TIMESHEETS_PAGE_SIZE, total),
  );
  const [activeTimesheets, setActiveTimesheets] = useState(timesheets);
  const locale = getLocale(isLoading);

  useEffect(() => {
    setActiveTimesheets(timesheets);
  }, [timesheets]);

  useEffect(() => {
    const { pageStart, limitedPageEnd } = computePaginationLimits(
      timesheetsQuery.pageNumber || 1,
      timesheetsQuery.pageSize || DEFAULT_TIMESHEETS_PAGE_SIZE,
      total,
    );
    setPaginationInfo(getPaginationInfo(pageStart, limitedPageEnd, total));
  }, [timesheets, timesheetsQuery]);

  const tableFilters = useMemo(() => getTableFilters(timesheets), [timesheets]);

  const onChangePagination = (currentPage: number, pageSize: number) => {
    const { pageStart, limitedPageEnd } = computePaginationLimits(
      currentPage,
      pageSize,
      total,
    );
    setPaginationInfo(getPaginationInfo(pageStart, limitedPageEnd, total));
    updatePagination(currentPage);
  };

  const onChangeTable = (
    currentPagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter:
      | SorterResult<TimesheetsTableDataSource>
      | SorterResult<TimesheetsTableDataSource>[],
  ) => {
    const pageNumber = currentPagination?.current;
    const pageSize = currentPagination?.pageSize;

    const singleSorter = sorter as SorterResult<TimesheetsTableDataSource>;
    const sortBy = singleSorter.field as string;
    let sortDir = singleSorter.order as string;
    if (sortDir === "descend") {
      sortDir = "desc";
    } else if (sortDir === "ascend") {
      sortDir = "asc";
    }

    const validFilters = filterObjectOfArrays(
      (filters || {}) as Record<string, FilterValue | null>,
    ) as Record<string, any>;
    const filterByAll = Object.keys(validFilters);
    const filterBy = filterByAll.join(",");
    const filterValueAll: string[] =
      Object.values(validFilters).length > 0
        ? flatArray(Object.values(validFilters))
        : [];
    const filterValue = filterValueAll.join(",");

    const query = {
      pageNumber,
      pageSize,
      sortBy,
      sortDir,
      filterBy,
      filterValue,
    };
    updateTimesheetQuery(query);
    onGetTimesheets(query);
  };

  const timesheetsColumns = useMemo(
    () =>
      getTimesheetsTableColumns(
        tableFilters,
        onClickDeveloperOpenDDP,
        onOpenTimesheetsModal,
        disableDDP,
        onOpenRejectionsModal,
        disableApprovalWorkflow,
      ),
    [
      onOpenTimesheetsModal,
      onClickDeveloperOpenDDP,
      tableFilters,
      disableDDP,
      disableApprovalWorkflow,
    ],
  );

  const rearrangedColumns = useMemo(
    () => rearrangeColumns(visibleColumns, timesheetsColumns),
    [visibleColumns, timesheetsColumns],
  );

  const isPaginationEnabled = total > DEFAULT_TIMESHEETS_PAGE_SIZE;
  const isPaginationExpanded = paginationInfo.split("-")[0]?.length > 1;

  return (
    <div
      className={classNames(styles.root, {
        [styles.rootDisablePagination]: !isPaginationEnabled,
        [styles.rootPaginationExpanded]: isPaginationExpanded,
      })}
    >
      <Table
        dataSource={activeTimesheets}
        columns={rearrangedColumns}
        rowKey={(record) => record.timeCardId}
        rowClassName={styles.row}
        pagination={{
          position: ["bottomLeft"],
          showSizeChanger: true,
          locale: { items_per_page: "per page" },
          onChange: onChangePagination,
          current: pagination,
          defaultPageSize: DEFAULT_TIMESHEETS_PAGE_SIZE,
          pageSizeOptions: [DEFAULT_TIMESHEETS_PAGE_SIZE, 30, 50, 100],
          total,
        }}
        scroll={{ x: "max-content" }}
        loading={isLoading}
        locale={locale}
        onChange={onChangeTable}
      />
      {/* It's not possible to customize antd's existing pagesizer suffix icon. */}
      {/* Solution is to manually replace with the expected one. */}
      {isPaginationEnabled && (
        <div className={styles.paginationContainer}>
          <div
            className={classNames(
              styles.paginationIcon,
              styles.customPaginationIcon,
              { [styles.paginationIconLoading]: isLoading },
              { [styles.paginationIconExpanded]: isPaginationExpanded },
              customClassNames.paginationIcon,
            )}
          >
            <DownSvg />
          </div>
          <div
            className={classNames(
              styles.paginationInfo,
              customClassNames.paginationInfo,
              {
                [styles.paginationInfoLoading]: isLoading,
              },
            )}
          >
            {paginationInfo}
          </div>
        </div>
      )}
    </div>
  );
}
