import React from "react";
import styles from "./time-card-summary.module.css";
import { TimeCardSummaryProps } from "./time-card-summary-types";

import { TimeCardBodyComponent } from "./time-card-body";
import { TimeCardActionContainer } from "./time-card-action-container";
import { TimeCardNotificationsComponent } from "./time-card-notifications";

export function TimeCardSummaryComponent({
  data,
  isLoading,
  onTimeCardApprove,
  onTimeCardComment,
  onTimeCardReject,
  approval,
  approveActionProgress,
  commentActionProgress,
  rejectActionProgress,
  resetCommentTrigger,
  setCommenTrigger,
  disableApprovalWorkflow,
}: TimeCardSummaryProps) {
  const className = "time-card";

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <div className={[styles["left-container"], className].join(" ")}>
          <div className={styles["time-card-header"]}>
            <h2 className={styles["time-card-header-heading"]}>
              {data?.intervalString}
            </h2>
          </div>
          <TimeCardBodyComponent
            timeCardStatus={
              approval?.approvalStatus ? approval?.approvalStatus : ""
            }
            totalMinutes={data?.totalMinutes}
            developerImage={data?.developerImage ? data?.developerImage : ""}
            developerName={data?.developerName}
            timeEntries={data?.timeEntries}
          />
          {!disableApprovalWorkflow ? (
            <TimeCardActionContainer
              isLoading={isLoading}
              onTimeCardApprove={onTimeCardApprove}
              onTimeCardComment={onTimeCardComment}
              onTimeCardReject={onTimeCardReject}
              timeCardStatus={
                approval?.approvalStatus ? approval?.approvalStatus : ""
              }
              approveActionProgress={approveActionProgress}
              rejectActionProgress={rejectActionProgress}
              commentActionProgress={commentActionProgress}
              resetCommentTrigger={resetCommentTrigger}
              setCommenTrigger={setCommenTrigger}
            />
          ) : null}
        </div>
      </div>
      <div className={styles.right}>
        <TimeCardNotificationsComponent
          approvals={data?.approvals}
          activityLogs={data?.activityLogs}
          disableApprovalWorkflow={disableApprovalWorkflow}
        />
      </div>
    </div>
  );
}
