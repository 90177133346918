import {
  PLACEHOLDER,
  isValueValid,
} from "../get-placeholder-value/get-placeholder-value";

function getFullSuffix(value: number, initialSuffix: string) {
  return value === 1 ? ` ${initialSuffix}` : ` ${initialSuffix}s`;
}

function getDisplayedHoursMinutes(
  hours: number,
  minutes: number,
  isShortSuffix: boolean,
) {
  const hoursSuffix = isShortSuffix ? "h" : getFullSuffix(hours, "hour");
  const minutesSuffix = isShortSuffix ? "m" : getFullSuffix(minutes, "min");
  const displayedHours = `${hours}${hoursSuffix}`;
  const displayedMinutes = minutes !== 0 ? ` ${minutes}${minutesSuffix}` : "";
  return `${displayedHours}${displayedMinutes}`;
}

function convertFractionalHoursToMinutes(fractionalHours: number) {
  const minutes = fractionalHours * 60;
  return parseInt(minutes.toString(), 10);
}

function getDisplayedDaysHours(days: number, hours: number) {
  const daysSuffix = getFullSuffix(days, "day");
  const hoursSuffix = getFullSuffix(hours, "hour");
  const displayedDays = `${days}${daysSuffix}`;
  const displayedHours = hours !== 0 ? ` ${hours}${hoursSuffix}` : "";
  return `${displayedDays}${displayedHours}`;
}

export function convertFractionalHours(
  numOfHours: number | string | null,
  isShortSuffix = false,
) {
  const parsedNumOfHours =
    typeof numOfHours === "string" ? parseFloat(numOfHours) : numOfHours;
  if (parsedNumOfHours === 0) {
    return 0;
  }
  if (!parsedNumOfHours) {
    return PLACEHOLDER;
  }
  const hoursString = parsedNumOfHours.toString(10);
  const hours = parseInt(hoursString, 10);
  const fraction = parseFloat((parsedNumOfHours - hours).toFixed(2));
  const minutes = fraction ? convertFractionalHoursToMinutes(fraction) : 0;
  return getDisplayedHoursMinutes(hours, minutes, isShortSuffix);
}

export function convertFractionalDays(numOfHours: number | string | null) {
  const parsedNumOfHours =
    typeof numOfHours === "string" ? parseFloat(numOfHours) : numOfHours;
  if (parsedNumOfHours === 0) {
    return 0;
  }
  if (!parsedNumOfHours) {
    return PLACEHOLDER;
  }
  const numOfHoursInteger = parseInt(parsedNumOfHours.toString(), 10);
  const numOfDays = parseInt((numOfHoursInteger / 24).toString(), 10);
  const numOfHoursRemaining = numOfHoursInteger % 24;
  return getDisplayedDaysHours(numOfDays, numOfHoursRemaining);
}

export function convertFractionalHoursFixed(
  numOfHours: number | string,
  isSuffixEnabled: boolean,
) {
  if (!isValueValid(numOfHours)) {
    return PLACEHOLDER;
  }
  const parsedNumOfHours =
    typeof numOfHours === "string" ? parseFloat(numOfHours) : numOfHours;
  const roundedNumOfHours = Math.round(parsedNumOfHours);
  if (roundedNumOfHours === 0) {
    return `0`;
  }
  return `${roundedNumOfHours} ${
    isSuffixEnabled ? getFullSuffix(roundedNumOfHours, "hour") : ""
  }`;
}
