export enum TimesheetsKeyEnum {
  "contractor" = "contractor",
  "week" = "week",
  "totalHours" = "totalHours",
  "status" = "status",
  "approveOrReject" = "approveOrReject",
  "contractorLink" = "contractorLink",
  "comments" = "comments",
  "rejectionData" = "rejectionData",
  "jibble" = "jibble",
}

export const TimesheetsKeyToNameMap: Record<
  TimesheetsKeyEnum | string,
  string
> = {
  [TimesheetsKeyEnum.contractor]: "Contractor",
  [TimesheetsKeyEnum.week]: "Week",
  [TimesheetsKeyEnum.totalHours]: "Total hours",
  [TimesheetsKeyEnum.status]: "Status",
  [TimesheetsKeyEnum.approveOrReject]: "",
  [TimesheetsKeyEnum.contractorLink]: "Contractor",
  [TimesheetsKeyEnum.comments]: "Comments",
  [TimesheetsKeyEnum.rejectionData]: "Rejected date",
  [TimesheetsKeyEnum.jibble]: "",
};
