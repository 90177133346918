import React from "react";
import styles from "../timesheets-table/timesheets-table.module.css";
import { TimesheetsTableColumnsHeaderProps } from "./timesheets-table-columns-header.types";

export function TimesheetsTableColumnsHeader({
  text,
  className = styles.header,
}: TimesheetsTableColumnsHeaderProps) {
  return <div className={className}>{text}</div>;
}
