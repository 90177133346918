import React from "react";
import { TimesheetsTableDataSource } from "../../types/postmatch.types";
import { OnClickDeveloperOpenDDP } from "../timesheets-table/timesheets-table.types";
import { TimesheetsTableContractor } from "../timesheets-table-contractor/timesheets-table-contractor";

export function TimesheetsTableRenderDeveloper(
  onClickDeveloperOpenDDP: OnClickDeveloperOpenDDP,
  disableDDP?: boolean,
) {
  return function (contractor: string, record: TimesheetsTableDataSource) {
    const img = record?.avatarUrl;
    const developerId = record?.developerId;
    const developerIdHash = record?.developerIdHash;
    const jobId = record?.jobId;

    const isDeveloperClickable = Boolean(
      contractor && developerId && developerIdHash && jobId && !disableDDP,
    );

    const onClick = () => {
      if (!disableDDP) {
        onClickDeveloperOpenDDP({
          name: contractor,
          developerId,
          developerIdHash,
          jobId,
        });
      }
    };

    return (
      <TimesheetsTableContractor
        contractor={contractor}
        img={img}
        isDeveloperClickable={isDeveloperClickable}
        onClick={onClick}
      />
    );
  };
}
