import React from "react";

export function FilterSvg({ isFiltered }: { isFiltered: boolean }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group">
        <path
          id="Vector"
          d="M2.83345 3.73984C4.38012 5.7265 6.66679 8.6665 6.66679 8.6665V11.9998C6.66679 12.7332 7.26679 13.3332 8.00012 13.3332C8.73345 13.3332 9.33345 12.7332 9.33345 11.9998V8.6665C9.33345 8.6665 11.6201 5.7265 13.1668 3.73984C13.5068 3.29984 13.1935 2.6665 12.6335 2.6665H3.36012C2.80679 2.6665 2.49345 3.29984 2.83345 3.73984Z"
          fill={isFiltered ? "#1890ff" : "#cccccc"}
        />
      </g>
    </svg>
  );
}
