import React from "react";

export function DisLikeSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M2.75643 12.9167C2.36005 12.9167 2.00989 12.7647 1.70595 12.4608C1.402 12.1568 1.25003 11.8067 1.25003 11.4103V10.0642C1.25003 9.97671 1.26125 9.88299 1.28368 9.78302C1.30611 9.68306 1.33121 9.58981 1.35899 9.50327L3.74718 3.86773C3.86659 3.60127 4.06679 3.37616 4.34776 3.19239C4.62875 3.00863 4.92193 2.91675 5.2273 2.91675H13.9904V12.9167L9.14261 17.7228C8.97702 17.8884 8.78525 17.9872 8.5673 18.0192C8.34936 18.0513 8.1405 18.0139 7.94072 17.9071C7.74094 17.8002 7.59458 17.6485 7.50164 17.452C7.40868 17.2554 7.38891 17.0519 7.44234 16.8414L8.33976 12.9167H2.75643ZM12.7404 12.3847V4.16673H5.22436C5.1656 4.16673 5.1055 4.18276 5.04407 4.21481C4.98263 4.24687 4.93588 4.30029 4.90382 4.37508L2.49999 10.0001V11.4103C2.49999 11.4851 2.52403 11.5465 2.57211 11.5946C2.6202 11.6427 2.68163 11.6667 2.75643 11.6667H9.91986L8.87499 16.234L12.7404 12.3847ZM16.4102 2.91675C16.8245 2.91675 17.1791 3.06426 17.4741 3.35927C17.7691 3.65427 17.9166 4.0089 17.9166 4.42317V11.4103C17.9166 11.8246 17.7691 12.1792 17.4741 12.4742C17.1791 12.7692 16.8245 12.9167 16.4102 12.9167H13.9904V11.6667H16.4102C16.485 11.6667 16.5464 11.6427 16.5945 11.5946C16.6426 11.5465 16.6667 11.4851 16.6667 11.4103V4.42317C16.6667 4.34837 16.6426 4.28694 16.5945 4.23885C16.5464 4.19077 16.485 4.16673 16.4102 4.16673H13.9904V2.91675H16.4102Z"
        fill="#212121"
      />
    </svg>
  );
}
