import React from "react";
import {
  Comment,
  TimesheetsTableDataSource,
} from "../../types/postmatch.types";
import { Tooltip } from "antd";
import classNames from "classnames";
import styles from "./timesheets-table-render-comments.module.css";
import { OnOpenRejectionsModal } from "../timesheets-table/timesheets-table.types";

export function TimesheetsTableRenderComments(
  onOpenRejectionsModal: OnOpenRejectionsModal,
) {
  return function (comments: Comment[], record: TimesheetsTableDataSource) {
    const activeTimeCardId = record?.timeCardId;
    const firstComment = comments?.[0]?.comment;
    const commentsLength = comments?.length;
    if (commentsLength === 1) {
      return (
        <Tooltip
          title={firstComment}
          placement="bottom"
          overlayInnerStyle={{ backgroundColor: "black" }}
          overlayStyle={{ maxWidth: "400px" }}
          className={styles.tooltip}
          showArrow={false}
        >
          <span className={styles.text}>{firstComment}</span>
        </Tooltip>
      );
    }
    if (commentsLength > 1) {
      return (
        <div
          className={styles.modal}
          onClick={() => onOpenRejectionsModal(activeTimeCardId)}
        >
          <span className={classNames(styles.text)}>{firstComment}</span>
          <span>({commentsLength})</span>
        </div>
      );
    }
    return null;
  };
}
