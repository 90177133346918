export enum Environment {
  Staging = "staging",
  UAT = "uat",
  Production = "production",
}

export function getEnvironment() {
  if (window?.location?.hostname?.includes(Environment.Staging)) {
    return Environment.Staging;
  }
  if (window?.location?.hostname?.includes(Environment.UAT)) {
    return Environment.UAT;
  }
  return Environment.Production;
}
