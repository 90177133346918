import React from "react";
import { isArrayNotEmpty } from "../../utils/helpers/helpers";
import { PLACEHOLDER } from "../../utils/get-placeholder-value/get-placeholder-value";

export function DashboardRenderDurationEntity({ text }: { text: string }) {
  const words = text?.split(/\s/)?.map((word, index) => ({ word, id: index }));
  if (!isArrayNotEmpty(words)) {
    return <div>{PLACEHOLDER}</div>;
  }
  return (
    <div>
      {words.map(({ word, id }) => {
        return id === 1 || id === 3 ? (
          <React.Fragment key={id}>
            <span>{word}</span> <br />
          </React.Fragment>
        ) : (
          <React.Fragment key={id}>
            <span>{word}</span>{" "}
          </React.Fragment>
        );
      })}
    </div>
  );
}
