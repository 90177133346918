import React, { useRef, useEffect, useState } from "react";
import { getPlaceholderValue } from "../../utils/get-placeholder-value/get-placeholder-value";
import styles from "./truncated-text.module.css";

export function TruncatedText({ text }: { text: string }) {
  const placeholderValue = getPlaceholderValue(text);
  const [tooltipValue, setTooltipValue] = useState<string | undefined>(
    undefined,
  );
  const textRef = useRef<any>(null);
  useEffect(() => {
    const element = textRef?.current;
    if (element.scrollHeight > element.clientHeight) {
      setTooltipValue(text);
    }
  }, []);
  return (
    <div title={tooltipValue} ref={textRef} className={styles.truncate}>
      {placeholderValue}
    </div>
  );
}
