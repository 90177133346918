import React, { useEffect, useMemo, useState } from "react";
import { Table } from "antd";
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
  TablePaginationConfig,
} from "antd/lib/table/interface";
import classNames from "classnames";
import { FormattedDeveloper } from "../../types/formatted-developer.types";
import { getPostmatchTableColumns } from "../postmatch-table-columns/postmatch-table-columns";
import { PostmatchTableProps } from "./postmatch-table.types";
import styles from "./postmatch-table.module.css";
import "antd/dist/antd.css";
import { PaginationOption } from "../../types/postmatch.types";
import {
  getLocale,
  getPaginationOptions,
  getTableFilterFields,
  getTableFilters,
  getTitle,
  rearrangeColumns,
  computeNumOfDevelopers,
  removeColumns,
  removeColumnsFlags,
  isSomeStatusPreOnboarding,
  getContactsWidth,
} from "./postmatch-table.utils";
import { logTableActivity } from "../../utils/activity-logs/activity-logs";
import {
  ALTERNATIVE_PAGE_SIZE,
  DEFAULT_PAGE_SIZE,
} from "./postmatch-table.constants";

export function PostmatchTable({
  developers,
  isLoading,
  visibleColumns,
  postmatchActivityType,
  logActivity,
  onClickDeveloperOpenDDP,
  preTitle = "Contractors",
  paginationOption = PaginationOption.Default,
  pagination,
  onChangePagination,
  enableHoursWorked,
  columnsToRemove = new Set([]),
  isSelfServe = false,
  onClickFeedbackTable,
  disableDDP,
}: PostmatchTableProps) {
  const [numDevelopers, setNumDevelopers] = useState(developers.length);
  const [activeFilters, setActiveFilters] = useState({});

  const locale = getLocale(isLoading);
  const title = getTitle(isLoading, numDevelopers, preTitle);
  const paginationOptions = getPaginationOptions(
    paginationOption,
    pagination,
    onChangePagination,
    numDevelopers,
  );

  const tableFilters = useMemo(() => getTableFilters(developers), [developers]);

  const isSomePreOnboarding = isSomeStatusPreOnboarding(developers);

  const isOutOfSpace = isSelfServe && columnsToRemove.size === 0;

  const contactsWidth = useMemo(
    () => getContactsWidth(developers, isSelfServe),
    [developers, isSelfServe],
  );

  const postmatchTableColumns = useMemo(
    () =>
      getPostmatchTableColumns(
        tableFilters,
        postmatchActivityType,
        logActivity,
        onClickDeveloperOpenDDP,
        isSomePreOnboarding,
        isOutOfSpace,
        contactsWidth,
        isSelfServe,
        onClickFeedbackTable,
        disableDDP,
      ),
    [
      tableFilters,
      postmatchActivityType,
      logActivity,
      isSomePreOnboarding,
      isOutOfSpace,
      contactsWidth,
      isSelfServe,
      onClickFeedbackTable,
      disableDDP,
    ],
  );

  const rearrangedColumns = useMemo(
    () => rearrangeColumns(visibleColumns, postmatchTableColumns),
    [visibleColumns, postmatchTableColumns],
  );

  const filteredColumnsWithFlags = useMemo(
    () => removeColumnsFlags(rearrangedColumns, enableHoursWorked),
    [rearrangedColumns, enableHoursWorked],
  );

  const filteredColumns = useMemo(
    () => removeColumns(filteredColumnsWithFlags, columnsToRemove),
    [filteredColumnsWithFlags, columnsToRemove],
  );

  const tableFilterFields = useMemo(
    () => getTableFilterFields().map((field) => field),
    [],
  );

  useEffect(() => {
    const numDisplayedDevelopers = computeNumOfDevelopers(
      activeFilters,
      developers,
      tableFilterFields,
    );
    setNumDevelopers(numDisplayedDevelopers);
  }, [developers]);

  const onChange = (
    _: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter:
      | SorterResult<FormattedDeveloper>
      | SorterResult<FormattedDeveloper>[],
    extra: TableCurrentDataSource<any>,
  ) => {
    setActiveFilters(filters);
    const numDisplayedDevelopers = computeNumOfDevelopers(
      filters,
      developers,
      tableFilterFields,
    );
    setNumDevelopers(numDisplayedDevelopers);
    logTableActivity(
      logActivity,
      extra?.action,
      sorter as SorterResult<any>,
      filters,
      activeFilters,
    );
  };

  const isPaginationVisible = useMemo(
    () =>
      paginationOption === PaginationOption.Default
        ? developers.length > DEFAULT_PAGE_SIZE
        : developers.length > ALTERNATIVE_PAGE_SIZE,
    [developers, paginationOption],
  );

  return (
    <div
      className={classNames(styles.postmatchTable, {
        [styles.postmatchTableDisablePagination]: !isPaginationVisible,
      })}
    >
      <h2>{title}</h2>
      <Table
        dataSource={developers}
        columns={filteredColumns}
        loading={isLoading}
        rowClassName={styles.row}
        scroll={{ x: "max-content" }}
        rowKey={(record) => record.normalizedId}
        locale={locale}
        pagination={paginationOptions}
        onChange={onChange}
      />
    </div>
  );
}
