import React from "react";
import { getCellClassName } from "../postmatch-table-render/postmatch-table-render.utils";

export function DashboardRenderExternalStatus() {
  return function (info: any) {
    const externalStatus = info?.getValue();
    const cellClassName = getCellClassName(externalStatus);
    return <div className={cellClassName}>{externalStatus}</div>;
  };
}
