import React from "react";
import { Link } from "react-router-dom";
import styles from "./postmatch-table-link-new-tab.module.css";
import { PostmatchTableLinkNewTabProps } from "./postmatch-table-link-new-tab.types";

export function PostmatchTableLinkNewTab({
  link,
  children,
}: PostmatchTableLinkNewTabProps) {
  return (
    <Link
      to={link}
      target="_blank"
      rel="noopener noreferrer"
      className={styles.link}
    >
      {children}
    </Link>
  );
}
