import React from "react";
import { Skeleton } from "antd";
import styles from "./postmatch-company-header.module.css";

export function PostmatchCompanyHeader({ companyInfo, isLoading }: any) {
  const companyName = companyInfo?.companyName;
  const salesRep = companyInfo?.salesRep;
  return (
    <section className={styles.root}>
      {isLoading ? (
        <Skeleton.Button active />
      ) : (
        companyName && <div className={styles.companyName}>{companyName}</div>
      )}
      <div className={styles.contacts}>
        {isLoading ? (
          <Skeleton.Button active />
        ) : (
          salesRep && (
            <div>
              <div className={styles.key}>Turing sales rep</div>
              <div className={styles.value}>{salesRep}</div>
            </div>
          )
        )}
      </div>
    </section>
  );
}
