import React from "react";
import { TimesheetsTableDataSource } from "../../types/postmatch.types";
import { ModalDesignSystemV2 } from "../modal-design-system-v2/modal-design-system-v2";
import styles from "./timesheets-rejections-modal.module.css";

export function TimesheetsRejectionsModal({
  isOpen,
  onClose,
  activeTimesheet,
}: {
  isOpen: boolean;
  onClose: () => void;
  activeTimesheet: TimesheetsTableDataSource;
}) {
  const rejectionDate = activeTimesheet?.rejectionData?.updatedAt;
  return (
    <ModalDesignSystemV2 isOpen={isOpen} onClose={onClose} width={684}>
      <div className={styles.title}>COMMENTS</div>
      <div className={styles.info}>
        <div>
          <span className={styles.key}>Contractor name: </span>
          {activeTimesheet?.contractor}
        </div>
        <div>
          <span className={styles.key}>Timesheet week: </span>
          {activeTimesheet?.week}
        </div>
      </div>
      <div className={styles.comments}>
        {activeTimesheet?.comments?.reverse().map((commentEntry: any) => (
          <div className={styles.comment} key={commentEntry.id}>
            <div className={styles.commentTitle}>
              <span className={styles.key}>Comment: </span>
              {commentEntry?.comment}
            </div>
            <div className={styles.metadata}>{commentEntry?.fullName}</div>
            <div className={styles.metadata}>
              {rejectionDate && rejectionDate === commentEntry?.updatedAt
                ? "Rejected "
                : ""}{" "}
              {commentEntry?.updatedAt}
            </div>
          </div>
        ))}
      </div>
    </ModalDesignSystemV2>
  );
}
