import { FilterValue } from "antd/lib/table/interface";
import { FormattedDeveloper } from "../../types/formatted-developer.types";
import { PaginationOption, ParsedDeveloper } from "../../types/postmatch.types";
import { TableDataKeyEnum, TableSort } from "../../utils";
import { PostmatchTableProps } from "./postmatch-table.types";
import {
  ALTERNATIVE_PAGE_SIZE,
  CUSTOMER_CONTACT_LIMIT_LENGTH,
  DEFAULT_PAGE_SIZE,
  TURING_CONTACT_LIMIT_LENGTH,
} from "./postmatch-table.constants";
import { Widths } from "./widths";
import { sortValuesAscOrderAppendInvalids } from "../postmatch-table-columns/postmatch-table-columns.utils";

function formatTableFilters(
  developers: FormattedDeveloper[],
  field: string,
  isSortingEnabled = false,
) {
  const fieldItemsWithSortValues = developers
    .filter((item: any) => item?.[field])
    .map((item: any) => ({
      displayValue: item?.[field],
      sortValue: item?.[TableSort?.[field]],
    }));
  if (isSortingEnabled) {
    fieldItemsWithSortValues.sort((a, b) =>
      sortValuesAscOrderAppendInvalids(a?.sortValue, b?.sortValue),
    );
  }
  const uniqueItems = new Set([
    ...fieldItemsWithSortValues.map((item) => item?.displayValue),
  ]);
  const formattedItems: any[] = [];
  uniqueItems.forEach((uniqueItem) =>
    formattedItems.push({ text: uniqueItem, value: uniqueItem }),
  );
  return formattedItems;
}

function getLargestWidth(developers: any[], field: TableDataKeyEnum) {
  return developers.reduce((acc, curr) => {
    const currLength = curr?.[field]?.length;
    return currLength > acc ? currLength : acc;
  }, 0);
}

export function rearrangeColumns(visibleColumns: string[], allColumns: any) {
  return visibleColumns.reduce((acc, curr) => {
    const matchedColumn = allColumns.find(
      ({ dataIndex }: any) => curr === dataIndex,
    );
    if (matchedColumn) {
      acc.push(matchedColumn);
    }
    return acc;
  }, [] as any);
}

export function getPaginationOptions(
  paginationOption: PaginationOption,
  pagination: number,
  onChange: PostmatchTableProps["onChangePagination"],
  total: number,
) {
  return paginationOption === PaginationOption.Default
    ? {
        defaultPageSize: DEFAULT_PAGE_SIZE,
        showSizeChanger: true,
        pageSizeOptions: [
          DEFAULT_PAGE_SIZE,
          50,
          100,
          500,
          ...(total > 500 ? [total] : []),
        ],
        current: pagination,
        onChange,
      }
    : {
        defaultPageSize: ALTERNATIVE_PAGE_SIZE,
        current: pagination,
        onChange,
      };
}

export function getLocale(isLoading: boolean) {
  return isLoading
    ? {
        emptyText: " ",
      }
    : undefined;
}

export function getTableFilterFields() {
  return [
    TableDataKeyEnum.internalStatus,
    TableDataKeyEnum.externalStatus,
    TableDataKeyEnum.locationText,
    TableDataKeyEnum.accountExecutiveEmail,
    TableDataKeyEnum.customerContactEmail,
    TableDataKeyEnum.salesRepEmail,
    TableDataKeyEnum.opportunityBusinessLineLabel,
    TableDataKeyEnum.companyTypeLabel,
    TableDataKeyEnum.billingRateTypeLabel,
    TableDataKeyEnum.devSuccessSpecialistEmail,
  ];
}

export function getTableFilters(developers: FormattedDeveloper[]) {
  return getTableFilterFields().reduce((acc, curr) => {
    acc[curr] = formatTableFilters(developers, curr, true);
    return acc;
  }, {} as any);
}

export function getTitle(
  isLoading: boolean,
  numDevelopers: number,
  preTitle: string,
) {
  return isLoading || !numDevelopers
    ? preTitle
    : `${preTitle} (${numDevelopers})`;
}

export function computeNumOfDevelopers(
  filters: Record<string, FilterValue | null>,
  developers: FormattedDeveloper[],
  tableFilterFields: string[],
) {
  const isSomeFilterSelected = Object.values(filters).some(
    (filter) => filter?.length,
  );
  if (!isSomeFilterSelected) {
    return developers.length;
  }
  return developers.reduce((total, developer: any) => {
    const activeFilters = tableFilterFields.filter((field: any) => {
      const activeFilter = filters?.[field];
      return activeFilter?.length;
    });
    const isMatchForAllFilters = activeFilters.every((activeFilter: any) => {
      const selectedFilterValues = filters?.[activeFilter];
      const developerValue = developer?.[activeFilter];
      return selectedFilterValues?.includes(developerValue);
    });
    return isMatchForAllFilters ? ++total : total;
  }, 0);
}

export function removeColumnsFlags(
  rearrangedColumns: any[],
  enableHoursWorked: boolean | undefined,
) {
  return rearrangedColumns.filter(
    (column) =>
      column?.dataIndex !== TableDataKeyEnum.billedHours || enableHoursWorked,
  );
}

export function removeColumns(
  currentColumns: any[],
  columnsToRemove: Set<TableDataKeyEnum>,
) {
  return currentColumns.filter(
    ({ dataIndex }) => !columnsToRemove.has(dataIndex),
  );
}

export function isSomeStatusPreOnboarding(developers: any[]) {
  return developers.some(
    (developer: ParsedDeveloper) =>
      developer?.billingStatus === "Pre-Onboarding",
  );
}

export function getContactsWidth(developers: any[], isSelfServe: boolean) {
  if (!isSelfServe) {
    return {
      customerContact: Widths.CustomerContactExtraLarge,
      turingContact: Widths.TuringContactExtraLarge,
    };
  }
  const largestCustomerContact = getLargestWidth(
    developers,
    TableDataKeyEnum.customerContactEmail,
  );
  const largestTuringContact = getLargestWidth(
    developers,
    TableDataKeyEnum.accountExecutiveEmail,
  );
  const customerContactLength =
    largestCustomerContact > CUSTOMER_CONTACT_LIMIT_LENGTH
      ? Widths.CustomerContactLarge
      : Widths.CustomerContact;
  const turingContactLength =
    largestTuringContact > TURING_CONTACT_LIMIT_LENGTH
      ? Widths.TuringContactLarge
      : Widths.TuringContact;
  return {
    customerContact: customerContactLength,
    turingContact: turingContactLength,
  };
}
