import React, { useEffect, useMemo, useState, useRef } from "react";
import { Column } from "@tanstack/react-table";
import classNames from "classnames";
import { Popover } from "antd";
import { debounce } from "lodash";
import { isValueValid } from "../../utils/get-placeholder-value/get-placeholder-value";
import { isArrayNotEmpty } from "../../utils/helpers/helpers";
import {
  ColumnOrder,
  ColumnType,
  ColumnVisibility,
  EngagementDashboardPreferences,
  Filter,
  SaveEngagementDashboardPreferences,
  Sort,
} from "../dashboard/dashboard.types";
import styles from "./dashboard-header-item-filter.module.css";
import { SearchSvg } from "../svgs/Search";
import { Checkbox } from "../checkbox/checkbox";
import { ButtonDesignSystemV2 } from "../button-design-system-v2/button-design-system-v2";
import { FilterSvg } from "../svgs/Filter";
import {
  TableDataKeyEnum,
  TableDataKeyToNameMap,
} from "../postmatch-table-columns/postmatch-table-columns.constants";
import {
  saveFilterPreferences,
  sortTextFunc,
} from "../dashboard/dashboard.utils";
import { CloseSvg } from "../svgs/CloseSvg";
import { ClearSvg } from "../svgs/ClearSvg";
import { ButtonDesignSystemV2Color } from "../button-design-system-v2/button-design-system-v2.types";
import { LogActivity } from "../postmatch-table/postmatch-table.types";

export function DashboardHeaderItemFilter({
  column,
  tableState,
  saveEngagementDashboardPreferences,
  isFetchingPreferences,
  engagementDashboardPreferences,
  resetCount,
  logActivity,
  isPopoverOpen,
  setIsPopoverOpen,
}: {
  column: Column<any, unknown>;
  tableState: {
    columnOrder: ColumnOrder;
    columnVisibility: ColumnVisibility;
    columnFilters: Filter[];
    sorting: Sort[];
  };
  saveEngagementDashboardPreferences: SaveEngagementDashboardPreferences;
  isFetchingPreferences: boolean;
  engagementDashboardPreferences: EngagementDashboardPreferences;
  resetCount: number;
  logActivity: LogActivity;
  isPopoverOpen: boolean;
  setIsPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [isCheckedSelectAll, setIsCheckedSelectAll] = useState(false);
  const [selectedValues, setSelectedValues] = useState<Set<string>>(new Set());
  const [searchText, setSearchText] = useState("");
  const [minMax, setMinMax] = useState<Array<string>>(["", ""]);
  const [activeFilter, setActiveFilter] = useState<string[]>([]);

  const searchInputRef = useRef<HTMLInputElement>(null);
  const minInputRef = useRef<HTMLInputElement>(null);

  const { columnOrder, columnVisibility, columnFilters, sorting } = tableState;

  const columnMeta = column?.columnDef?.meta as Record<string, any>;
  const columnType = columnMeta?.columnType;

  const isFilterWithCheckboxes = columnType === ColumnType.TextWithCheckboxes;
  const isFilterNumericalType = columnType === ColumnType.NumberType;
  const isFilterDateType = columnType === ColumnType.DateType;
  const isFilterNumericalTypeOrDateType =
    isFilterNumericalType || isFilterDateType;

  const uniqueValues = useMemo(
    () =>
      !isFilterWithCheckboxes
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort((a, b) =>
            sortTextFunc(a as string, b as string),
          ),
    [isPopoverOpen],
  );

  const numFilterOptions = useMemo(
    () => column.getFacetedUniqueValues().size,
    [isPopoverOpen],
  );

  const isCheckedSelectAllManually = selectedValues.size === numFilterOptions;

  const isFiltered = useMemo(() => {
    const isFilterPopulated = Boolean(
      columnFilters?.find((columnFilter) => column.id === columnFilter?.id)
        ?.value?.length,
    );
    return column.getIsFiltered() && isFilterPopulated && !isCheckedSelectAll;
  }, [columnFilters]);

  const placeholderMinMax = useMemo(
    () =>
      isFilterDateType
        ? { min: "Min format MM/DD/YYYY", max: "Max format MM/DD/YYYY" }
        : column.id === TableDataKeyEnum.durationSort
        ? { min: "Min number of days", max: "Max number of days" }
        : { min: "Min value", max: "Max value" },
    [],
  );

  const isResetButtonDisabled = useMemo(() => {
    if (isFilterWithCheckboxes) {
      return !selectedValues.size;
    }
    if (isFilterNumericalTypeOrDateType) {
      return !minMax.some(Boolean);
    }
    return !searchText.length;
  }, [selectedValues, minMax, searchText]);

  useEffect(() => {
    if (resetCount) {
      setSelectedValues(new Set());
      setSearchText("");
      setMinMax(["", ""]);
    }
  }, [resetCount]);

  useEffect(() => {
    if (!isFetchingPreferences) {
      const { filter } = engagementDashboardPreferences;
      const preselectedFilters = filter?.find(({ id }) => column.id === id)
        ?.value as string[];
      if (isFilterWithCheckboxes) {
        const newSelectedValues = new Set<string>();
        if (isArrayNotEmpty(preselectedFilters)) {
          preselectedFilters?.forEach((valueItem) => {
            newSelectedValues.add(valueItem);
          });
          setSelectedValues(newSelectedValues);
        }
      } else if (isFilterNumericalTypeOrDateType) {
        const savedMin = preselectedFilters?.[0];
        const savedMax = preselectedFilters?.[1];
        const normalizedSavedMin = typeof savedMin === "string" ? savedMin : "";
        const normalizedSavedMax = typeof savedMax === "string" ? savedMax : "";
        setMinMax([normalizedSavedMin, normalizedSavedMax]);
      } else {
        const preExistingText = preselectedFilters?.[0] || "";
        setSearchText(preExistingText);
      }
    }
  }, [isFetchingPreferences]);

  useEffect(() => {
    if (isPopoverOpen) {
      setTimeout(() => {
        searchInputRef.current?.focus();
        minInputRef.current?.focus();
      }, 0);
    }
  }, [isPopoverOpen]);

  const onChangePopover = async (newIsOpen: boolean) => {
    setIsPopoverOpen(newIsOpen);
    if (!newIsOpen) {
      await saveFilterPreferences(
        columnOrder,
        columnVisibility,
        sorting,
        columnFilters,
        saveEngagementDashboardPreferences,
        logActivity,
        column.id,
        activeFilter,
      );
    } else if (isFilterWithCheckboxes) {
      setSearchText("");
    }
  };

  const onClickClose = async () => {
    setIsPopoverOpen(false);
    await saveFilterPreferences(
      columnOrder,
      columnVisibility,
      sorting,
      columnFilters,
      saveEngagementDashboardPreferences,
      logActivity,
      column.id,
      activeFilter,
    );
  };

  const onChangeCheckboxInput = (name: string) => {
    const newSelectedValues = new Set(selectedValues);
    if (!selectedValues.has(name)) {
      newSelectedValues.add(name);
    } else {
      newSelectedValues.delete(name);
    }
    setSelectedValues(newSelectedValues);
    const isAllSelected = newSelectedValues.size === numFilterOptions;
    setIsCheckedSelectAll(isAllSelected);
    const newValues = [...newSelectedValues];
    column.setFilterValue(newValues);
    setActiveFilter(newValues);
  };

  const onChangeCheckboxInputSelectAll = () => {
    const newIsCheckedSelectAll = isCheckedSelectAllManually
      ? false
      : !isCheckedSelectAll;
    const newSelectedValues = new Set<string>();
    if (newIsCheckedSelectAll) {
      uniqueValues.forEach((uniqueValue) => {
        newSelectedValues.add(uniqueValue);
      });
      setIsCheckedSelectAll(true);
    } else {
      setIsCheckedSelectAll(false);
    }
    setSelectedValues(newSelectedValues);
    const newValues: string[] = [];
    column.setFilterValue(newValues);
    setActiveFilter(newValues);
  };

  const onChangeMinMax = (min: string, max: string) => {
    const newValues = min === "" && max === "" ? [] : [min, max];
    setMinMax([min, max]);
    column.setFilterValue(newValues);
    setActiveFilter(newValues);
  };

  const updateTableFromTextSearch = debounce((text) => {
    const trimmedSearchText = text.trim();
    const newValues = trimmedSearchText ? [trimmedSearchText] : [];
    column.setFilterValue(newValues);
    setActiveFilter(newValues);
  }, 100);

  const onChangeSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    setSearchText(text);
    if (!isFilterWithCheckboxes) {
      updateTableFromTextSearch(text);
    }
  };

  const onClickReset = () => {
    if (isFilterWithCheckboxes) {
      setSearchText("");
      setSelectedValues(new Set());
    } else if (isFilterNumericalTypeOrDateType) {
      setMinMax(["", ""]);
    } else {
      setSearchText("");
    }
    const newValues: string[] = [];
    column.setFilterValue(newValues);
    setActiveFilter(newValues);
  };

  const onClickClear = (isMin = false) => {
    if (isFilterWithCheckboxes) {
      setSearchText("");
    } else if (isFilterNumericalTypeOrDateType) {
      const newMinMax = isMin ? ["", minMax[1]] : [minMax[0], ""];
      const newMinMaxFilterValue = minMax.every(Boolean) ? newMinMax : [];
      setMinMax(newMinMax);
      column.setFilterValue(newMinMaxFilterValue);
      setActiveFilter(newMinMax);
    } else {
      setSearchText("");
      column.setFilterValue([]);
      setActiveFilter([]);
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" || e.key === "Escape") {
      onClickClose();
    }
  };

  const content = (
    <div className={styles.popoverContentContainer} onKeyDown={onKeyDown}>
      <div className={styles.popoverContentTitle}>
        {
          TableDataKeyToNameMap[
            TableDataKeyEnum[column.id as keyof typeof TableDataKeyEnum]
          ]
        }
      </div>
      {!isFilterNumericalTypeOrDateType ? (
        <div className={styles.popoverContentSearch}>
          <SearchSvg />
          <input
            placeholder="Search"
            value={searchText}
            onChange={onChangeSearchText}
            ref={searchInputRef}
          />
          {searchText ? (
            <div
              className={styles.popoverContentSearchClear}
              onClick={() => onClickClear()}
            >
              <ClearSvg />
            </div>
          ) : null}
        </div>
      ) : (
        <div>
          <div className={styles.popoverContentSearch}>
            <SearchSvg />
            <input
              onChange={(e) => onChangeMinMax(e.target.value, minMax[1])}
              value={minMax[0]}
              placeholder={placeholderMinMax.min}
              ref={minInputRef}
            />
            {minMax[0] ? (
              <div
                className={styles.popoverContentSearchClear}
                onClick={() => onClickClear(true)}
              >
                <ClearSvg />
              </div>
            ) : null}
          </div>
          <div className={styles.popoverContentSearch}>
            <SearchSvg />
            <input
              onChange={(e) => onChangeMinMax(minMax[0], e.target.value)}
              value={minMax[1]}
              placeholder={placeholderMinMax.max}
            />
            {minMax[1] ? (
              <div
                className={styles.popoverContentSearchClear}
                onClick={() => onClickClear()}
              >
                <ClearSvg />
              </div>
            ) : null}
          </div>
        </div>
      )}

      <div
        className={classNames({
          [styles.popoverContentValues]: isFilterWithCheckboxes,
        })}
      >
        {isFilterWithCheckboxes && !searchText.length && (
          <div
            key={`${column.id}-filter-select-all}`}
            className={styles.popoverContentValuesInput}
          >
            <Checkbox
              text="(Select all)"
              onClick={onChangeCheckboxInputSelectAll}
              isChecked={isCheckedSelectAllManually}
              isCheckedPartialForSelectAll={Boolean(selectedValues.size)}
              isEnterEnabled={false}
            />
          </div>
        )}
        {isFilterWithCheckboxes &&
          uniqueValues.map((uniqueValue) => {
            const uniqueValueLowerCase =
              typeof uniqueValue === "string"
                ? uniqueValue.toLowerCase()
                : uniqueValue;
            return (!searchText.length ||
              uniqueValueLowerCase?.includes(searchText.toLowerCase())) &&
              isValueValid(uniqueValueLowerCase) ? (
              <div
                key={`${column.id}-${uniqueValue}`}
                className={styles.popoverContentValuesInput}
              >
                <Checkbox
                  text={uniqueValue}
                  onClick={() => onChangeCheckboxInput(uniqueValue)}
                  isChecked={selectedValues.has(uniqueValue)}
                  isEnterEnabled={false}
                />
              </div>
            ) : null;
          })}
      </div>
      <div className={styles.submitWrapper}>
        <div className={styles.submitButtonWrapper}>
          <ButtonDesignSystemV2
            text="Clear"
            onClick={onClickReset}
            buttonClassName={styles.submitButton}
            color={ButtonDesignSystemV2Color.Tertiary}
            disabled={isResetButtonDisabled}
          />
        </div>
      </div>
      <div className={styles.popoverContentClose} onClick={onClickClose}>
        <CloseSvg />
      </div>
    </div>
  );

  return (
    <Popover
      content={content}
      trigger="click"
      getPopupContainer={(trigger) => trigger}
      placement="bottomLeft"
      open={isPopoverOpen}
      onOpenChange={onChangePopover}
      className={styles.popover}
    >
      <div className={styles.filter}>
        <FilterSvg isFiltered={isFiltered} />
      </div>
    </Popover>
  );
}
