import React, { useEffect, useState } from "react";
import { Input } from "antd";
import classNames from "classnames";
import { TextAreaProps } from "antd/lib/input";
import styles from "./timesheets-modal.module.css";
import { ButtonDesignSystemV2 } from "../button-design-system-v2/button-design-system-v2";
import { getTimesheetModalFields } from "./timesheets-modal.utils";
import { TimesheetsModalProps } from "./timesheets-modal.types";
import { notificationConfig } from "../toast/toast";
import { ModalDesignSystemV2 } from "../modal-design-system-v2/modal-design-system-v2";

export function TimesheetsModal({
  isApproval,
  onCloseTimesheetsModal,
  onSubmitTimesheetsModal,
  isOpen,
  isSubmittingTimesheet,
  ToastService,
}: TimesheetsModalProps) {
  const { title, subtitle, placeholder } = getTimesheetModalFields(isApproval);
  const [currentMessage, setCurrentMessage] = useState("");
  const [status, setStatus] = useState<TextAreaProps["status"]>("");

  useEffect(() => {
    setCurrentMessage("");
    setStatus("");
  }, [isOpen]);

  const onSubmit = () => {
    const trimmedMessage = currentMessage.trim();
    if (isApproval) {
      onSubmitTimesheetsModal(trimmedMessage);
    } else if (trimmedMessage === "") {
      setStatus("error");
      ToastService.warn("Please enter a message", notificationConfig);
    } else {
      onSubmitTimesheetsModal(trimmedMessage);
    }
  };

  const onClose = () => {
    onCloseTimesheetsModal();
  };

  return (
    <ModalDesignSystemV2 isOpen={isOpen} onClose={onClose}>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.subtitle}>{subtitle}</p>
      <div className={styles.textareaWrapper}>
        <Input.TextArea
          placeholder={placeholder}
          className={classNames(styles.textarea)}
          onChange={(e) => setCurrentMessage(e.target.value)}
          value={currentMessage}
          status={status}
          onFocus={() => setStatus("")}
        />
      </div>
      <div className={styles.buttonWrapper}>
        <ButtonDesignSystemV2
          text="Submit"
          buttonClassName={classNames(styles.button, {
            [styles.loadingExpanded]: isSubmittingTimesheet,
          })}
          isLoading={isSubmittingTimesheet}
          onClick={onSubmit}
        />
      </div>
    </ModalDesignSystemV2>
  );
}
