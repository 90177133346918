import React from "react";
import { CheckboxSvg } from "../svgs/CheckboxSvg";
import { CheckboxAllSvg } from "../svgs/CheckboxAllSvg";
import { CheckboxEmptySvg } from "../svgs/CheckboxEmptySvg";
import styles from "./checkbox.module.css";

function CheckboxIcon({
  isCheckedPartialForSelectAll,
  isChecked,
}: {
  isCheckedPartialForSelectAll: boolean;
  isChecked: boolean;
}) {
  if (isChecked) {
    return <CheckboxSvg />;
  }
  if (isCheckedPartialForSelectAll) {
    return <CheckboxAllSvg />;
  }
  return <CheckboxEmptySvg />;
}

export function Checkbox({
  text,
  isChecked,
  onClick,
  isEnterEnabled = true,
  isCheckedPartialForSelectAll = false,
}: {
  text: string;
  isChecked: boolean;
  onClick: () => void;
  isEnterEnabled?: boolean;
  isCheckedPartialForSelectAll?: boolean;
}) {
  const onKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (!isEnterEnabled && e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <button onClick={onClick} className={styles.checkbox} onKeyDown={onKeyDown}>
      <div className={styles.checkboxSvg}>
        <CheckboxIcon
          isCheckedPartialForSelectAll={isCheckedPartialForSelectAll}
          isChecked={isChecked}
        />
      </div>
      <span className={styles.checkboxText}>{text}</span>
    </button>
  );
}
