import { FormattedDeveloper } from "../../types/formatted-developer.types";
import { OpportunityRateHistoryResponse, RateChangeType } from "../../utils";
import { TableDataKeyEnum } from "../postmatch-table-columns/postmatch-table-columns.constants";
import {
  LogActivity,
  OnClickFeedbackTable,
  PostmatchActivityType,
} from "../postmatch-table/postmatch-table.types";

export type ColumnOrder = TableDataKeyEnum[];

export type ColumnVisibility = Record<TableDataKeyEnum, boolean>;

export interface Sort {
  id: TableDataKeyEnum;
  desc: boolean;
}

export interface Filter {
  id: TableDataKeyEnum;
  value: string[];
}

export interface EngagementDashboardPreferences {
  columnOrder: ColumnOrder;
  columnVisibility: ColumnVisibility;
  sort: Sort[];
  filter: Filter[];
}

export type SaveEngagementDashboardPreferences = (
  newPreferences: EngagementDashboardPreferences,
) => void;

export interface DashboardProps {
  developers: FormattedDeveloper[];
  postmatchActivityType: PostmatchActivityType;
  logActivity: LogActivity;
  onClickFeedbackTable: OnClickFeedbackTable;
  saveEngagementDashboardPreferences: SaveEngagementDashboardPreferences;
  engagementDashboardPreferences: EngagementDashboardPreferences;
  isFetchingPreferences: boolean;
  isLoading: boolean;
  columnsToRemove?: Set<TableDataKeyEnum | string>;
  openRateHistoryModal?: rateHistoryModalOpenAction;
  gbFlags?: Record<string, any>;
}

export type rateHistoryModalOpenAction = (
  props: OpenRateHistoryModalProps,
) => void;

export interface OpenRateHistoryModalProps {
  rateHistory: Array<OpportunityRateHistoryResponse>;
  rateChangeType: RateChangeType;
  developerName: string;
  jobTitle: string;
}

export enum ColumnType {
  TextWithCheckboxes = "TextWithCheckboxes",
  TextWithoutCheckboxes = "TextWithoutCheckboxes",
  NumberType = "NumberType",
  DateType = "DateType",
  Disabled = "Disable",
}
