import { ITimeCardSummary } from "./time-card-summary-types";

const mockTimeEntries = [
  {
    id: 233,
    timeCardId: "234",
    from: "some date",
    to: "some date",
    dayDate: "Aug 28",
    dayTitle: "Mon",
    durationMinutes: 510,
  },
  {
    id: 233,
    timeCardId: "234",
    from: "some date",
    to: "some date",
    dayDate: "Aug 29",
    dayTitle: "Tue",
    durationMinutes: 450,
  },
  {
    id: 233,
    timeCardId: "234",
    from: "some date",
    to: "some date",
    dayDate: "Aug 30",
    dayTitle: "Wed",
    durationMinutes: 480,
  },
  {
    id: 233,
    timeCardId: "234",
    from: "some date",
    to: "some date",
    dayDate: "Aug 31",
    dayTitle: "Thu",
    durationMinutes: 480,
  },
  {
    id: 233,
    timeCardId: "234",
    from: "some date",
    to: "some date",
    dayDate: "Sept 01",
    dayTitle: "Fri",
    durationMinutes: 480,
  },
  {
    id: 233,
    timeCardId: "234",
    from: "some date",
    to: "some date",
    dayDate: "Sept 02",
    dayTitle: "Sat",
    durationMinutes: 0,
  },
  {
    id: 233,
    timeCardId: "234",
    from: "some date",
    to: "some date",
    dayDate: "Sept 03",
    dayTitle: "Sun",
    durationMinutes: 0,
  },
];
const toBeApprovedMockTimeCard: ITimeCardSummary = {
  id: `234`,
  developerId: 232,
  developerName: "Aakash",
  projectId: 334,
  jobId: 24,
  periodEnd: "September 2",
  periodStart: "August 27",
  totalMinutes: 2400,
  intervalString: "August 27 - September 2",
  timeEntries: mockTimeEntries,
  approvals: [
    {
      id: 123,
      approvalStatus: "PENDING_APPROVAL",
      approvalDate: "some date",
      createdAt: "some date",
      approvalTitle: "WO00330 - Senior full stack engineer",
      approvalComment: null,
      approvalBody: "Approver: Roger Lee",
      approvalChainStructure: {
        id: 234,
        approverEmail: "manageremail@email.com",
        approverType: "CLIENT",
      },
      approverName: "Roger LE",
      approvalType: "CLIENT",
    },
  ],
  activityLogs: [
    {
      id: 234,
      activityTitle: "Timesheet submited by Turing",
      createdAt: "Oct 27, 2023, 1:43 PM",
    },
  ],
};

const rejectedMockTimeCard: ITimeCardSummary = {
  ...toBeApprovedMockTimeCard,
  approvals: [
    {
      id: 123,
      approvalStatus: "REJECTED",
      approvalDate: "some date",
      createdAt: "some date",
      approvalTitle: "WO00330 - Senior full stack engineer",
      approvalComment: null,
      approvalBody: "Rejected by: Roger Lee",
      approvalChainStructure: {
        id: 234,
        approverEmail: "manageremail@email.com",
        approverType: "CLIENT",
      },
      approverName: "Roger LE",
      approvalType: "CLIENT",
    },
  ],
  activityLogs: [
    {
      id: 455,
      activityTitle: "Time sheet rejected by Roger Lee",
      createdAt: "Nov 1, 2023, 1:43 PM",
    },
    {
      id: 234,
      activityTitle: "Timesheet submited by Turing",
      createdAt: "Oct 27, 2023, 1:43 PM",
    },
  ],
};

const approvedMockTimeCard: ITimeCardSummary = {
  ...toBeApprovedMockTimeCard,
  approvals: [
    {
      id: 123,
      approvalStatus: "APPROVED",
      approvalDate: "some date",
      createdAt: "some date",
      approvalTitle: "WO00330 - Senior full stack engineer",
      approvalComment: null,
      approvalBody: "Approved By: Roger Lee",
      approvalChainStructure: {
        id: 234,
        approverEmail: "manageremail@email.com",
        approverType: "CLIENT",
      },
      approverName: "Roger LE",
      approvalType: "CLIENT",
    },
  ],
  activityLogs: [
    {
      id: 455,
      activityTitle: "Time sheet approved by Roger Lee",
      createdAt: "Nov 1, 2023, 1:43 PM",
    },
    {
      id: 234,
      activityTitle: "Timesheet submited by Turing",
      createdAt: "Oct 27, 2023, 1:43 PM",
    },
  ],
};

const approvedMockTimeCardWithComment: ITimeCardSummary = {
  ...approvedMockTimeCard,
  activityLogs: [
    {
      id: 4556,
      activityTitle: "Roger Lee commented \n Nice",
      createdAt: "Nov 1, 2023, 1:45 PM",
    },
    {
      id: 455,
      activityTitle: "Time sheet approved by Roger Lee",
      createdAt: "Nov 1, 2023, 1:43 PM",
    },
    {
      id: 234,
      activityTitle: "Timesheet submited by Turing",
      createdAt: "Oct 27, 2023, 1:43 PM",
    },
  ],
};

export {
  toBeApprovedMockTimeCard,
  approvedMockTimeCard,
  rejectedMockTimeCard,
  approvedMockTimeCardWithComment,
};
