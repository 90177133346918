import React from "react";
import { Link } from "react-router-dom";
import { TimesheetsTableDataSource } from "../../types/postmatch.types";
import { TimesheetsTableContractor } from "../timesheets-table-contractor/timesheets-table-contractor";

export function TimesheetsTableRenderContractorLink() {
  return function (contractorLink: string, record: TimesheetsTableDataSource) {
    const contractor = record?.contractor;
    const img = record?.avatarUrl;

    const isDeveloperClickable = Boolean(contractorLink && contractor);

    return isDeveloperClickable ? (
      <Link to={contractorLink} target="_blank" rel="noopener noreferrer">
        <TimesheetsTableContractor
          contractor={contractor}
          img={img}
          isDeveloperClickable={isDeveloperClickable}
        />
      </Link>
    ) : (
      <TimesheetsTableContractor
        contractor={contractor}
        img={img}
        isDeveloperClickable={isDeveloperClickable}
      />
    );
  };
}
