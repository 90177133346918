import React from "react";
import { DashboardRenderFeedbackEntity } from "../dashboard-render-feedback-entity/dashboard-render-feedback-entity";
import { PostmatchTableProps } from "../postmatch-table/postmatch-table.types";

export function DashboardRenderFeedback(
  onClickFeedbackTable: PostmatchTableProps["onClickFeedbackTable"],
) {
  return function (info: any) {
    const feedback = info?.getValue();
    return (
      <DashboardRenderFeedbackEntity
        feedback={feedback}
        onClickFeedbackTable={onClickFeedbackTable}
      />
    );
  };
}
