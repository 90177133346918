import React from "react";
import { Link } from "react-router-dom";
import { TimesheetsTableDataSource } from "../../types/postmatch.types";

export const TimesheetsTableRenderHours = () => {
  return (text: string, record: TimesheetsTableDataSource) => {
    const timeCardId = record.timeCardId;
    return timeCardId ? (
      <Link to={`/timesheets/time-card/${timeCardId}`}>{text}</Link>
    ) : (
      ""
    );
  };
};
