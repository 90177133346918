import React from "react";
import { TimesheetsTableColumnsHeader } from "../timesheets-table-columns-header/timesheets-table-columns-header";
import { TimesheetsTableRenderApprovalRejection } from "../timesheets-table-render-approval-rejection/timesheets-table-render-approval-rejection";
import { TimesheetsTableRenderDeveloper } from "../timesheets-table-render-developer/timesheets-table-render-developer";
import { TimesheetsTableRenderHours } from "../timesheets-table-render-hours/timesheets-table-render-hours";
import { TimesheetsTableRenderStatus } from "../timesheets-table-render-status/timesheets-table-render-status";
import {
  OnClickDeveloperOpenDDP,
  OnOpenRejectionsModal,
  OnOpenTimesheetsModal,
} from "../timesheets-table/timesheets-table.types";
import {
  TimesheetsKeyEnum,
  TimesheetsKeyToNameMap,
} from "./timesheets-table-columns.constants";
import { TimesheetsTableRenderComments } from "../timesheets-table-render-comments/timesheets-table-render-comments";
import { TimesheetsTableRenderRejectedDate } from "../timesheets-table-render-rejected-date/timesheets-table-render-rejected-date";
import { TimesheetsTableRenderJibble } from "../timesheets-table-render-jibble/timesheets-table-render-jibble";
import { TimesheetsTableRenderContractorLink } from "../timesheets-table-render-contractor-link/timesheets-table-render-contractor-link";

export function getTimesheetsTableColumns(
  tableFilters: Record<string, any>,
  onClickDeveloperOpenDDP: OnClickDeveloperOpenDDP,
  onOpenTimesheetsModal: OnOpenTimesheetsModal,
  disableDDP: boolean,
  onOpenRejectionsModal: OnOpenRejectionsModal,
  disableApprovalWorkflow?: boolean,
) {
  return [
    {
      title: (
        <TimesheetsTableColumnsHeader
          text={TimesheetsKeyToNameMap[TimesheetsKeyEnum.contractor]}
        />
      ),
      dataIndex: TimesheetsKeyEnum.contractor,
      render: TimesheetsTableRenderDeveloper(
        onClickDeveloperOpenDDP,
        disableDDP,
      ),
      sorter: true,
    },

    {
      title: (
        <TimesheetsTableColumnsHeader
          text={TimesheetsKeyToNameMap[TimesheetsKeyEnum.week]}
        />
      ),
      dataIndex: TimesheetsKeyEnum.week,
      sorter: true,
    },

    {
      title: (
        <TimesheetsTableColumnsHeader
          text={TimesheetsKeyToNameMap[TimesheetsKeyEnum.totalHours]}
        />
      ),
      dataIndex: TimesheetsKeyEnum.totalHours,
      render: TimesheetsTableRenderHours(),
      sorter: true,
    },

    {
      title: (
        <TimesheetsTableColumnsHeader
          text={TimesheetsKeyToNameMap[TimesheetsKeyEnum.status]}
        />
      ),
      dataIndex: TimesheetsKeyEnum.status,
      render: TimesheetsTableRenderStatus(),
      sorter: true,
      filters: tableFilters.status,
    },

    ...(!disableApprovalWorkflow
      ? [
          {
            title: (
              <TimesheetsTableColumnsHeader
                text={TimesheetsKeyToNameMap[TimesheetsKeyEnum.approveOrReject]}
              />
            ),
            dataIndex: TimesheetsKeyEnum.approveOrReject,
            render: TimesheetsTableRenderApprovalRejection(
              onOpenTimesheetsModal,
            ),
            width: 32 + 32 + 12,
          },
        ]
      : []),
    {
      title: (
        <TimesheetsTableColumnsHeader
          text={TimesheetsKeyToNameMap[TimesheetsKeyEnum.contractorLink]}
        />
      ),
      dataIndex: TimesheetsKeyEnum.contractorLink,
      render: TimesheetsTableRenderContractorLink(),
      sorter: true,
    },

    {
      title: (
        <TimesheetsTableColumnsHeader
          text={TimesheetsKeyToNameMap[TimesheetsKeyEnum.comments]}
        />
      ),
      dataIndex: TimesheetsKeyEnum.comments,
      render: TimesheetsTableRenderComments(onOpenRejectionsModal),
      width: 400,
    },

    {
      title: (
        <TimesheetsTableColumnsHeader
          text={TimesheetsKeyToNameMap[TimesheetsKeyEnum.rejectionData]}
        />
      ),
      dataIndex: TimesheetsKeyEnum.rejectionData,
      render: TimesheetsTableRenderRejectedDate(),
    },

    {
      title: (
        <TimesheetsTableColumnsHeader
          text={TimesheetsKeyToNameMap[TimesheetsKeyEnum.jibble]}
        />
      ),
      dataIndex: TimesheetsKeyEnum.jibble,
      render: TimesheetsTableRenderJibble(),
    },
  ];
}
