import React, { useEffect, useMemo, useState } from "react";
import { isFunction } from "lodash";
import styles from "./time-card-action-container.module.css";
import {
  TimeCardActionsProps,
  TimeCardStatus,
} from "../time-card-summary-types";
import { ButtonDesignSystemV2 } from "../../button-design-system-v2/button-design-system-v2";
import { ButtonDesignSystemV2Color } from "../../button-design-system-v2/button-design-system-v2.types";
import { LikeSvg } from "../../svgs/LikeSvg";
import { DisLikeSvg } from "../../svgs/DisLikeSvg";

function TimeCardActionContainer({
  timeCardStatus,
  onTimeCardApprove,
  onTimeCardComment,
  onTimeCardReject,
  approveActionProgress,
  rejectActionProgress,
  commentActionProgress,
  resetCommentTrigger,
  setCommenTrigger,
}: TimeCardActionsProps) {
  const [comment, setComment] = useState("");
  const shouldShowStatusChangeBtn = useMemo(() => {
    if (
      timeCardStatus === TimeCardStatus.APPROVED ||
      timeCardStatus === TimeCardStatus.REJECTED
    ) {
      return false;
    }
    return true;
  }, [timeCardStatus]);

  useEffect(() => {
    if (resetCommentTrigger) {
      setComment("");
      setCommenTrigger && setCommenTrigger(false);
    }
  }, [resetCommentTrigger]);

  return (
    <div className={styles.container}>
      <form>
        <div className={styles.control}>
          <label htmlFor="comment" className={styles.label}>
            Comment
          </label>
          <textarea
            className={styles.textarea}
            name="comment"
            id="comment"
            placeholder="Enter your feedback"
            rows={4}
            value={comment}
            onChange={(event) => setComment(event.target.value)}
          ></textarea>
        </div>
        <div className={styles.actions}>
          {shouldShowStatusChangeBtn ? (
            <React.Fragment>
              <ButtonDesignSystemV2
                isLoading={rejectActionProgress}
                text="Reject"
                Icon={DisLikeSvg}
                color={ButtonDesignSystemV2Color.Secondary}
                onClick={() =>
                  isFunction(onTimeCardReject) && onTimeCardReject(comment)
                }
                disabled={approveActionProgress ? true : false}
              />
              <ButtonDesignSystemV2
                isLoading={approveActionProgress}
                text="Approve"
                Icon={LikeSvg}
                color={ButtonDesignSystemV2Color.Primary}
                onClick={() =>
                  isFunction(onTimeCardApprove) && onTimeCardApprove(comment)
                }
                disabled={rejectActionProgress ? true : false}
              />
            </React.Fragment>
          ) : (
            <ButtonDesignSystemV2
              isLoading={commentActionProgress}
              text="Submit"
              color={ButtonDesignSystemV2Color.Primary}
              onClick={() =>
                isFunction(onTimeCardComment) && onTimeCardComment(comment)
              }
            />
          )}
        </div>
      </form>
    </div>
  );
}

export { TimeCardActionContainer };
