import {
  APPROVAL_STATUS,
  TimesheetsTableDataSource,
} from "../../types/postmatch.types";
import {
  convertDisplayableStatusToEnum,
  convertStatusToDisplayableText,
} from "../../utils/timesheets-status/timesheets-status";
import { TimesheetsKeyEnum } from "../timesheets-table-columns/timesheets-table-columns.constants";
import { TableFilters } from "./timesheets-table.types";

function formatTableFilters(timesheets: Record<string, any>[], field: string) {
  if (TimesheetsKeyEnum.status) {
    return Object.values(APPROVAL_STATUS)
      .filter(
        (approvalStatus) =>
          approvalStatus !== APPROVAL_STATUS.QUEUED &&
          approvalStatus !== APPROVAL_STATUS.AUTO_APPROVED,
      )
      .map((approvalStatus) => ({
        text: convertStatusToDisplayableText(approvalStatus),
        value: approvalStatus,
      }));
  }
  const allItems = timesheets.map(
    (timesheet) => timesheet[field as keyof TimesheetsTableDataSource],
  );
  const uniqueItems = new Set([...allItems]);
  const formattedItems: TableFilters[] = [];
  uniqueItems.forEach((uniqueItem) => {
    formattedItems.push({
      text: uniqueItem,
      value: convertDisplayableStatusToEnum(uniqueItem as string),
    });
  });
  return formattedItems;
}

export function getTableFilterFields() {
  return [TimesheetsKeyEnum.status];
}

export function getTableFilters(timesheets: TimesheetsTableDataSource[]) {
  return getTableFilterFields().reduce((acc: Record<string, any>, curr) => {
    acc[curr] = formatTableFilters(timesheets, curr);
    return acc;
  }, {});
}

export function getPaginationInfo(start: number, end: number, total: number) {
  return `${start}-${end} of ${total}`;
}

export function computePaginationLimits(
  currentPage: number,
  pageSize: number,
  total: number,
) {
  const pageEnd = currentPage * pageSize;
  const pageStart = pageEnd - pageSize + 1;
  const limitedPageEnd = Math.min(pageEnd, total);
  return { pageStart, limitedPageEnd };
}
