import React from "react";
import { PostmatchTableProps } from "../postmatch-table/postmatch-table.types";
import { Feedback } from "../../types/postmatch.types";
import { ButtonDesignSystemV2 } from "../button-design-system-v2/button-design-system-v2";
import styles from "./dashboard-render-feedback-entity.module.css";

export function DashboardRenderFeedbackEntity({
  feedback,
  onClickFeedbackTable,
}: {
  feedback: Feedback;
  onClickFeedbackTable: PostmatchTableProps["onClickFeedbackTable"];
}) {
  const onClick = () => {
    if (typeof onClickFeedbackTable === "function") {
      onClickFeedbackTable(feedback);
    }
  };
  return (
    <ButtonDesignSystemV2
      onClick={onClick}
      text="Submit"
      buttonClassName={styles.button}
    />
  );
}
