import React from "react";

export function SearchSvg() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.51916 15.6155C7.81148 15.6155 6.36533 15.0232 5.18073 13.8386C3.99612 12.6539 3.40381 11.2078 3.40381 9.50012C3.40381 7.79243 3.99612 6.34628 5.18073 5.16167C6.36533 3.97707 7.81148 3.38477 9.51916 3.38477C11.2268 3.38477 12.673 3.97707 13.8576 5.16167C15.0422 6.34628 15.6345 7.79243 15.6345 9.50012C15.6345 10.2142 15.5147 10.8963 15.2749 11.5463C15.0352 12.1963 14.7153 12.7616 14.3153 13.2424L20.0692 18.9963C20.2076 19.1347 20.2784 19.3088 20.2816 19.5184C20.2849 19.728 20.214 19.9052 20.0692 20.0501C19.9243 20.195 19.7486 20.2674 19.5422 20.2674C19.3358 20.2674 19.1602 20.195 19.0153 20.0501L13.2615 14.2962C12.7615 14.7091 12.1865 15.0321 11.5365 15.2655C10.8865 15.4988 10.214 15.6155 9.51916 15.6155ZM9.51916 14.1155C10.8076 14.1155 11.899 13.6684 12.7932 12.7742C13.6874 11.8799 14.1346 10.7886 14.1346 9.50012C14.1346 8.21165 13.6874 7.1203 12.7932 6.22607C11.899 5.33183 10.8076 4.88472 9.51916 4.88472C8.23069 4.88472 7.13934 5.33183 6.24511 6.22607C5.35089 7.1203 4.90378 8.21165 4.90378 9.50012C4.90378 10.7886 5.35089 11.8799 6.24511 12.7742C7.13934 13.6684 8.23069 14.1155 9.51916 14.1155Z"
        fill="#999999"
      />
    </svg>
  );
}
