import { TimesheetsKeyEnum } from "../../components/timesheets-table-columns/timesheets-table-columns.constants";

export function getTimesheetsMatchingColumns() {
  return [
    TimesheetsKeyEnum.contractorLink,
    TimesheetsKeyEnum.week,
    TimesheetsKeyEnum.comments,
    TimesheetsKeyEnum.rejectionData,
    TimesheetsKeyEnum.jibble,
  ];
}

export function getTimesheetsSelfServeColumns() {
  return [
    TimesheetsKeyEnum.contractor,
    TimesheetsKeyEnum.week,
    TimesheetsKeyEnum.totalHours,
    TimesheetsKeyEnum.status,
    TimesheetsKeyEnum.approveOrReject,
  ];
}
