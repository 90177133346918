import React from "react";
import { getPlaceholderValue } from "../../utils/get-placeholder-value/get-placeholder-value";
import { getCellClassName } from "./postmatch-table-render.utils";

export function PostmatchTableRender() {
  return (text: string) => {
    const cellClassName = getCellClassName(text);
    return <div className={cellClassName}>{getPlaceholderValue(text)}</div>;
  };
}
