import { SortDirection } from "@tanstack/react-table";
import React from "react";

export function SortSvg({
  sortedState,
}: {
  sortedState: false | SortDirection;
}) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2921_3955)">
        <path
          d="M4 6L8 2L12 6H4Z"
          fill={sortedState === "asc" ? "#1890ff" : "#cccccc"}
        />
        <path
          d="M4 10L8 14L12 10H4Z"
          fill={sortedState === "desc" ? "#1890ff" : "#cccccc"}
        />
      </g>
      <defs>
        <clipPath id="clip0_2921_3955">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
