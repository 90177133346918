import React from "react";
import {
  OpportunityRateHistoryResponse,
  RateChangeType,
  TableDataKeyEnum,
  rateHistoryModalOpenAction,
} from "../../utils";
import { TruncatedText } from "../truncated-text/truncated-text";

const RenderRate = (
  field: string,
  openRateHistoryModal?: rateHistoryModalOpenAction,
) => {
  return function (info: any) {
    const original = info?.row?.original;
    const value = original?.[field];
    const rateHistory: Array<OpportunityRateHistoryResponse> =
      original?.rateHistory || [];
    const rateChangeType =
      field === TableDataKeyEnum.developerRate
        ? RateChangeType.developer
        : RateChangeType.customer;

    const filteredRateHistory = rateHistory.filter(
      (history) => history.rateChangeType === rateChangeType,
    );

    if (filteredRateHistory.length && openRateHistoryModal) {
      return (
        <div
          style={{ cursor: "pointer", color: "blue" }}
          onClick={() => {
            const developerName = original?.developerName;
            const jobTitle = original?.jobTitle;

            openRateHistoryModal({
              developerName,
              jobTitle,
              rateHistory: filteredRateHistory,
              rateChangeType,
            });
          }}
        >
          <TruncatedText text={value} />
        </div>
      );
    }
    return <TruncatedText text={value} />;
  };
};

export { RenderRate };
