import styles from "./postmatch-table-render.module.css";

const CellClassName = {
  DEFAULT: "defaut",
  ACTIVE: "active",
  DASH: "-",
  // in_trial and pre_trial and pre-onboarding same color
  IN_TRIAL: "in-trial",
  PRE_TRIAL: "pre-trial",
  PRE_ONBOARDING: "pre-onboarding",
  // off-boarded and closed same color
  OFF_BOARDED: "off-boarded",
  CLOSED: "closed",
} as const;

export function getCellClassName(cellValue: string | number) {
  if (typeof cellValue !== "string") {
    return "";
  }
  const cellValueText = cellValue?.toLowerCase()?.replace(/\s/, "-");
  switch (cellValueText) {
    case CellClassName.ACTIVE:
      return styles.active;

    case CellClassName.IN_TRIAL:
      return styles.inTrail;
    case CellClassName.PRE_TRIAL:
      return styles.inTrail;
    case CellClassName.PRE_ONBOARDING:
      return styles.inTrail;

    case CellClassName.OFF_BOARDED:
      return styles.offBoarded;
    case CellClassName.CLOSED:
      return styles.offBoarded;

    case CellClassName.DASH:
      return styles.center;
    default:
      return "";
  }
}
