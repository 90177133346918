export enum TableDataKeyEnum {
  "developerRecordWithLink" = "developerRecordWithLink",
  "developerName" = "developerName",
  "developerNameSort" = "developerNameSort",

  "companyNameWithLink" = "companyNameWithLink",
  "companyName" = "companyName",
  "companyNameSort" = "companyNameSort",

  "jobTitleWithLink" = "jobTitleWithLink",
  "jobTitle" = "jobTitle",
  "jobTitleSort" = "jobTitleSort",

  "opportunityLink" = "opportunityLink",

  "developerRate" = "developerRate",
  "developerRateSort" = "developerRateSort",

  "customerRate" = "customerRate",
  "customerRateSort" = "customerRateSort",
  "customerRateAlternative" = "customerRateAlternative",

  "rateMargin" = "rateMargin",
  "rateMarginSort" = "rateMarginSort",

  "billedHours" = "billedHours",
  "billedHoursSort" = "billedHoursSort",

  "ptoHours" = "ptoHours",

  "startDate" = "startDate",
  "startDateSort" = "startDateSort",
  "endDate" = "endDate",
  "endDateSort" = "endDateSort",

  "duration" = "duration",
  "durationSort" = "durationSort",

  "internalStatus" = "internalStatus",
  "internalStatusSort" = "internalStatusSort",

  "externalStatus" = "externalStatus",
  "externalStatusAlternative" = "externalStatusAlternative",
  "externalStatusSort" = "externalStatusSort",

  "locationText" = "locationText",
  "locationTextSort" = "locationTextSort",

  "customerContactEmail" = "customerContactEmail",
  "customerContactEmailSort" = "customerContactEmailSort",

  "accountExecutiveEmail" = "accountExecutiveEmail",
  "accountExecutiveEmailSort" = "accountExecutiveEmailSort",

  "salesRepEmail" = "salesRepEmail",
  "salesRepEmailSort" = "salesRepEmailSort",

  "personalEmail" = "personalEmail",
  "personalEmailSort" = "personalEmailSort",

  "turingEmail" = "turingEmail",
  "turingEmailSort" = "turingEmailSort",

  "developerRecord" = "developerRecord",

  "hoursWorked" = "hoursWorked",

  "feedback" = "feedback",

  "opportunityBusinessLine" = "opportunityBusinessLine",
  "opportunityBusinessLineSort" = "opportunityBusinessLineSort",

  "opportunityBusinessLineLabel" = "opportunityBusinessLineLabel",
  "opportunityBusinessLineLabelSort" = "opportunityBusinessLineLabelSort",

  "companyType" = "companyType",
  "companyTypeSort" = "companyTypeSort",
  "companyTypeLabel" = "companyTypeLabel",
  "companyTypeLabelSort" = "companyTypeLabelSort",

  "billingRateType" = "billingRateType",
  "billingRateTypeSort" = "billingRateTypeSort",
  "billingRateTypeLabel" = "billingRateTypeLabel",
  "billingRateTypeLabelSort" = "billingRateTypeLabelSort",

  "devSuccessSpecialistEmail" = "devSuccessSpecialistEmail",
  "devSuccessSpecialistEmailSort" = "devSuccessSpecialistEmailSort",
}

export const TableDataKeyEnumValues = Object.values(TableDataKeyEnum);

export const TableDataKeyToNameMap: Record<TableDataKeyEnum | string, string> =
  {
    [TableDataKeyEnum.developerRecordWithLink]: "Developer",
    [TableDataKeyEnum.developerName]: "Developer",
    [TableDataKeyEnum.developerNameSort]: "Developer",

    [TableDataKeyEnum.companyNameWithLink]: "Company",
    [TableDataKeyEnum.companyName]: "Company",
    [TableDataKeyEnum.companyNameSort]: "Company",

    [TableDataKeyEnum.jobTitleWithLink]: "Title",
    [TableDataKeyEnum.jobTitle]: "Title",
    [TableDataKeyEnum.jobTitleSort]: "Title",

    [TableDataKeyEnum.opportunityLink]: "Salesforce link",

    [TableDataKeyEnum.developerRate]: "Developer rate",
    [TableDataKeyEnum.developerRateSort]: "Developer rate",

    [TableDataKeyEnum.rateMargin]: "Margin",
    [TableDataKeyEnum.rateMarginSort]: "Margin",

    [TableDataKeyEnum.ptoHours]: "PTO hours",

    [TableDataKeyEnum.startDate]: "Start date",
    [TableDataKeyEnum.startDateSort]: "Start date",
    [TableDataKeyEnum.endDate]: "End date",
    [TableDataKeyEnum.endDateSort]: "End date",

    [TableDataKeyEnum.duration]: "Duration",
    [TableDataKeyEnum.durationSort]: "Duration",

    [TableDataKeyEnum.internalStatus]: "Internal status",
    [TableDataKeyEnum.internalStatusSort]: "Internal status",

    [TableDataKeyEnum.externalStatus]: "External status",
    [TableDataKeyEnum.externalStatusAlternative]: "External status",
    [TableDataKeyEnum.externalStatusSort]: "External status",

    [TableDataKeyEnum.customerRate]: "Rate",
    [TableDataKeyEnum.customerRateSort]: "Rate",
    [TableDataKeyEnum.customerRateAlternative]: "Customer rate",

    [TableDataKeyEnum.locationText]: "Location",
    [TableDataKeyEnum.locationTextSort]: "Location",

    [TableDataKeyEnum.customerContactEmail]: "Customer contact",
    [TableDataKeyEnum.customerContactEmailSort]: "Customer contact",

    [TableDataKeyEnum.accountExecutiveEmail]: "Turing contact",
    [TableDataKeyEnum.accountExecutiveEmailSort]: "Turing contact",

    [TableDataKeyEnum.salesRepEmail]: "Sales representative",
    [TableDataKeyEnum.salesRepEmailSort]: "Sales representative",

    [TableDataKeyEnum.personalEmail]: "Developer personal email",
    [TableDataKeyEnum.personalEmailSort]: "Developer personal email",

    [TableDataKeyEnum.turingEmail]: "Developer turing email",
    [TableDataKeyEnum.turingEmailSort]: "Developer turing email",

    [TableDataKeyEnum.developerRecord]: "Developer",

    [TableDataKeyEnum.billedHours]: "Hours worked",
    [TableDataKeyEnum.billedHoursSort]: "Hours worked",

    [TableDataKeyEnum.feedback]: "Feedback",

    [TableDataKeyEnum.opportunityBusinessLineLabel]: "Business line",
    [TableDataKeyEnum.opportunityBusinessLineLabelSort]: "Business line",

    [TableDataKeyEnum.companyTypeLabel]: "Company type",
    [TableDataKeyEnum.companyTypeLabelSort]: "Company type",

    [TableDataKeyEnum.billingRateTypeLabel]: "Billing rate type",
    [TableDataKeyEnum.billingRateTypeLabelSort]: "Billing rate type",

    [TableDataKeyEnum.devSuccessSpecialistEmail]: "Dev success specialist",
    [TableDataKeyEnum.devSuccessSpecialistEmailSort]: "Dev success specialist",
  };

export const TableSort: Record<TableDataKeyEnum | string, string> = {
  [TableDataKeyEnum.developerName]: "developerNameSort",
  [TableDataKeyEnum.companyName]: "companyNameSort",
  [TableDataKeyEnum.jobTitle]: "jobTitleSort",
  [TableDataKeyEnum.developerRate]: "developerRateSort",
  [TableDataKeyEnum.customerRate]: "customerRateSort",
  [TableDataKeyEnum.rateMargin]: "rateMarginSort",
  [TableDataKeyEnum.billedHours]: "billedHoursSort",
  [TableDataKeyEnum.startDate]: "startDateSort",
  [TableDataKeyEnum.endDate]: "endDateSort",
  [TableDataKeyEnum.duration]: "durationSort",
  [TableDataKeyEnum.internalStatus]: "internalStatusSort",
  [TableDataKeyEnum.externalStatus]: "externalStatusSort",
  [TableDataKeyEnum.locationText]: "locationSort",
  [TableDataKeyEnum.salesRepEmail]: "salesRepEmailSort",
  [TableDataKeyEnum.personalEmail]: "personalEmailSort",
  [TableDataKeyEnum.turingEmail]: "turingEmailSort",
  [TableDataKeyEnum.customerContactEmail]: "customerContactEmailSort",
  [TableDataKeyEnum.accountExecutiveEmail]: "accountExecutiveEmailSort",
  [TableDataKeyEnum.opportunityBusinessLine]: "opportunityBusinessLineSort",
  [TableDataKeyEnum.companyType]: "companyTypeSort",
  [TableDataKeyEnum.billingRateType]: "billingRateTypeSort",
  [TableDataKeyEnum.devSuccessSpecialistEmail]: "devSuccessSpecialistEmailSort",
};
