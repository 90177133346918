import React from "react";
import classnames from "classnames";
import { getStatusClassName } from "./timesheets-table-render-status.utils";
import { WaitingForApprovalSvg } from "../svgs/WaitingForApprovalSvg";
import { ApprovedSvg } from "../svgs/ApprovedSvg";
import { RejectedSvg } from "../svgs/RejectedSvg";
import styles from "./timesheets-table-render-status.module.css";
import {
  APPROVAL_STATUS,
  TimesheetsTableDataSource,
} from "../../types/postmatch.types";

function ActiveSvg({ approvalStatus }: { approvalStatus: APPROVAL_STATUS }) {
  switch (approvalStatus) {
    case APPROVAL_STATUS.PENDING_APPROVAL:
      return <WaitingForApprovalSvg />;
    case APPROVAL_STATUS.APPROVED || APPROVAL_STATUS.AUTO_APPROVED:
      return <ApprovedSvg />;
    case APPROVAL_STATUS.REJECTED:
      return <RejectedSvg />;
    default:
      return null;
  }
}

export function TimesheetsTableRenderStatus() {
  return function (status: string, record: TimesheetsTableDataSource) {
    const approvalStatus = record?.approvalStatus;
    const statusClassName = getStatusClassName(status);
    return (
      <div className={classnames(styles.base, styles[statusClassName])}>
        <ActiveSvg approvalStatus={approvalStatus} />
        <span className={styles.text}>{status}</span>
      </div>
    );
  };
}
