const units = {
  million: { value: 1e6, unit: "M" },
  billion: { value: 1e9, unit: "B" },
};

const computeUnit = (amount: number, denominator: number, unit: string) => {
  return `$${(amount / denominator).toFixed(2)}${unit}`;
};

export const convertToUSD = (amount: number | null) => {
  return typeof amount !== "number"
    ? ""
    : amount.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
};

export const convertToUSDUnits = (amount: number | null) => {
  if (typeof amount !== "number") {
    return "";
  }
  if (amount >= units.million.value && amount < units.billion.value) {
    return computeUnit(amount, units.million.value, units.million.unit);
  }
  if (amount >= units.billion.value) {
    return computeUnit(amount, units.billion.value, units.billion.unit);
  }
  return convertToUSD(amount);
};
