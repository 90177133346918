import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Space, Spin } from "antd";

export const spinnerExtraLarge = (
  <LoadingOutlined style={{ fontSize: 120 }} spin />
);
export const spinnerLarge = <LoadingOutlined style={{ fontSize: 80 }} spin />;
export const spinnerSmall = <LoadingOutlined style={{ fontSize: 40 }} spin />;

export function Spinner() {
  return (
    <Space
      style={{ display: "grid", placeItems: "center", height: "800px" }}
      direction="vertical"
    >
      <Spin indicator={spinnerSmall} />
    </Space>
  );
}
