import { FilterValue, SorterResult } from "antd/lib/table/interface";
import { TableDataKeyEnum, TableDataKeyToNameMap } from "../columns";
import { TABLE_ACTION, TABLE_ACTIVITY_TYPES } from "./table-activity.types";
import { LogActivity } from "../../components/postmatch-table/postmatch-table.types";
import { areArraysEqual, filterObject } from "../helpers/helpers";

const getSelectedFilters = (
  currFilters: Record<string, FilterValue | null>,
  prevFilters: Record<string, FilterValue | null>,
) => {
  const validPrevFilters = filterObject(prevFilters || {});
  const validCurrFilters = filterObject(currFilters || {});
  for (const [currFilterKey, currFilterValues] of Object.entries(
    validCurrFilters,
  )) {
    const prevFilterValues = validPrevFilters[currFilterKey];
    const onlyCurrIsArray =
      !Array.isArray(prevFilterValues) && Array.isArray(currFilterValues);
    const prevAndCurrAreDistinctArrays =
      Array.isArray(prevFilterValues) &&
      Array.isArray(currFilterValues) &&
      !areArraysEqual(currFilterValues, prevFilterValues);
    if (onlyCurrIsArray || prevAndCurrAreDistinctArrays) {
      return { field: currFilterKey, values: currFilterValues };
    }
  }
  for (const [prevFilterKey, prevFilterValues] of Object.entries(
    validPrevFilters,
  )) {
    const currFilterValues = validCurrFilters[prevFilterKey];
    const onlyPrevIsArray =
      Array.isArray(prevFilterValues) && !Array.isArray(currFilterValues);
    if (onlyPrevIsArray) {
      return { field: prevFilterKey, values: [] };
    }
  }
  return { field: "", values: [] };
};

export const logClickActivity = async (
  key: string,
  action: string,
  link: string,
  logActivity: LogActivity,
) => {
  if (key && action && typeof logActivity === "function") {
    const id = link?.match(/\d+$/g)?.[0] || "";
    const formattedId = parseInt(id, 10) || null;
    const payload = { page: window?.location?.href || "" };
    await logActivity(key, action, formattedId, payload);
  }
};

export const logTableActivity = async (
  logActivity: LogActivity,
  action: string,
  sorter: SorterResult<any>,
  filters: Record<string, FilterValue | null>,
  prevFilters: Record<string, FilterValue | null>,
) => {
  if (action === TABLE_ACTION.SORT && typeof logActivity === "function") {
    const columnField = sorter?.field as keyof typeof TableDataKeyEnum;
    await logActivity(
      TABLE_ACTIVITY_TYPES.KEY,
      TABLE_ACTIVITY_TYPES.POSTMATCH_TABLE_ORDERED,
      null,
      {
        columnName: TableDataKeyToNameMap[TableDataKeyEnum[columnField]] || "",
        order: sorter?.order || "cancel",
      },
    );
  } else if (
    action === TABLE_ACTION.FILTER &&
    typeof logActivity === "function"
  ) {
    const { field, values } = getSelectedFilters(filters, prevFilters);
    const columnField = field as keyof typeof TableDataKeyEnum;
    await logActivity(
      TABLE_ACTIVITY_TYPES.KEY,
      TABLE_ACTIVITY_TYPES.POSTMATCH_TABLE_ORDERED,
      null,
      {
        columnName: TableDataKeyToNameMap[columnField] || "",
        filteredValues: values,
      },
    );
  }
};
