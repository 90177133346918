import {
  ColumnType,
  rateHistoryModalOpenAction,
} from "../dashboard/dashboard.types";
import {
  TableDataKeyEnum,
  TableDataKeyToNameMap,
} from "../postmatch-table-columns/postmatch-table-columns.constants";
import { RenderRate } from "./../render-rate";
import { DashboardRenderDeveloperLink } from "../dashboard-render-developer-link/dashboard-render-developer-link";
import { DashboardRenderLink } from "../dashboard-render-link/dashboard-render-link";
import {
  LogActivity,
  PostmatchActivityType,
  PostmatchTableProps,
} from "../postmatch-table/postmatch-table.types";
import { DashboardRenderSalesforce } from "../dashboard-render-salesforce/dashboard-render-salesforce";
import { DashboardRenderOverridable } from "../dashboard-render-overridable/dashboard-render-overridable";
import { DashboardRenderDefault } from "../dashboard-render-default/dashboard-render-default";
import { DashboardRenderExternalStatus } from "../dashboard-render-external-status/dashboard-render-external-status";
import { DashboardRenderFeedback } from "../dashboard-render-feedback/dashboard-render-feedback";
import { columnHelper, getColumnConfig } from "./dashboard-columns.utils";

export function getDashboardDefaultColumns(
  postmatchActivityType: PostmatchActivityType = {},
  logActivity: LogActivity = () => {},
  onClickFeedbackTable: PostmatchTableProps["onClickFeedbackTable"] = () => {},
  openRateHistoryModal?: rateHistoryModalOpenAction,
) {
  const { KEY, ACTIONS } = postmatchActivityType;
  return [
    {
      id: TableDataKeyEnum.developerName,
      cell: DashboardRenderDeveloperLink(
        KEY,
        ACTIONS?.CLICKED_POSTMATCH_DEVELOPER_LINK,
        logActivity,
      ),
      header: TableDataKeyToNameMap[TableDataKeyEnum.developerName],
      ...getColumnConfig(ColumnType.TextWithoutCheckboxes),
    },

    {
      id: TableDataKeyEnum.companyName,
      cell: DashboardRenderLink(
        KEY,
        ACTIONS?.CLICKED_POSTMATCH_COMPANY_LINK,
        logActivity,
        TableDataKeyEnum.companyNameWithLink,
      ),
      header: TableDataKeyToNameMap[TableDataKeyEnum.companyName],
      ...getColumnConfig(ColumnType.TextWithCheckboxes),
    },

    {
      id: TableDataKeyEnum.jobTitle,
      cell: DashboardRenderLink(
        KEY,
        ACTIONS?.CLICKED_POSTMATCH_JOB_LINK,
        logActivity,
        TableDataKeyEnum.jobTitleWithLink,
      ),
      header: TableDataKeyToNameMap[TableDataKeyEnum.jobTitle],
      ...getColumnConfig(ColumnType.TextWithoutCheckboxes),
    },

    {
      id: TableDataKeyEnum.opportunityLink,
      cell: DashboardRenderSalesforce(),
      header: TableDataKeyToNameMap[TableDataKeyEnum.opportunityLink],
      ...getColumnConfig(ColumnType.Disabled),
    },

    {
      id: TableDataKeyEnum.developerRateSort,
      cell: RenderRate(TableDataKeyEnum.developerRate, openRateHistoryModal),
      header: TableDataKeyToNameMap[TableDataKeyEnum.developerRateSort],
      ...getColumnConfig(ColumnType.NumberType),
    },

    {
      id: TableDataKeyEnum.customerRateSort,
      cell: RenderRate(TableDataKeyEnum.customerRate, openRateHistoryModal),
      header: TableDataKeyToNameMap[TableDataKeyEnum.customerRateSort],
      ...getColumnConfig(ColumnType.NumberType),
    },

    {
      id: TableDataKeyEnum.billingRateTypeLabel,
      cell: DashboardRenderDefault(),
      header: TableDataKeyToNameMap[TableDataKeyEnum.billingRateTypeLabel],
      ...getColumnConfig(ColumnType.TextWithCheckboxes),
    },

    {
      id: TableDataKeyEnum.rateMarginSort,
      cell: DashboardRenderOverridable(TableDataKeyEnum.rateMargin),
      header: TableDataKeyToNameMap[TableDataKeyEnum.rateMarginSort],
      ...getColumnConfig(ColumnType.NumberType),
    },

    {
      id: TableDataKeyEnum.billedHoursSort,
      cell: DashboardRenderOverridable(TableDataKeyEnum.billedHours),
      header: TableDataKeyToNameMap[TableDataKeyEnum.billedHoursSort],
      ...getColumnConfig(ColumnType.NumberType),
    },

    {
      id: TableDataKeyEnum.startDateSort,
      cell: DashboardRenderOverridable(TableDataKeyEnum.startDate),
      header: TableDataKeyToNameMap[TableDataKeyEnum.startDateSort],
      ...getColumnConfig(ColumnType.DateType),
    },

    {
      id: TableDataKeyEnum.endDateSort,
      cell: DashboardRenderOverridable(TableDataKeyEnum.endDate),
      header: TableDataKeyToNameMap[TableDataKeyEnum.endDateSort],
      ...getColumnConfig(ColumnType.DateType),
    },

    {
      id: TableDataKeyEnum.durationSort,
      cell: DashboardRenderOverridable(TableDataKeyEnum.duration),
      header: TableDataKeyToNameMap[TableDataKeyEnum.durationSort],
      ...getColumnConfig(ColumnType.NumberType),
    },

    {
      id: TableDataKeyEnum.internalStatus,
      cell: DashboardRenderDefault(),
      header: TableDataKeyToNameMap[TableDataKeyEnum.internalStatus],
      ...getColumnConfig(ColumnType.TextWithCheckboxes),
    },

    {
      id: TableDataKeyEnum.externalStatus,
      cell: DashboardRenderExternalStatus(),
      header: TableDataKeyToNameMap[TableDataKeyEnum.externalStatus],
      ...getColumnConfig(ColumnType.TextWithCheckboxes),
    },

    {
      id: TableDataKeyEnum.opportunityBusinessLineLabel,
      cell: DashboardRenderDefault(),
      header:
        TableDataKeyToNameMap[TableDataKeyEnum.opportunityBusinessLineLabel],
      ...getColumnConfig(ColumnType.TextWithCheckboxes),
    },

    {
      id: TableDataKeyEnum.companyTypeLabel,
      cell: DashboardRenderDefault(),
      header: TableDataKeyToNameMap[TableDataKeyEnum.companyTypeLabel],
      ...getColumnConfig(ColumnType.TextWithCheckboxes),
    },

    {
      id: TableDataKeyEnum.locationText,
      cell: DashboardRenderDefault(),
      header: TableDataKeyToNameMap[TableDataKeyEnum.locationText],
      ...getColumnConfig(ColumnType.TextWithCheckboxes),
    },

    {
      id: TableDataKeyEnum.customerContactEmail,
      cell: DashboardRenderDefault(),
      header: TableDataKeyToNameMap[TableDataKeyEnum.customerContactEmail],
      ...getColumnConfig(ColumnType.TextWithCheckboxes),
    },

    {
      id: TableDataKeyEnum.accountExecutiveEmail,
      cell: DashboardRenderDefault(),
      header: TableDataKeyToNameMap[TableDataKeyEnum.accountExecutiveEmail],
      ...getColumnConfig(ColumnType.TextWithCheckboxes),
    },

    {
      id: TableDataKeyEnum.salesRepEmail,
      cell: DashboardRenderDefault(),
      header: TableDataKeyToNameMap[TableDataKeyEnum.salesRepEmail],
      ...getColumnConfig(ColumnType.TextWithCheckboxes),
    },

    {
      id: TableDataKeyEnum.devSuccessSpecialistEmail,
      cell: DashboardRenderDefault(),
      header: TableDataKeyToNameMap[TableDataKeyEnum.devSuccessSpecialistEmail],
      ...getColumnConfig(ColumnType.TextWithCheckboxes),
    },

    {
      id: TableDataKeyEnum.personalEmail,
      cell: DashboardRenderDefault(),
      header: TableDataKeyToNameMap[TableDataKeyEnum.personalEmail],
      ...getColumnConfig(ColumnType.TextWithoutCheckboxes),
    },

    {
      id: TableDataKeyEnum.turingEmail,
      cell: DashboardRenderDefault(),
      header: TableDataKeyToNameMap[TableDataKeyEnum.turingEmail],
      ...getColumnConfig(ColumnType.TextWithoutCheckboxes),
    },

    {
      id: TableDataKeyEnum.feedback,
      cell: DashboardRenderFeedback(onClickFeedbackTable),
      header: TableDataKeyToNameMap[TableDataKeyEnum.feedback],
      ...getColumnConfig(ColumnType.Disabled),
    },
  ];
}

export function getDashboardAccessorColumns(
  postmatchActivityType: PostmatchActivityType = {},
  logActivity: LogActivity = () => {},
  onClickFeedbackTable: PostmatchTableProps["onClickFeedbackTable"] = () => {},
  openRateHistoryModal?: rateHistoryModalOpenAction,
) {
  return getDashboardDefaultColumns(
    postmatchActivityType,
    logActivity,
    onClickFeedbackTable,
    openRateHistoryModal,
  ).map((column) => columnHelper.accessor(column.id as any, column as any));
}
