import React from "react";

export function LikeSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M17.2436 7.08336C17.6399 7.08336 17.9901 7.23534 18.294 7.5393C18.598 7.84325 18.75 8.19341 18.75 8.58978V9.93591C18.75 10.0234 18.7387 10.1171 18.7163 10.2171C18.6939 10.317 18.6688 10.4103 18.641 10.4968L16.2528 16.1323C16.1334 16.3988 15.9332 16.6239 15.6522 16.8077C15.3712 16.9914 15.0781 17.0833 14.7727 17.0833H6.00961V7.08336L10.8574 2.2773C11.023 2.11171 11.2147 2.01288 11.4327 1.98082C11.6506 1.94877 11.8595 1.98616 12.0593 2.09301C12.259 2.19984 12.4054 2.35155 12.4983 2.54812C12.5913 2.7447 12.6111 2.94822 12.5576 3.15868L11.6602 7.08336H17.2436ZM7.25959 7.61541V15.8333H14.7756C14.8344 15.8333 14.8945 15.8173 14.9559 15.7853C15.0174 15.7532 15.0641 15.6998 15.0962 15.625L17.5 10V8.58978C17.5 8.51499 17.4759 8.45355 17.4279 8.40547C17.3798 8.35738 17.3183 8.33334 17.2436 8.33334H10.0801L11.125 3.76603L7.25959 7.61541ZM3.58976 17.0833C3.1755 17.0833 2.82086 16.9358 2.52586 16.6408C2.23085 16.3458 2.08334 15.9912 2.08334 15.5769V8.58978C2.08334 8.17552 2.23085 7.82089 2.52586 7.52589C2.82086 7.23087 3.1755 7.08336 3.58976 7.08336H6.00961V8.33334H3.58976C3.51497 8.33334 3.45353 8.35738 3.40545 8.40547C3.35736 8.45355 3.33332 8.51499 3.33332 8.58978V15.5769C3.33332 15.6517 3.35736 15.7131 3.40545 15.7612C3.45353 15.8093 3.51497 15.8333 3.58976 15.8333H6.00961V17.0833H3.58976Z"
        fill="white"
      />
    </svg>
  );
}
