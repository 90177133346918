import { EngagementsPermission } from "../../types/postmatch.types";

export const isUserWithEngagements = (permission: EngagementsPermission) => {
  // Not checking directly for "none" since it could be null or another bad value.
  // E.g. only allowed to see engagements if user has a valid permission field.
  return (
    permission === EngagementsPermission.CompanyEngagements ||
    permission === EngagementsPermission.OwnEngagements
  );
};
