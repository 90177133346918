import moment from "moment";
import { FormattedDeveloper } from "../../types/formatted-developer.types";
import {
  BillingRateTypeToLabelMap,
  CompanyItem,
  CompanyTypeEnum,
  CompanyTypeEnumToLabelMap,
  Developer,
  Location,
  SfOppBusinessLineEnumToLabelMap,
  User,
} from "../../types/postmatch.types";
import { convertFractionalHoursFixed } from "../convert-fractional-rates/convert-fractional-rates";
import {
  PLACEHOLDER,
  getPlaceholderValue,
  getPlaceholderValueSortNumber,
  getPlaceholderValueSortText,
} from "../get-placeholder-value/get-placeholder-value";
import { END_DATE_CURRENTLY_WORKING } from "./parse-developer-list.constants";
import { getPrefixURL } from "../url/get-prefix-url/get-prefix-url";
import { capitalizeFirstLetter } from "../text/text";

function insertSingleCompanyName(
  developers: Developer[],
  companyName: string,
  id: number,
  salesRepresentative: User,
  type?: CompanyTypeEnum,
) {
  return developers.map((developer, index) => ({
    ...developer,
    companyName,
    normalizedId: `0x${index}`,
    companyId: id,
    developerId: developer?.id,
    salesRepresentative,
    companyType: type,
  }));
}

function insertCompanyName(allEngagements: CompanyItem[]) {
  return allEngagements.map((postmatchCompanyItem) => ({
    ...postmatchCompanyItem,
    developers: postmatchCompanyItem?.developers?.map((developer) => ({
      ...developer,
      companyName: postmatchCompanyItem.name,
      companyId: postmatchCompanyItem.id,
      companyType: postmatchCompanyItem.type,
      developerId: developer?.id,
      salesRepresentative: postmatchCompanyItem.salesRepresentative,
    })),
  }));
}

function combineDevelopers(postmatchDeveloperList: CompanyItem[]) {
  const acc: any = [];
  for (let i = 0; i < postmatchDeveloperList.length; i += 1) {
    for (let j = 0; j < postmatchDeveloperList[i].developers?.length; j += 1) {
      acc.push({
        ...postmatchDeveloperList[i].developers[j],
        normalizedId: `${i}x${j}`,
      });
    }
  }
  return acc;
}

function formatLocation(location: Location | undefined) {
  return getPlaceholderValue(location?.country);
}

function formatDevelopers(developers: Developer[]): FormattedDeveloper[] {
  return developers.map((developer) => {
    const {
      normalizedId,
      location,
      email,
      developerBillingRate,
      customerBillingRate,
      billingRateMargin,
      status,
      engagement,
      billedHours,
      companyId,
      developerId,
      companyName,
      name: developerName,
      avatarUrl,
      accountExecutive,
      salesRepresentative,
      developerIdHash,
      opportunityBusinessLine,
      companyType,
      billingRateType,
      devSuccessSpecialist,
    } = developer;

    const { rateText: developerRate, rate: developerRateSort } =
      developerBillingRate || {};

    const { rateText: customerRate, rate: customerRateSort } =
      customerBillingRate || {};

    const { margin: rateMarginSort, marginText: rateMargin } =
      billingRateMargin || {};
    const invalidReceivedMarginText = "NaN%";

    const engagementStartDate = engagement?.startDate;
    const engagementEndDate = engagement?.endDate;

    const startDate = moment(engagementStartDate, "YYYY-MM-DD");
    const endDate = moment(engagementEndDate, "YYYY-MM-DD");

    const formattedStartDate = startDate.isValid()
      ? startDate.format("MM/DD/YYYY")
      : PLACEHOLDER;
    const formattedEndDate = endDate.isValid()
      ? endDate.format("MM/DD/YYYY")
      : END_DATE_CURRENTLY_WORKING;

    const startDateSort = startDate.isValid()
      ? parseInt(startDate.format("X"), 10)
      : undefined;
    const endDateSort = endDate.isValid()
      ? parseInt(endDate.format("X"), 10)
      : undefined;

    const internal = getPlaceholderValue(status?.internal);
    const external = getPlaceholderValue(status?.external);

    const internalStatus = getPlaceholderValue(
      capitalizeFirstLetter(internal || ""),
    );
    const internalStatusSort = getPlaceholderValueSortText(internalStatus);

    const externalStatus = getPlaceholderValue(
      capitalizeFirstLetter(external || ""),
    );
    const externalStatusSort = getPlaceholderValueSortText(externalStatus);

    const personalEmail = getPlaceholderValue(
      email?.personal?.replace(/\s/, ""),
    );
    const personalEmailSort = getPlaceholderValueSortText(personalEmail);

    const turingEmail = getPlaceholderValue(email?.turing);
    const turingEmailSort = getPlaceholderValueSortText(turingEmail);

    const normalizedCompanyName = getPlaceholderValue(companyName);
    const companyNameSort = getPlaceholderValueSortText(normalizedCompanyName);
    const companyPageLink = companyId ? `/company/${companyId}` : "";
    const companyNameWithLink = {
      text: normalizedCompanyName,
      link: companyPageLink,
    };

    const jobId = engagement?.job?.id;
    const jobPageLink = jobId ? `/job/${jobId}` : "";
    const jobTitle = getPlaceholderValue(engagement?.job?.title);
    const jobTitleSort = getPlaceholderValueSortText(jobTitle);
    const jobTitleWithLink = {
      text: jobTitle,
      link: jobPageLink,
    };

    const normalizedDeveloperName = getPlaceholderValue(developerName);
    const developerNameSort = getPlaceholderValueSortText(
      normalizedDeveloperName,
    );

    const developerPageLink = developerId ? `/developer/${developerId}` : "";

    const developerRecord = {
      text: normalizedDeveloperName,
      link: developerPageLink,
      imageUrl: avatarUrl
        ? `https://storage.googleapis.com/turing_developers/avatar/${avatarUrl}`
        : "",
      developerId,
      developerIdHash,
      jobId,
    };

    const accountExecutiveEmail = getPlaceholderValue(accountExecutive?.email);
    const accountExecutiveEmailSort = getPlaceholderValueSortText(
      accountExecutiveEmail,
    );

    const customerContactEmail = getPlaceholderValue(
      engagement?.customerHiringManager?.email,
    );
    const customerContactEmailSort =
      getPlaceholderValueSortText(customerContactEmail);

    const salesRepEmail = getPlaceholderValue(salesRepresentative?.email);
    const salesRepEmailSort = getPlaceholderValueSortText(salesRepEmail);

    const formattedLocation = formatLocation(location);
    const locationSort = getPlaceholderValueSortText(formattedLocation);

    const formattedBilledHours = convertFractionalHoursFixed(billedHours, true);
    const billedHoursSort =
      typeof billedHours === "string" ? parseFloat(billedHours) : billedHours;
    const normalizedBilledHoursSort =
      getPlaceholderValueSortNumber(billedHoursSort);

    const opportunityLink = developer?.opportunityLink || "";

    const formattedDuration = engagement?.formattedDuration;
    const duration = engagement?.duration;
    const normalizedFormattedDuration = getPlaceholderValue(formattedDuration);
    const durationSort = getPlaceholderValueSortNumber(duration);

    const prefixURL = getPrefixURL();

    const feedback = {
      developerName: normalizedDeveloperName,
      developerPageLink: `${prefixURL}${developerPageLink}`,
      companyName: normalizedCompanyName,
      companyPageLink: `${prefixURL}${companyPageLink}`,
      jobTitle,
      jobPageLink: `${prefixURL}${jobPageLink}`,
      salesforceLink: opportunityLink,
    };

    const opportunityBusinessLineLabel = opportunityBusinessLine
      ? SfOppBusinessLineEnumToLabelMap[opportunityBusinessLine]
      : PLACEHOLDER;
    const opportunityBusinessLineSort = getPlaceholderValueSortText(
      opportunityBusinessLineLabel,
    );

    const companyTypeLabel = companyType
      ? CompanyTypeEnumToLabelMap[companyType]
      : PLACEHOLDER;
    const companyTypeSort = getPlaceholderValueSortText(companyTypeLabel);

    const billingRateTypeLabel = getPlaceholderValue(
      billingRateType ? BillingRateTypeToLabelMap[billingRateType] : "",
    );
    const billingRateTypeSort =
      getPlaceholderValueSortText(billingRateTypeLabel);

    const devSuccessSpecialistEmail = getPlaceholderValue(
      devSuccessSpecialist?.email,
    );
    const devSuccessSpecialistEmailSort = getPlaceholderValueSortText(
      devSuccessSpecialistEmail,
    );

    return {
      normalizedId,

      developerRecordWithLink: developerRecord,
      developerPageLink,
      developerName: normalizedDeveloperName,
      developerNameSort,
      developerId,

      companyNameWithLink,
      companyPageLink,
      companyName: normalizedCompanyName,
      companyNameSort,

      jobTitleWithLink,
      jobPageLink,
      jobTitle,
      jobTitleSort,

      developerRate: getPlaceholderValue(developerRate),
      developerRateSort: getPlaceholderValueSortNumber(developerRateSort),

      customerRate: getPlaceholderValue(customerRate),
      customerRateSort: getPlaceholderValueSortNumber(customerRateSort),

      rateMargin:
        rateMargin !== invalidReceivedMarginText
          ? getPlaceholderValue(rateMargin)
          : PLACEHOLDER,
      rateMarginSort:
        rateMargin !== invalidReceivedMarginText
          ? getPlaceholderValueSortNumber(rateMarginSort)
          : undefined,

      billedHours: formattedBilledHours,
      billedHoursSort: normalizedBilledHoursSort,

      internalStatus,
      internalStatusSort,
      externalStatus,
      externalStatusSort,

      startDate: formattedStartDate,
      startDateSort,

      endDate: formattedEndDate,
      endDateSort,

      duration: normalizedFormattedDuration,
      durationSort,

      locationText: formattedLocation,
      locationWithFilter: formattedLocation,
      locationSort,

      salesRepEmail,
      salesRepEmailSort,

      personalEmail,
      personalEmailSort,

      turingEmail,
      turingEmailSort,

      developerRecord,

      billingStatus: externalStatus,

      accountExecutiveEmail,
      accountExecutiveEmailSort,

      customerContactEmail,
      customerContactEmailSort,

      opportunityLink,

      feedback,

      opportunityBusinessLine,
      opportunityBusinessLineLabel,
      opportunityBusinessLineSort,

      companyType,
      companyTypeLabel,
      companyTypeSort,

      billingRateType,
      billingRateTypeLabel,
      billingRateTypeSort,

      devSuccessSpecialistEmail,
      devSuccessSpecialistEmailSort,
      rateHistory: engagement?.rateHistory || [],
    };
  });
}

export function parseDevelopersFromSingleCompany(
  developers: Developer[],
  companyName: string,
  id: number,
  salesRepresentative: User,
  companyType?: CompanyTypeEnum,
) {
  return formatDevelopers(
    insertSingleCompanyName(
      developers,
      companyName,
      id,
      salesRepresentative,
      companyType,
    ),
  );
}

export function parseDevelopersFromAllCompanies(engagements: CompanyItem[]) {
  return formatDevelopers(combineDevelopers(insertCompanyName(engagements)));
}
