import {
  endOfDay,
  startOfMonth,
  endOfWeek,
  parseISO,
  startOfDay,
  startOfWeek,
  addDays,
  setDay,
  getDay,
  setMonth,
  setYear,
} from "date-fns";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { utcToZonedTime } from "date-fns-tz";
import { minDateWeek } from "../../utils/datepicker-dates";
import { PostmatchPickerWeekProps } from "./postmatch-picker-week.types";
import {
  ActivePicker,
  PickerDateRange,
} from "../../../../types/postmatch.types";
import {
  getQueryDatesInISO,
  isPickerFromQueryValid,
} from "../../../../utils/query/query";
import { getMonthFromISO, getYearFromISO } from "../../../../utils/dates/dates";
import { ActiveMonthYear } from "../../postmatch-picker.types";

function getInitialPickerDate(
  query: URLSearchParams,
  isQueryLoaded: boolean,
  activeMonthYear: ActiveMonthYear,
) {
  const { startDateFromQueryISO, endDateFromQueryISO } =
    getQueryDatesInISO(query);
  if (
    getMonthFromISO(startDateFromQueryISO) !== activeMonthYear.month ||
    getYearFromISO(startDateFromQueryISO) !== activeMonthYear.year
  ) {
    const startMonth = startOfMonth(
      setYear(
        setMonth(new Date(), activeMonthYear.month),
        activeMonthYear.year,
      ),
    );
    const initialStartDate = setDay(startMonth, 0, {
      weekStartsOn: getDay(startMonth),
    });
    const initialEndDate = addDays(initialStartDate, 6);
    return { initialStartDate, initialEndDate };
  }
  if (
    isQueryLoaded &&
    query.get("pickerMode") === ActivePicker.Week &&
    isPickerFromQueryValid(
      ActivePicker.Week,
      startDateFromQueryISO,
      endDateFromQueryISO,
    )
  ) {
    return {
      initialStartDate: utcToZonedTime(parseISO(startDateFromQueryISO), "UTC"),
      initialEndDate: utcToZonedTime(parseISO(endDateFromQueryISO), "UTC"),
    };
  }
  const startMonth = startOfMonth(
    utcToZonedTime(parseISO(startDateFromQueryISO), "UTC"),
  );
  const initialStartDate = setDay(startMonth, 0, {
    weekStartsOn: getDay(startMonth),
  });
  const initialEndDate = addDays(initialStartDate, 6);
  return { initialStartDate, initialEndDate };
}

export function PostmatchPickerWeek({
  setPopoverDate,
  isQueryLoaded,
  query,
  activeMonthYear,
  setActiveMonthYear,
}: PostmatchPickerWeekProps) {
  const { initialStartDate, initialEndDate } = getInitialPickerDate(
    query,
    isQueryLoaded,
    activeMonthYear,
  );

  const [pickerDateRange, setPickerDateRange] = useState<PickerDateRange>({
    start: initialStartDate,
    end: initialEndDate,
  });

  useEffect(() => {
    const startDateUTC = moment(initialStartDate).utc(true).toISOString();
    const endDateUTC = moment(initialEndDate).utc(true).toISOString();
    setPopoverDate({ start: startDateUTC, end: endDateUTC });
    setPickerDateRange({ start: initialStartDate, end: initialEndDate });
  }, [isQueryLoaded]);

  const onChange = (dates: [Date, Date]) => {
    const [startDate] = dates;
    const startWeek = startOfWeek(startDate);
    const endWeek = endOfWeek(startDate);
    setPickerDateRange({
      start: startWeek,
      end: endWeek,
    });
    const startDateUTC = moment(startOfDay(startWeek)).utc(true).toISOString();
    const endDateUTC = moment(endOfDay(endWeek)).utc(true).toISOString();
    setPopoverDate({ start: startDateUTC, end: endDateUTC });
  };

  const onMonthChange = (date: Date) => {
    setActiveMonthYear({ month: date.getMonth(), year: date.getFullYear() });
  };

  return (
    <DatePicker
      selected={pickerDateRange.start}
      onChange={onChange}
      onMonthChange={onMonthChange}
      startDate={pickerDateRange.start}
      endDate={pickerDateRange.end}
      selectsRange
      inline
      minDate={minDateWeek}
    />
  );
}
