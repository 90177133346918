import { FunctionComponent } from "react";

export enum ButtonDesignSystemV2Color {
  Primary = "Primary",
  Secondary = "Secondary",
  Tertiary = "Tertiary",
}

export interface ButtonDesignSystemV2Props {
  text: string;
  Icon?: FunctionComponent;
  isLoading?: boolean;
  onClick?: () => void;
  color?: ButtonDesignSystemV2Color;
  buttonClassName?: string;
  spinnerClassName?: string;
  textClassName?: string;
  disabled?: boolean;
  isLinkNewTab?: boolean;
  href?: string;
}
