import React from "react";
import { PostmatchTableProps } from "../postmatch-table/postmatch-table.types";
import { DashboardRenderDeveloperLinkEntity } from "../dashboard-render-developer-link-entity/dashboard-render-developer-link-entity";

export function PostmatchTableRenderLinkDeveloper(
  activityKey: string,
  activityAction: string,
  logActivity: PostmatchTableProps["logActivity"],
) {
  return function (value: {
    text: string;
    link: string;
    imageUrl: string;
    developerId: number | undefined;
    developerIdHash: string | undefined;
    jobId: number | null;
  }) {
    return (
      <DashboardRenderDeveloperLinkEntity
        value={value}
        activityKey={activityKey}
        activityAction={activityAction}
        logActivity={logActivity}
      />
    );
  };
}
