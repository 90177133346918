import React from "react";
import { PostmatchTableFieldActivityProps } from "./postmatch-table-field-activity.types";
import { TruncatedText } from "../truncated-text/truncated-text";
import { getPlaceholderValue } from "../../utils/get-placeholder-value/get-placeholder-value";

export function PostmatchTableFieldActivity({
  onClickField,
  text,
  isTruncationEnabled,
}: PostmatchTableFieldActivityProps) {
  return (
    <div onClick={onClickField}>
      {isTruncationEnabled ? (
        <TruncatedText text={text} />
      ) : (
        getPlaceholderValue(text)
      )}
    </div>
  );
}
