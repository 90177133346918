import React from "react";
import { Skeleton } from "antd";
import classNames from "classnames";
import { getPlaceholderDash } from "../../utils/get-placeholder-value/get-placeholder-value";
import { PostmatchSummaryBlocksItemProps } from "./postmatch-summary-blocks.types";
import styles from "./postmatch-summary-blocks-item.module.css";

export function PostmatchSummaryBlocksItem({
  name,
  value,
  title,
  isLoading,
  styleLeft,
}: PostmatchSummaryBlocksItemProps) {
  return (
    <div
      className={classNames(styles.blocksItem, {
        [styles.left]: styleLeft,
      })}
    >
      {isLoading ? (
        <Skeleton.Button active />
      ) : (
        <div title={title}>{getPlaceholderDash(value)}</div>
      )}
      <div>{name}</div>
    </div>
  );
}
